import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production'
    ? 'https://zoho2.flitegains.com/'
   : 'https://zoho2.flitegains.com/'
});

// const instance = axios.create({
//     baseURL: process.env.NODE_ENV === 'production'
//     ? 'http://localhost:3001/'
//    : 'http://localhost:3001/'
// });

export function setupInterceptors(history) {
    instance
        .interceptors
        .request
        .use(config => {
            // perform a task before the request is sent
            config.headers['x-auth-token'] = localStorage.getItem('token');
            return config;
        }, error => {
            // handle the error
            return Promise.reject(error);
        });

    instance
        .interceptors
        .response
        .use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    history.push('/login');
                }

                if (error.response.status === 404) {
                    history.push('/');
                }
            }

            return Promise.reject(error);
        });
}

export default instance;
