// ------------- REACT -------------
import React, { useState, useEffect } from 'react';

// ------------- AXIOS -------------
import axios from '../../../axios-usercharges';

// ------------- COMPONENTS -------------
import Navbar from '../../navbar/Navbar';

// ------------- REACT ROUTER DOM -------------
import { Link } from 'react-router-dom';

// ------------- REACT ICONS -------------
import { BsFileEarmarkPdf, BsArrowLeft } from 'react-icons/bs';

function PdfList() {
  // ------------- STATES -------------
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdfList = async () => {
      try {
        const response = await axios.post('/api/pdf-list', {
          country: 'Dominican Republic',
        });

        if (response.status !== 200) {
          throw new Error(`Request failed with status: ${response.status}`);
        }

        const pdfData = response.data;

        if (pdfData.error) {
          throw new Error(pdfData.error);
        }

        if (!Array.isArray(pdfData.pdfList)) {
          throw new Error('PDF list is not an array');
        }

        setPdfList(pdfData.pdfList);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchPdfList();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <>
      <Navbar index={5} />
      <div>
          <div style={{ fontSize: '30px' }}>
        <Link to="/ocr-route" style={{ textDecoration: 'none' }}>
            <BsArrowLeft />
        </Link>
          </div>
        {pdfList.length > 0 ? (
          pdfList.map((pdf, index) => (
            <a
              key={index}
              href={pdf.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial, sans-serif',
                margin: '10px 0',
              }}
            >
              <BsFileEarmarkPdf style={{ fontSize: '48px', color: 'red' }} />
              {pdf.name}
            </a>
          ))
        ) : (
          <div>No PDFs found for the specified country.</div>
        )}
      </div>
    </>
  );
  

}

export default PdfList;




