import React from "react";
import Navbar from "../navbar/Navbar";
import KanbanBoard from "./KanbanBoard/KanbanBoard";

const InvoicesARM = (props) => {

  return (
    <>
      <Navbar index={7}/>
      <div style={{width: "95%", margin: "0 auto"}}>
        <KanbanBoard/>
      </div>
    </>
  )
}

export default InvoicesARM;