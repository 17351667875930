import React, {useContext} from "react";

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Button} from "@mui/material";

// ------------- AXIOS -------------
import axios from "../../../../axios-usercharges";

import VarianceDetailsRowRevised from "./VarianceDetailsRowRevised";

import { biginAuthContext } from "../../../BiginContext/BiginContext";

function VarianceDetailsTableRevised (props) {
  const {
    slicedData, 
    filteredData, 
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage, 
    handleTrackIcon, 
    handleClaimsOpen,
    disputesData,
    setDisputesData,
    setSnackbar,
    month,
    year,
    anspData,
    invoiceBackendDate
  } = props;

  const {
    getAccessToken
  } = useContext(biginAuthContext)

  const tableHeaderCellStyle = {
    color: "white",
  };

  const saveClaims = async () => {
    await axios
      .post("/api/disputes", disputesData)
      .then((res) => {
          if (res.status == 202) {
              setSnackbar({
                  open: true,
                  message: res.data.message,
                  severity: "info",
              });
          } else {
              setSnackbar({
                  open: true,
                  message: res.data.message,
                  severity: "success",
              });
          }
      })
      .catch((error) => {
          setSnackbar({
              open: true,
              message: "Error creating disputes",
              severity: "error",
          });
      });

      console.log(disputesData);

      const totalCharge = disputesData.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.disputed_amount);
      }, 0)

      console.log(totalCharge);

      axios.post(`api/bigin/addclaim`, {
        ansp: anspData.ansp,
        anspEmail: anspData.credit_email,
        closingDate: invoiceBackendDate,
        charge: totalCharge,
        month: month,
        year: year,
        accessToken: getAccessToken(),
        subPipelineName: "Claims Processing",
        subPipelineStage: "New Request"
    })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error)
    });
  }

  const tableRows = slicedData.map((item, index) => {
    return <VarianceDetailsRowRevised
      item={item}
      key={index}
      disputesData={disputesData}
      setDisputesData={setDisputesData}
      handleTrackIcon={handleTrackIcon}
      handleClaimsOpen={handleClaimsOpen}
    />
  })
  return (
    <>
      <TableContainer>
          <div className="table-container customTable">
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell
                              style={tableHeaderCellStyle}
                          ></TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Date</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Flight Ref</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Aircraft Type</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Origin</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Destination</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>MTOW</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Charge</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Difference</b>
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                              <b>Claim</b>
                          </TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {tableRows}
                  </TableBody>
              </Table>
          </div>
          <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ width: "95%", margin: "0 auto 20px auto" }}
          />
      </TableContainer>
      <div style={{display: "flex", justifyContent: "end"}}>
        <Button variant="contained" onClick={saveClaims}>
          Save Claims
        </Button>
      </div>
    </>
  );
}

export default VarianceDetailsTableRevised;