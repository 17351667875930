import { withStyles, TableCell } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderRight: '1px dotted #cccccc',
        borderLeft: '1px dotted #cccccc',
        padding:"none"
    },
  }))(TableCell);

export default StyledTableCell;