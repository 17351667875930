// ------------- REACT -------------
import React, {useState} from "react";

// ------------- MUI -------------
import { styled, Box, Grid, Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";

// ------------- REACT ICONS -------------
import { BsFileEarmarkPdf } from "react-icons/bs";

// ------------- AXIOS -------------
import axios from "../../../axios-usercharges";

const Card = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  border: "1px solid #ccc",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
  margin: "10px",
  width: "220px",
  height: "60px",
}));

const BlueFolderIcon = styled(FolderIcon)({
  fontSize: 64,
  color: "#003487",
});

const FolderItem = ({ text }) => (
  <Card>
    <BlueFolderIcon />
    <Typography variant="subtitle1" style={{ marginLeft: "12px" }}>
      {text}
    </Typography>
  </Card>
);

const InteractiveList = () => {
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPdfList = async (countryName) => {
    console.log('countryName', countryName);
    document.getElementById('folders').style.display = 'block';
    setPdfList([]);
    try {
      const response = await axios.post("/api/pdf-list", {
        country: countryName,
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const pdfData = response.data;

      if (pdfData.error) {
        throw new Error(pdfData.error);
      }

      if (!Array.isArray(pdfData.pdfList)) {
        throw new Error("PDF list is not an array");
      }

      setPdfList(pdfData.pdfList);
      setLoading(false);
    }
    catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  return (
    <>
      <div id="countries" style={{width: "95%", margin: "0 auto"}}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap">
                <Button onClick={() => fetchPdfList('Dominican Republic')}>
                  <FolderItem text="Dominican Republic" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div id="folders" style={{ display: 'none', width: "95%", margin: "0 auto" }}>
        {pdfList.length > 0 ? (
          pdfList.map((pdf, index) => (
            <a
              key={index}
              href={pdf.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline",
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
                margin: "10px 0",
              }}
            >
              <BsFileEarmarkPdf style={{ fontSize: "48px", color: "red" }} />
              {pdf.name}
              <br></br>
            </a>
          ))
        ) : (
          <div style={{ color: "red" }}><b>No PDFs found for the specified country...</b></div>
        )}
      </div>
    </>
  );
};

export default InteractiveList;
