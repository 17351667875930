// ------------- REACT -------------
import React, { useState, useEffect, useContext } from "react";

// ------------- MUI -------------
import {
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { CircularProgress, TextField } from "@material-ui/core";

// ------------- LUXON -------------
import { DateTime } from "luxon";

// ------------- AXIOS -------------
import axios from "../../../axios-usercharges";

// ------------- COMPONENTS  -------------
import DataDisplayTable from "./table";
import ATCChargesModal from "../../Utility/ATCChargesModal";
import DisputeDataModal from "./DisputeDataModal/DisputeDataModal";
import PlaceHolder from "../../Utility/PlaceHolders/PlaceHolder";

// ------------- MOCKS -------------
import ATCCharges from "../../../mocks/ATCCharges";


const DataExtract = () => {
  // ------------- STATES -------------
  const [selectedCountry, setSelectedCountry] = useState("");
  const [yearMonths, setYearMonths] = useState([]);
  const [showDataExtract, setShowDataExtract] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [monthYearData, setMonthYearData] = useState([]);
  const [extractaData, setExtractaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [emailId, setEmail] = useState("");
  const [anspData, setAnspData] = useState(null);
  const [disputeData, setDisputeData] = useState([]);
  const [message, setMessage] = useState("")
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");

  // --- MODAL STATES ---
  const [disputesModalOpen, setDisputesModalOpen] = useState(false);
  const [ATCModalOpen, setATCModalOpen] = useState(false);



  

  const getFormattedDateFromMonthYear = (yearMonthString) => {
    if (yearMonthString && typeof yearMonthString === 'string' && yearMonthString.includes(' ')) {
      const [year, month] = yearMonthString.split(" ");
      const date = DateTime.fromObject({ year, month });
      const formattedString = `${date.toFormat("MMMM yyyy")}`;
      return formattedString;
    } else {
      return "No data";
    }
  };
  
  const fetchCountries = async () => {
    try {
      const response = await axios.get("/api/get-countries");
      if (response.status === 200) {
        setCountries(response.data.countries);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  
  const fetchYearMonthsForCountry = async (country) => {
    try {
      const response = await axios.post("/api/get-year_months", { country });
      if (response.status === 200) {
        setYearMonths(response.data.yearMonths);
      }
    } catch (error) {
      console.error("Error fetching year-months for country:", error);
    }
  };
  
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const [year, month] = selectedMonthYear.split(' ');
      setCurrentYear(year);
      setCurrentMonth(month);
      const country = selectedCountry;
      const response = await axios.post(`/api/get-month_years?country=${country}&month=${month}&year=${year}`,
      { month_year: selectedMonthYear });
      
      if (response.status === 200) {
        if (response.data.data) {
          setMonthYearData(response.data.data);
          setExtractaData(response.data.extractaData);
        } else {
          setMonthYearData([]); // Set to an empty array if no data
        }
      }
      
    } catch (error) {
      setError("An error occurred while retrieving data.");
    } finally {
      setIsLoading(false);
      setShowDataExtract(true); // Set showDataExtract to true when data is fetched
    }
  };
  
  
  const fetchAnspForCountry = async (country) => {
    try {
      const response = await axios.get(`/api/ansp/${country}`);
        if (response.status === 200) {
          setAnspData(response.data)
          setEmail(response.data.credit_email)
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
  }
  
  const fetchDisputeData = async () => {
    // setIsLoading(true);
    setError(null);
    
    try {
      const response = await axios.get(
        `/api/dispute?month_year=${selectedMonthYear}`
      );
      
      if (response.status === 200) {
        if (response.data) {
          setDisputeData(response.data);
        }
      }
    } catch (error) {
      setError("An error occurred while retrieving data.");
    } finally {
      // setIsLoading(false);
      // setShowDataExtract(true);
    }
  };
  
  const handleDeleteDispute = async (id) => {
    try {
      const response = await axios.delete(`/api/dispute/${id}`);
      // setMessage(response.data.message);
      setMessage("Success: Dispute deleted successfully");
      fetchDisputeData();
    } catch (error) {
      // setMessage(`Error: ${error.message}`);
      setMessage("Error: Failed to delete dispute");
    }
  };
  
  useEffect(() => {
    fetchCountries();
  }, []);
  
  useEffect(() => {
    if (selectedCountry) {
      fetchYearMonthsForCountry(selectedCountry);
      fetchAnspForCountry(selectedCountry);
    }
  }, [selectedCountry]);
  
  useEffect(() => {
    if (selectedMonthYear) {
      fetchData();
      fetchDisputeData();
    }
  }, [selectedMonthYear]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);
 
    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  const handleCountrySelect = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setShowDataExtract(false);
  };

  const handleYearMonthSelect = (event) => {
    const ym = event.target.value;
    setSelectedMonthYear(ym);
    setIsLoading(true); // Set loading to true before fetching data
    setError(null); // Clear any previous error messages
  };

  
  const handleOpen = () => {
    setDisputesModalOpen(true);
    fetchDisputeData();
  };
  
  const getCountryMenuItems = () => {
    return countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));
  };

  const getYearMonthsMenuItems = () => {
    const reversedYearMonths = [...yearMonths].reverse();

    return reversedYearMonths.map((ym) => {
      const formattedString = getFormattedDateFromMonthYear(ym);
      return (
        <MenuItem key={ym} value={ym}>
          {formattedString}
        </MenuItem>
      );
    });
  };

  const ErrorMessage = ({ message }) => {
    return (
      <div style={{ color: "red", marginTop: "10px" }}>
        <b>{message}</b>
      </div>
    );
  };
  
  return (
    <>
    <div style={{width:"95%", margin: "0 auto"}}>
      <div style={{padding: '0 25px 25px 25px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>   
                  <span style={{ fontSize: '18px' , minWidth: "150px"}}>Country:</span>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country-select"
                      value={selectedCountry}
                      label="Country"
                      onChange={handleCountrySelect}
                    >
                      {getCountryMenuItems()}
                    </Select>
                  </FormControl>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>   
                  <span style={{ fontSize: '18px', minWidth: "150px"}}>Date:</span>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel id="date-label">Date</InputLabel>
                    <Select
                      labelId="date-label"
                      id="date-select"
                      value={selectedMonthYear}
                      label="Date"
                      disabled={!selectedCountry}
                      onChange={handleYearMonthSelect}
                    >
                      {getYearMonthsMenuItems()}
                    </Select>
                  </FormControl>
              </div>
          </div>
      </div>
    </div>
      {(!showDataExtract && !isLoading) && 
        <PlaceHolder 
            title="OCR Data"
            description="Please select a country and date"
        />
      }

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
          }}
        >
          <CircularProgress size={65} />
        </div>
      ) : error ? (
        <ErrorMessage message={error} />
      ) : showDataExtract ? (
        <div>
          <div style={{
            width: "95%",
            margin: "0 auto",
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <Button variant="contained" onClick={handleOpen}>
              Claims
            </Button>
            <Button variant="contained" style={{marginLeft: "5%"}} onClick={() => setATCModalOpen(true)}>ATC Charges</Button>
            <ATCChargesModal object={ATCCharges.dominican} modalOpen={ATCModalOpen} setModalOpen={setATCModalOpen}/> 
          </div>
          <DataDisplayTable monthYearData={monthYearData} extractaData={extractaData} disputesData={disputeData} setDisputesData={setDisputeData} anspData={anspData} month={currentMonth} year={currentYear}/>
        </div>
      ) : null}
      <DisputeDataModal
        disputeData={disputeData}
        selectedCountry={selectedCountry}
        handleDeleteDispute={handleDeleteDispute}
        open={disputesModalOpen}
        setOpen={setDisputesModalOpen}
        emailId={emailId}
        setEmail={setEmail}

      />
    </>
  );
};

export default DataExtract;
