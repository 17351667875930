// ------------- REACT -------------
import React, { useEffect, useState } from "react";

// ------------- REACT APEX CHARTS -------------
import ReactApexChart from "react-apexcharts";

// ------------- AXIOS -------------
import axios from "../../../axios-usercharges";

// ------------- MUI -------------
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CircularProgress } from "@material-ui/core";

// ------------- LUXON -------------
import { DateTime } from "luxon";
import DateFnsUtils from "@date-io/luxon";

const Chart8 = () => {
    // ------------- STATES -------------
    const [chartData, setChartData] = useState({
        series: [{ data: [] }],
        options: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
                title: {
                    text: "Cost Outlay per Aircraft Type",
                },
            },
        },
    });
    const [selectedDate, setSelectedDate] = useState(DateTime.utc().toJSDate()); // Initialize with a JavaScript Date object
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData(selectedDate);
    }, [selectedDate]);

    const fetchData = (date) => {
        setIsLoading(true);
        const date_1 = new Date(date);
        const year = date_1.getFullYear();
        const month = (date_1.getMonth() + 1).toString().padStart(2, "0");
        const day = date_1.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        const selectedDateISO = formattedDate;

        axios
            .get(`/api/chart8?selectedDate=${selectedDateISO}`)
            .then((res) => {
                const categories = [];
                const data = [];

                res.data.data.forEach((item) => {
                    categories.push(item.aircraft_specs_value);
                    data.push(item.cost_sum);
                });

                setChartData((prevChartData) => ({
                    ...prevChartData,
                    series: [{ data: data }],
                    options: {
                        ...prevChartData.options,
                        xaxis: {
                            ...prevChartData.options.xaxis,
                            categories: categories,
                        },
                    },
                }));
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const startDate = DateTime.fromISO("2021-01-15").minus({ day: 1 });

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="antialiased">
                <div className="wrapper">
                    <div
                        style={{
                            width: "100%",
                            backgroundColor: "#e6e6e6",
                        }}
                    >
                        <KeyboardDatePicker
                            style={{ width: "200px", fontSize: "1.25rem" }}
                            value={selectedDate}
                            placeholder="01/01/2021"
                            onChange={(date) => handleDateChange(date)}
                            minDate={startDate}
                            maxDate={DateTime.utc().toJSDate()}
                            format="DD"
                        />
                        {isLoading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 500,
                                }}
                            >
                                <CircularProgress />
                            </div>
                        ) : (
                            <ReactApexChart
                                options={chartData.options}
                                series={chartData.series}
                                type="bar"
                                height={400}
                            />
                        )}
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default Chart8;
