// ------------- REACT -------------
import React, { useRef, useImperativeHandle, useState } from "react";

// ------------- MUI  -------------
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// ------------- GENERATORS -------------
import { generatePDF } from "../../../shared/pdf";
import { exportCSVFile } from "../../../shared/csv";

// ------------- RECHARTS -------------
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

// ------------- COMPONENTS -------------
import UpdatedGreatCircleTable from "./GreatCircleTable/UpdatedGreatCircleTable";
import FlightInfoBanner from "../FlightInfoBanner";
import CountryCostPieChart from "./CountryCostPieChart";
import Map from "./Map/Map";
import { generateEmailPDF } from "../../../shared/emailPdf";

const useStyles = makeStyles({
    root: {
        // margin: '5px',
    },
    table: {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Mukta",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        fontFamily: "Mukta",
    },
    colCell: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center'",
        margin: "2rem",
        fontFamily: "Mukta",
    },
});

const UpdatedGreatCircle = (props, ref) => {
    const componentRef = useRef();
    const hiddenMapRef = useRef();
    const mapRef = useRef();
    const tableRef = useRef();
    const [open, setOpen] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [email, setEmail] = useState("");
    // const { width } = useResize(componentRef);
    let content = "";
    const classes = useStyles();
    const width = 750;

    const handleEmail = () => {
        setOpen(true);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        setIsValidEmail(validateEmail(newEmail));
    };

    const handleCancelEmail = () => {
        setEmail("");
        setOpen(false);
    };

    const handleSendEmail = () => {
        setEmail("");
        setOpen(false);
        generateEmailGCPDF();
    };

    const wayPointsCoordinates =
        props.selectedFlightInfo.gcData.features[0].wayPointsC;
    const headers = {
        // 'properties.fir': 'FIR Code',
        // 'properties.fir_name': 'FIR Name',
        country: "FIR Country",
        dist_km: "SEG DIST",
        currency: "Currency",
        cost: "Charge",
        commonUsdCost: "Converted USD",
    };

    const newHeaders = [
        { id: "explore", value: "", align: "center", style: { width: "20px" } },
        // { id: 'firCode', value: 'FIR Code', 'align': 'center', },
        // { id: 'firName', value: 'FIR Name', 'align': 'center', },
        { id: "firCountry", value: "FIR Country", align: "center" },
        { id: "distKm", value: "SEG DIST (km)", align: "center" },
        // { id: 'elapsed_time', value: 'Elapsed Time', 'align': 'right', },
        { id: "currency", value: "Billing Currency", align: "center" },
        { id: "amount", value: "Invoiced Amount", align: "center" },
        { id: "usdAmount", value: "Converted USD", align: "center" },
    ];

    const tcHeaders = [
        {
            id: "departureTerminalCharges",
            value: "DEP TNC",
            align: "center",
        },
        {
            id: "departureUSDTerminalCharges",
            value: "DEP TNC USD",
            align: "center",
        },
        {
            id: "arrivalTerminalCharges",
            value: "ARR TNC",
            align: "center",
        },
        {
            id: "arrivalUSDTerminalCharges",
            value: "ARR TNC USD",
            align: "center",
        },
        {
            id: "departureTerminalChargesCurrency",
            value: "DEP TNC Currency",
            align: "center",
        },
        {
            id: "arrivalTerminalChargesCurrency",
            value: "ARR TNC Currency",
            align: "center",
        },
    ];

    const zoomToRoute = (row) => {
        mapRef.current.fitBounds(row);
    };

    const generateGCPDF = () => {
        const elements = document.getElementsByClassName(
            "custom-collapse-function"
        );

        const removedElements = [];
        if (elements.length > 0) {
            // Store references to the custom-collapse-function elements and their previous siblings
            while (elements.length > 0) {
                const ele = elements[0];
                const prevSibling = ele.previousElementSibling;
                if (
                    prevSibling &&
                    prevSibling.classList.contains("makeStyles-row-827")
                ) {
                    removedElements.push({
                        element: ele,
                        previousSibling: prevSibling,
                    });
                    ele.remove();
                } else {
                    // If the structure is unexpected, handle appropriately (e.g., log an error)
                    console.error(
                        "Unexpected structure: custom-collapse-function element does not follow a makeStyles-row-827 element."
                    );
                    ele.remove();
                }
            }
        }
        const gcData = props.selectedFlightInfo
            ? props.selectedFlightInfo.gcData.features
            : null;
        if (!gcData) return;
        const countryReducedGcData = Array.from(
            Object.values(countryReducedFeatures(gcData)).map((e) => ({
                ...e.properties,
                commonUsdCost: e.commonUsdCost,
            }))
        );
        const totalAmount = gcData.reduce(
            (acc, curr) => acc + curr.commonUsdCost,
            0
        );
        const data = gcData.map((gc) => ({
            ...gc.properties,
            commonUsdCost: gc.commonUsdCost.toFixed(2),
        }));

        generatePDF(
            tableRef.current,
            "OVERFLIGHT CHARGES",
            countryReducedGcData,
            [
                "stroke",
                "country",
                "dist_km",
                "currency",
                "cost",
                "commonUsdCost",
            ],
            "USD",
            totalAmount.toFixed(2),
            "OVERFLIGHT CHARGES.pdf",
            hiddenMapRef.current.addMapToPDF,
            {
                cost: (value) => Number(value).toFixed(2),
                commonUsdCost: (value) => Number(value).toFixed(2),
                dist_km: (value) => Number(value).toFixed(2),
            }
        );
        // Re-append the removed custom-collapse-function elements after their respective previous siblings
        removedElements.forEach(({ element, previousSibling }) => {
            if (previousSibling.parentNode) {
                previousSibling.insertAdjacentElement("afterend", element);
            } else {
                console.error("Parent node is missing for:", previousSibling);
            }
        });
    };

    const generateEmailGCPDF = () => {
        const elements = document.getElementsByClassName(
            "custom-collapse-function"
        );

        const removedElements = [];
        if (elements.length > 0) {
            // Store references to the custom-collapse-function elements and their previous siblings
            while (elements.length > 0) {
                const ele = elements[0];
                const prevSibling = ele.previousElementSibling;
                if (
                    prevSibling &&
                    prevSibling.classList.contains("makeStyles-row-827")
                ) {
                    removedElements.push({
                        element: ele,
                        previousSibling: prevSibling,
                    });
                    ele.remove();
                } else {
                    // If the structure is unexpected, handle appropriately (e.g., log an error)
                    console.error(
                        "Unexpected structure: custom-collapse-function element does not follow a makeStyles-row-827 element."
                    );
                    ele.remove();
                }
            }
        }
        const gcData = props.selectedFlightInfo
            ? props.selectedFlightInfo.gcData.features
            : null;
        if (!gcData) return;
        const countryReducedGcData = Array.from(
            Object.values(countryReducedFeatures(gcData)).map((e) => ({
                ...e.properties,
                commonUsdCost: e.commonUsdCost,
            }))
        );
        const totalAmount = gcData.reduce(
            (acc, curr) => acc + curr.commonUsdCost,
            0
        );
        const data = gcData.map((gc) => ({
            ...gc.properties,
            commonUsdCost: gc.commonUsdCost.toFixed(2),
        }));

        const flightData = props.selectedFlightInfo;
        const date = flightData.etd.split("T")[0];
        const fileTitle = `${flightData.departure}_${flightData.arrival}_${flightData.flightNumber}_${date}`;

        generateEmailPDF(
            tableRef.current,
            "OVERFLIGHT CHARGES",
            countryReducedGcData,
            [
                "stroke",
                "country",
                "dist_km",
                "currency",
                "cost",
                "commonUsdCost",
            ],
            "USD",
            totalAmount.toFixed(2),
            `${fileTitle}.pdf`,
            null,
            {
                cost: (value) => Number(value).toFixed(2),
                commonUsdCost: (value) => Number(value).toFixed(2),
                dist_km: (value) => Number(value).toFixed(2),
            },
            email
        );
        // Re-append the removed custom-collapse-function elements after their respective previous siblings
        removedElements.forEach(({ element, previousSibling }) => {
            if (previousSibling.parentNode) {
                previousSibling.insertAdjacentElement("afterend", element);
            } else {
                console.error("Parent node is missing for:", previousSibling);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        generateGreatCiclePDF() {
            generateGCPDF();
        },
        generateGCExel() {
            const gcData = props.selectedFlightInfo
                ? props.selectedFlightInfo.gcData.features
                : null;
            if (!gcData) return;
            const countryReducedGcData = Array.from(
                Object.values(countryReducedFeatures(gcData)).map((e) => {
                    return {
                        ...e.properties,
                        commonUsdCost: Number(e.commonUsdCost).toFixed(2),
                        cost: Number(e.properties.cost).toFixed(2),
                    };
                })
            );

            const data = props.selectedFlightInfo;
            const date = data.etd.split("T")[0];
            const fileTitle = `${data.departure}_${data.arrival}_${data.flightNumber}_${date}`;
            exportCSVFile(headers, countryReducedGcData, fileTitle);
        },
    }));

    const countryReducedFeatures = (data) =>
        data.reduce((acc, curr) => {
            const {
                commonUsdCost,
                properties,
                country,
                geometry,
                elapsed_time,
                allPoints,
                wayPointsC,
            } = curr;
            let object = {
                ...properties,
                usdCost: commonUsdCost,
            };
            if (!acc[country]) {
                acc[country] = {
                    country,
                    commonUsdCost,
                    properties: object,
                    geometry,
                    elapsed_time,
                    type: "Feature",
                };
            } else {
                const accData = acc[country];
                const accP = accData.properties;
                const tmpProperties = {
                    ...accData.properties,
                    cost: +accP.cost + +properties.cost,
                    usdCost: +accP.commonUsdCost + +properties.commonUsdCost,
                    dist_km: +accP.dist_km + +properties.dist_km,
                    elapsed_time: +elapsed_time,
                };

                const accG = accData.geometry;
                const tmpGeometry = {
                    ...accG,
                    coordinates: accG.coordinates.concat(geometry.coordinates),
                };
                acc[country] = {
                    ...acc[country],
                    commonUsdCost: +accData.commonUsdCost + +commonUsdCost,
                    properties: tmpProperties,
                    geometry: tmpGeometry,
                };
            }
            return acc;
        }, {});

    if (props.selectedFlightInfo && props.selectedFlightInfo.gcData) {
        const { gcData } = props.selectedFlightInfo;

        const countryReducedGcData = Array.from(
            Object.values(countryReducedFeatures(gcData.features))
        );
        const featureCollection = {
            type: "FeatureCollection",
            features: gcData.features,
        };

        const flight = countryReducedGcData.map((data) => {
            return data.properties;
        });

        const flight1 = countryReducedGcData.map((data) => {
            return (data.properties = {
                ...data.properties,
                elapsed_time: data.elapsed_time,
            });
        });

        const chargePerKm = (flight) => {
            const data = (+flight.usdCost / +flight.dist_km).toFixed(2);
            return data == Infinity ? 0 : data;
        };
        const costPerMin = (flight1) => {
            if (flight1.elapsed_time) {
                const time = flight1.elapsed_time;
                return (+flight1.usdCost / +time).toFixed(2);
            } else {
                return 0;
            }
        };

        content = (
            <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <FlightInfoBanner
                        selectedFlightInfo={props.selectedFlightInfo}
                        GreatCircleRef={ref}
                        componentRef={componentRef}
                        handleEmailChange={handleEmailChange}
                        handleCancelEmail={handleCancelEmail}
                        handleSendEmail={handleSendEmail}
                        handleEmail={handleEmail}
                        email={email}
                        isValidEmail={isValidEmail}
                        open={open}
                    />

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "50px",
                            justifyContent: "space-evenly",
                        }}
                        className="map-view"
                    >
                        <Map
                            geojson={featureCollection}
                            width={width}
                            height={500}
                            ref={mapRef}
                            style={{
                                border: "solid black",
                                fontFamily: "Mukta",
                            }}
                            wayPointsC={wayPointsCoordinates}
                            waypointsArray={
                                props.selectedFlightInfo.waypointsArray
                            }
                            selectedDate={props.selectedDate.c}
                            flightNumber={
                                "UAL" +
                                props.selectedFlightInfo.flightNumber.slice(3)
                            }
                        />
                        <Map
                            geojson={featureCollection}
                            width={1200}
                            height={600}
                            ref={hiddenMapRef}
                            hide={true}
                            style={{ fontFamily: "Mukta" }}
                            wayPointsC={wayPointsCoordinates}
                            selectedDate={props.selectedDate.c}
                            waypointsArray={
                                props.selectedFlightInfo.waypointsArray
                            }
                            flightNumber={
                                "UAL" +
                                props.selectedFlightInfo.flightNumber.slice(3)
                            }
                        />
                        <div
                            style={{ right: "40px", fontFamily: "Mukta" }}
                            className="flight-gc-data"
                        >
                            <UpdatedGreatCircleTable
                                features={countryReducedGcData}
                                firData={gcData.features}
                                zoomToRoute={zoomToRoute}
                                headers={newHeaders}
                                tcHeaders={tcHeaders}
                                ref={tableRef}
                                GreatCircleRef={ref}
                                flightData={props.selectedFlightInfo}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Grid container spacing={2} style={{ padding: "20px" }}>
                            {/* FIR Cost Per Country Pie chart*/}
                            <Grid className="first-chart" item xs={4}>
                                <Card
                                    style={{
                                        backgroundColor: "#e6e6e6",
                                        height: "450px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    <CountryCostPieChart
                                        data={countryReducedGcData}
                                    />
                                </Card>
                            </Grid>
                            {/* Charge Per KM bar chart*/}
                            <Grid className="second-chart" item xs={4}>
                                <Card
                                    style={{
                                        backgroundColor: "#e6e6e6",
                                        height: "450px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        align="center"
                                        style={{
                                            backgroundColor: "#180aa2",
                                            color: "white",
                                            marginBottom: "50px",
                                            fontFamily: "Mukta",
                                        }}
                                    >
                                        Charge Per KM
                                    </Typography>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontFamily: "Mukta",
                                        }}
                                    >
                                        <BarChart
                                            width={400}
                                            height={350}
                                            data={flight}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="country"
                                                interval={0}
                                                angle={-45}
                                                textAnchor="end"
                                                height={100}
                                            />
                                            <YAxis
                                                type="number"
                                                interval={0}
                                                tickFormatter={(tick) =>
                                                    "$" + tick
                                                }
                                                allowDecimals={true}
                                            />

                                            <Tooltip />
                                            <Bar
                                                dataKey={chargePerKm}
                                                fill="#4E81BD"
                                            />
                                        </BarChart>
                                    </Box>
                                </Card>
                            </Grid>
                            {/* Charge Per Min bar chart*/}
                            <Grid className="third-chart" item xs={4}>
                                <Card
                                    style={{
                                        backgroundColor: "#e6e6e6",
                                        height: "450px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        align="center"
                                        style={{
                                            backgroundColor: "#180aa2",
                                            color: "white",
                                            marginBottom: "50px",
                                            fontFamily: "Mukta",
                                        }}
                                    >
                                        Charge Per Min
                                    </Typography>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontFamily: "Mukta",
                                        }}
                                    >
                                        <BarChart
                                            width={400}
                                            height={350}
                                            data={flight1}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="country"
                                                interval={0}
                                                angle={-45}
                                                textAnchor="end"
                                                height={100}
                                            />
                                            <YAxis
                                                type="number"
                                                interval={0}
                                                tickFormatter={(tick) =>
                                                    "$" + tick
                                                }
                                                allowDecimals={true}
                                            />

                                            <Tooltip />
                                            <Bar
                                                dataKey={costPerMin}
                                                fill="#9DBD5B"
                                            />
                                        </BarChart>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }
    return <div ref={componentRef}>{content}</div>;
};

export default React.forwardRef(UpdatedGreatCircle);
