import React from "react";

import { TableRow, TableCell, Chip, Button, IconButton, Checkbox } from "@mui/material";

import ExploreIcon from "@material-ui/icons/Explore";



function VarianceDetailsRowRevised (props) {
  const {item, handleTrackIcon, handleClaimsOpen, disputesData, setDisputesData} = props

  const mismatchCellStyle = {
    color: "red",
  };

  function areValuesEqual(value1, value2) {
    const epsilon = 0.01;
    return Math.abs(value1 - value2) < epsilon;
  }


  const checkForMatch = (item) => {
    return (item.date === item.betaInvoiceEtd &&
    item.flight_ref === item.betaInvoiceFlihtRef &&
    item.aircraftType === item.betaInvoiceAircraftType &&
    item.origin === item.start &&
    item.destination === item.end 
    //&& item.mtow === item.betaInvoiceMtow
    );
  }

    // Determine the explanation for the claim
    function getExplanationDetails() {
      if(item.date !== item.betaInvoiceEtd ||
        item.flight_ref !== item.betaInvoiceFlihtRef){
        return "Flight Did Not Operate"
      }
      if(item.origin !== item.start) {
        return "Incorrect Origin Airport"
      }
      if(item.destination !== item.end) {
        return "Incorrect Destination Airport"
      }
      if(item.charge !== item.BetaInvoiceCharge){
        return "Incorrect Charge"
      }
      if(item.mtow !== (item.betaInvoiceMtow || 0)) {
        return "Incorrect MTOW"
      }
  }

  function getCurrentDisputeData () {
     return disputesData ? disputesData.find((value)=>
      {return value.flight_number === item.flight_ref
        && value.date === item.date
        && value.tail_number === item.tailNumber
      }) 
    : null
  }

  function handleCheckboxChange () {
    const currentDisputeData = getCurrentDisputeData()
    console.log(currentDisputeData);
    if(currentDisputeData && !currentDisputeData.deleted) {
      // If it already exists in the db
      if(currentDisputeData.id){
        currentDisputeData.deleted = true;
        setDisputesData([...disputesData.filter((disputeData) => 
          {return !(disputeData.flight_number === item.flight_ref
          && disputeData.date === item.date
          && disputeData.tail_number === item.tailNumber)
        }), currentDisputeData]);
      } else {
        setDisputesData(disputesData.filter((disputeData) => 
          {return !(disputeData.flight_number === item.flight_ref
          && disputeData.date === item.date
          && disputeData.tail_number === item.tailNumber)
        }));
      }
    } else {
      if(currentDisputeData){
        currentDisputeData.deleted = false;
        setDisputesData([...disputesData.filter((disputeData) => 
          {return !(disputeData.flight_number === item.flight_ref
          && disputeData.date === item.date
          && disputeData.tail_number === item.tailNumber)
        }), currentDisputeData]);
      } else {
        const date = new Date(item.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        const formattedMonthYear = `${year} ${month
            .toString()
            .padStart(2, "0")}`;

        const amount=parseInt(item.charge)
        const dispute = {
          flight_ref: item.betaInvoiceFlihtID || "",
          flight_number: item.flight_ref || "0",
          tail_number: item.tailNumber,
          disputed_amount: parseFloat(amount).toFixed(2),
          dispute_reason: getExplanationDetails(),
          month_year: formattedMonthYear || "-",
          date: item.date,
          deleted: false,
        }

        setDisputesData([...disputesData, dispute]);
      }
    }
  }

  return (
    <>
        <React.Fragment>
            <TableRow>
                <TableCell rowSpan={2} align="center">
                    {item.betaInvoiceFlihtRef ? (
                        <IconButton
                            style={{ padding: "0px" }}
                            variant="outlined"
                            onClick={() =>
                                handleTrackIcon(
                                    item.betaInvoiceFlihtID,
                                    item.tailNumber
                                )
                            }
                        >
                            {
                                <ExploreIcon
                                    style={{
                                        color: "#bd0026",
                                    }}
                                />
                            }
                        </IconButton>
                    ) : (
                        "-"
                    )}
                </TableCell>

                <TableCell
                    rowSpan={
                        item.betaInvoiceEtd ? 2 : 1
                    }
                >
                    {item.date || "-"}
                </TableCell>
                <TableCell>
                    {item.flight_ref || "-"}
                </TableCell>
                <TableCell>
                    {item.aircraftType || "-"}
                </TableCell>
                <TableCell>
                    {item.origin || "-"}
                </TableCell>
                <TableCell>
                    {item.destination || "-"}
                </TableCell>
                <TableCell>
                    {item.mtow || "0.00"}
                </TableCell>
                <TableCell>
                    {item.charge || "0.00"}
                </TableCell>
                <TableCell
                    rowSpan={2}
                    style={
                        item.charge <=
                        item.BetaInvoiceCharge
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    $
                    {item.BetaInvoiceCharge !==
                    undefined
                        ? Math.abs(
                              item.charge -
                                  item.BetaInvoiceCharge
                          ).toFixed(2)
                        : item.charge}
                </TableCell>
                <TableCell rowSpan={checkForMatch(item) ? 2 : 1}>
                    {checkForMatch(item) && 
                    areValuesEqual(
                        item.charge,
                        item.BetaInvoiceCharge
                    ) ? (
                        <Chip
                            label="MATCHED"
                            variant="contained"
                            color="success"
                        />
                    ) : (
                      <>
                        <span style={{paddingRight: "10px"}}>Claim</span>
                        <Checkbox style={{padding: "0"}}
                          checked={(getCurrentDisputeData() && !getCurrentDisputeData().deleted) ? true : false}
                          onChange={handleCheckboxChange}
                        />
                      </>
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                {!item.betaInvoiceEtd && <TableCell />}
                <TableCell
                    style={
                        item.flight_ref ===
                        item.betaInvoiceFlihtRef
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.betaInvoiceFlihtRef || "-"}
                </TableCell>
                <TableCell
                    style={
                        item.aircraftType ===
                        item.betaInvoiceAircraftType
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.betaInvoiceAircraftType ||
                        "-"}
                </TableCell>
                <TableCell
                    style={
                        item.origin === item.start
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.start || "-"}
                </TableCell>
                <TableCell
                    style={
                        item.destination === item.end
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.end || "-"}
                </TableCell>
                <TableCell
                    style={
                        item.mtow ===
                        item.betaInvoiceMtow
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.betaInvoiceMtow || "0.00"}
                </TableCell>
                <TableCell
                    style={
                        item.charge ===
                        item.BetaInvoiceCharge
                            ? {}
                            : mismatchCellStyle
                    }
                >
                    {item.BetaInvoiceCharge !==
                    undefined
                        ? item.BetaInvoiceCharge
                        : "0.00"}
                </TableCell>
                {!checkForMatch(item) && 
                  <TableCell style={mismatchCellStyle}>
                    <span>{getExplanationDetails()}</span>
                  </TableCell>
                  }
            </TableRow>
        </React.Fragment>
    </>
  );
}

export default VarianceDetailsRowRevised;