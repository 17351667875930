import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTime } from "luxon";
import axios from "../../axios-usercharges";
import DataExtractFlorida from "./DataExtract/DataDB-florida";
import { CircularProgress } from "@material-ui/core";
import PlaceHolder from "../Utility/PlaceHolders/PlaceHolder";

const DataExtractFromDatabase = () => {
    const [selectedCountry, setSelectedCountry] = useState("");
    const [yearMonths, setYearMonths] = useState([]);
    const [showDataExtract, setShowDataExtract] = useState(false);
    const [selectedMonthYear, setSelectedMonthYear] = useState("");
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);

    // const getFormattedDateFromMonthYear = (yearMonthString) => {
    //     const [year, month] = yearMonthString.split(" ");

    //     const date = DateTime.fromObject({ year, month });
    //     const formattedString = `${date.toFormat("MMMM yyyy")}`;
    //     return formattedString;
    // };

    const getFormattedDateFromMonthYear = (yearMonthString) => {
        if (yearMonthString && typeof yearMonthString === 'string' && yearMonthString.includes(' ')) {
          const [year, month] = yearMonthString.split(" ");
          const date = DateTime.fromObject({ year, month });
          const formattedString = `${date.toFormat("MMMM yyyy")}`;
          return formattedString;
        } else {
          return "No data";
        }
      };

    const fetchCountries = async () => {
        try {
            const response = await axios.get("/api/get-countries");
            if (response.status === 200) {
                setCountries(response.data.countries);
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    const fetchYearMonthsForCountry = async (country) => {
        try {
            const response = await axios.post("/api/get-year_months", { country });
            if (response.status === 200) {
                setYearMonths(response.data.yearMonths);
            }
        } catch (error) {
            console.error("Error fetching year-months for country:", error);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post("/api/get-xtracta-data", {
                month_year: selectedMonthYear,
            });

            if (response.status === 200) {
                if (response.data.data) {
                    setData(response.data.data);
                } else {
                    setError("Data not found for the specified date.");
                }
            } else {
                setError("Data not found for the specified date.");
            }
        } catch (error) {
            setError("An error occurred while retrieving data from the database.");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (selectedCountry) {
            fetchYearMonthsForCountry(selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedMonthYear) {
            fetchData();
        }
    }, [selectedMonthYear]);

    const handleCountrySelect = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        setShowDataExtract(false);
    };

    const handleYearMonthSelect = (event) => {
        const ym = event.target.value;
        setSelectedMonthYear(ym);
        setShowDataExtract(true);
    };

    const getCountryMenuItems = () => {
        return countries.map((country) => (
            <MenuItem key={country} value={country}>
                {country}
            </MenuItem>
        ));
    };

    const getYearMonthsMenuItems = () => {
        const reversedYearMonths = [...yearMonths].reverse();

        return reversedYearMonths.map((ym) => {
            const formattedString = getFormattedDateFromMonthYear(ym);
            return (
                <MenuItem key={ym} value={ym}>
                    {formattedString}
                </MenuItem>
            );
        });
    };

    const ErrorMessage = ({ message }) => {
        return (
            <div style={{ color: "red", marginTop: "10px" }}>
                <b>{message}</b>
            </div>
        );
    };

    return (
        <>
            <div style={{width:"95%", margin: "0 auto"}}>
                <div style={{padding: '0 25px 25px 25px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>   
                            <span style={{ fontSize: '18px' , minWidth: "150px"}}>Country:</span>
                            <FormControl sx={{ m: 1, minWidth: 300 }}>
                                <InputLabel id="country-label">Country</InputLabel>
                                <Select
                                labelId="country-label"
                                id="country-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={handleCountrySelect}
                                >
                                {getCountryMenuItems()}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>   
                            <span style={{ fontSize: '18px', minWidth: "150px"}}>Date:</span>
                            <FormControl sx={{ m: 1, minWidth: 300 }}>
                                <InputLabel id="date-label">Date</InputLabel>
                                <Select
                                labelId="date-label"
                                id="date-select"
                                value={selectedMonthYear}
                                label="Date"
                                disabled={!selectedCountry}
                                onChange={handleYearMonthSelect}
                                >
                                {getYearMonthsMenuItems()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        
            {(!showDataExtract && !isLoading) && 
                <PlaceHolder 
                    title="OCR Data"
                    description="Please select a country and date"
                />
            }
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "80px",
                    }}
                >
                    <CircularProgress size={65} />
                </div>
            ) : error ? (
                <ErrorMessage message={error} />
            ) : (
                showDataExtract && (
                    <div
                        // style={{
                        //     display: "flex",
                        //     justifyContent: "center",
                        //     alignItems: "center",
                        // }}
                    >
                        <DataExtractFlorida data={data}/>
                    </div>
                )
            )}
        </>
    );
};

export default DataExtractFromDatabase;

// import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { getFormattedDateFromMonthYear } from '../../../shared/util';
// import { DateTime } from 'luxon';
// import axios from '../../../axios-usercharges';
// import DataExtract from './DataExtract-florida';

// const SelectSmall = () => {
//     const [selectedYearMonth, setSelectedYearMonth] = React.useState('');
//     const [selectedCountry, setSelectedCountry] = React.useState('');
//     const [yearMonths, setYearMonths] = React.useState([]);
//     const [documentId, setDocumentId] = React.useState(null);
//     const [uploadMessage, setUploadMessage] = React.useState('');
//     const [workflowId, setWorkflowId] = React.useState('');
//     const [loading, setLoading] = React.useState(false);
//     const [showDataExtract, setShowDataExtract] = React.useState(false);

//     const workflowIdOptions = [
//         'EuroControl',
//         'Germany',
//         'Brazil',
//         'Others',
//         'Ireland',
//         'Mexico',
//         'Canada',
//         'Dominican Republic',
//     ];

//     const onDrop = async (acceptedFiles) => {
//         try {
//             setLoading(true);

//             const xtractaRequestBody = new FormData();
//             xtractaRequestBody.append('documentId', documentId);
//             xtractaRequestBody.append('workflowId', workflowId);

//             const response = await axios.post(`/api/retrieve-data`, xtractaRequestBody);

//             setDocumentId(response.data.document_id);
//             setTimeout(() => {
//                 setUploadMessage(null);
//             }, 5000);
//         } catch (error) {
//             setUploadMessage('An error occurred while processing the PDF. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };
//     React.useEffect(() => {
//         if (selectedYearMonth) {
//             onDrop(selectedYearMonth);
//         }
//     }, [selectedYearMonth]);

//     const getYearMonthsForCountry = () => {
//         const currentDate = DateTime.local();
//         const months = [];

//         for (let i = 0; i < 6; i++) {
//             const formattedMonth = currentDate.minus({ months: i }).toFormat('yyyy MM');
//             months.push(formattedMonth);
//         }

//         return months;
//     };

//     const handleCountrySelect = (event) => {
//         const country = event.target.value;
//         setSelectedCountry(country);
//         const yearMonthsForCountry = getYearMonthsForCountry(country);
//         setYearMonths(yearMonthsForCountry);
//     };

//     const handleYearMonthSelect = (event) => {
//         const ym = event.target.value;
//         setSelectedYearMonth(ym);
//         setShowDataExtract(true);
//     };

//     const getCountryYearMonthsMenuItems = () => {
//         return yearMonths.map(ym => {
//             const formattedString = getFormattedDateFromMonthYear(ym);
//             return (
//                 <MenuItem key={ym} value={ym}>
//                     {formattedString}
//                 </MenuItem>
//             );
//         });
//     };

//     return (
//         <>
//             <div style={{ marginTop: "-63px", marginLeft: "515px" }}>
//                 <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                     <InputLabel id="country-label">Country</InputLabel>
//                     <Select
//                         labelId="country-label"
//                         id="country-select"
//                         value={selectedCountry}
//                         label="Country"
//                         onChange={handleCountrySelect}
//                     >
//                         {Object.keys(workflowIdOptions).map((id) => (
//                             <MenuItem key={id} value={id}>
//                                 {workflowIdOptions[id]}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>

//                 <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                     <InputLabel id="date-label">Date</InputLabel>
//                     <Select
//                         labelId="date-label"
//                         id="date-select"
//                         value={selectedYearMonth}
//                         displayEmpty
//                         disabled={!selectedCountry}
//                         onChange={(event) => {
//                             handleYearMonthSelect(event);
//                             onDrop(selectedYearMonth);
//                         }}
//                     >
//                         {getCountryYearMonthsMenuItems()}
//                     </Select>
//                 </FormControl>
//             </div>

//             {showDataExtract && (
//                 <div style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                 }}>
//                     <DataExtract documentId="306365948" />
//                 </div>
//             )}
//         </>
//     );
// }

// export default SelectSmall;
