// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import {
    makeStyles,
    useTheme,
    Button,
    Dialog,
    useMediaQuery,
} from "@material-ui/core";

// ------------- COMPONENTS -------------
import UpdatedGreatCircle from "./GreatCircle/UpdatedGreatCircle";

// ------------- MUI STYLES -------------
const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: "80vh",
        maxHeight: "80vh",
    },
}));

export default function FlightMapModal({
    selectedFlightInfo,
    selectedDate,
    setModalOpen,
    GreatCircleRef,
}) {
    // ------------- MUI STYLES -------------
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // getModalStyle is not a pure function, we roll the style only on the first render
    const modalBody = (
        <div>
            <h2 style={{ textAlign: "center", fontFamily: "Mukta" }}>
                ROUTE COST ANALYSIS
            </h2>
            <Button
                color="secondary"
                style={{
                    position: "absolute",
                    color: "red",
                    top: "-10px",
                    right: "-10px",
                    fontSize: "1.5rem",
                }}
                onClick={() => setModalOpen(false)}
            >
                X
            </Button>
            <UpdatedGreatCircle
                ref={GreatCircleRef}
                selectedFlightInfo={selectedFlightInfo}
                selectedDate={selectedDate}
            />
        </div>
    );

    return (
        <div>
            <Dialog
                fullWidth
                classes={{ paper: classes.paper }}
                maxWidth="xl"
                open={true}
            >
                {modalBody}
            </Dialog>
        </div>
    );
}
