// ------------- REACT -------------
import React from "react"

// ------------- MUI -------------
import {Button, TextField, Chip, Typography } from '@material-ui/core';

// ------------- COMPONENTS -------------
import MoreVertIcon from '@mui/icons-material/MoreVert';

function GroupTableRow(props) {
  return(
    <>   
      <tr style={{border: "1px solid lightgrey", textAlign: "center"}}>
        <td>
          <div style={{padding: "10px", display: "flex", justifyContent:"center", alignItems: "center", color: "grey"}}>
            <input type="checkbox"/>
            <span style={{marginLeft: "2px"}}>
              {props.contact.name ? props.contact.name : "(not joined yet)"}
            </span>
          </div>
        </td>
        <td>
          <span style={{fontWeight: "bold"}}>{props.contact.email}</span>
        </td>
        <td>
        <TextField
          variant='outlined'
          size="small"
          value={props.contact.billableRate}
          InputProps={{endAdornment: 
            <Button variant="outlined">Change</Button>
          }}
        />
        </td>
        <td>
          {props.contact.role ? 
            <Chip label={props.contact.role} color="primary"/>
            :
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2"}}>
              <Chip label="+" color="primary" size="small"/>
              <Typography color="primary" style={{marginLeft: "5px"}}>Role</Typography>
            </div>
          }
        </td>
        <td>
          <MoreVertIcon/>
        </td>
      </tr>
    </>
  );
}

export default GroupTableRow;