import React, {useState} from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";

const KanbanBoard = (props) => {
  const [generatedInvoice, setGeneratedInvoice] = useState([]);
  const [sentInvoice, setSentInvoice] = useState([]);

  return (
    <DragDropContext>
      <h2 style={{textAlign: "center"}}> Invoices</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row"
        }}>
          
          <Column id={"1"} />
          <Column id={"2"} />
      </div>
    </DragDropContext>
  )
}

export default KanbanBoard;