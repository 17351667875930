// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { makeStyles } from '@material-ui/core';

// ------------- RECHARTS -------------
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        left: -999
    }
}));

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {

    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text
            x={x}
            y={y}
            fill="white"
            stroke="black"
            strokeWidth={1}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {(percent * 100) > 2 ? `${(percent * 100).toFixed(0)}%` : ''}
        </text>
    );
};


const SimplePieChart = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { gcData } = props;
    const width = 550;
    const height = 350;
    const firData = [];
    let total = 0;
    // populate data array
    gcData.features.forEach(feature => {
        const dist = parseInt(feature.properties.dist_km);
        total += dist;
        firData.push({
            "name": feature.properties.fir,
            "value": dist,
            "color": feature.properties.stroke
        });
    });

    return (
        <div style={{ 'width': `${width}px` }} ref={ref}  >
            <PieChart width={500} height={325}>
                <Pie
                    data={firData}
                    cx={230}
                    cy={125}
                    labelLine={false}
                    label={renderLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    isAnimationActive={false}
                >
                    {firData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Legend />
                <Tooltip />
            </PieChart>
        </div>
    );
});

export default SimplePieChart;


