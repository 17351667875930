import React from "react";
import Navbar from "../navbar/Navbar";
import { Icon } from "@material-ui/core";

// ------------- LOGOS -------------
import ualLogo from "../../assets/logo/ual_logo.jpg";

import InvoiceTable from "./InvoiceTable";

function InvoiceViewer () {
  return (
    <>
      <Navbar index={6}/>
      <div style={{width:"95%", margin: "0 auto", paddingTop: "30px"}}>
            <div
                style={{
                    marginTop: '15px',
                    backgroundColor: '#180aa2',
                    fontSize: '22px',
                    padding: '10px 20px',
                    marginBottom: "20px",
                    fontWeight: '700',
                    color: 'white'
                }}
            >
                <span>Invoice Viewer</span>
            </div>
            <InvoiceTable/>
        </div>
        
    </>
  );
}

export default InvoiceViewer;