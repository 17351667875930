// @flow
import * as React from 'react';
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Checkbox } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StyledTableRow from '../../../../Utility/TableStyles/StyledTableRow';
import StyledTableCell from '../../../../Utility/TableStyles/StyledTableCell';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '500px',
    marginBottom: '10px'
  },
    tableHeader: {
      background: '#DFCE97',
      '& th': {
        // color: theme.palette.common.white,
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        color: 'black',
        border: '1px solid #fff',
      }
    },
    rowSelected: {
      backgroundColor: theme.palette.action.selected + ' !important'
    },
    checkBox: {
      padding: '0px'
    }
  }));
  
  const ResulTable =  React.forwardRef((props, ref) => {
    const classes = useStyles();

    const rows = props.routes.map((route) => {
      const isSelected = props.selectedFlight === route.file_id;
      const rowClasses = isSelected  ? classes.rowSelected : ''
      return (
              <StyledTableRow onClick={() => props.handleResultTableSelection(route.file_id)} className={rowClasses}>
                <StyledTableCell align="left"><Checkbox className={classes.checkBox} checked={isSelected}/></StyledTableCell>
                <StyledTableCell align="left">{route.flight_num}</StyledTableCell>
                <StyledTableCell align="left">{route.origin}</StyledTableCell>
                <StyledTableCell align="left">{route.dest}</StyledTableCell>
                <StyledTableCell align="left">{route.acft_type}</StyledTableCell>
                <StyledTableCell align="center">{route.route_freq}</StyledTableCell>
                {/* <StyledTableCell align="center"><NumberFormat value={route.avg_tow} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell> */}
                <StyledTableCell align="center"><NumberFormat value={route.avg_dist} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
                <StyledTableCell align="center"><NumberFormat value={route.avg_cost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
                <StyledTableCell align="left">{route.route_data}</StyledTableCell>
            </StyledTableRow>
    )});

  return (
    <TableContainer component={Box} elevation={1} ref={ref} className={classes.root} >
      <Table  size="small" aria-label="a dense table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Flight No.</TableCell>
            <TableCell align="center">Origin</TableCell>
            <TableCell align="center">Destination</TableCell>
            <TableCell align="center">Aircraft Type</TableCell>
            <TableCell align="center">Number of Flights</TableCell>
            {/* <TableCell align="center">TOW (kg)</TableCell> */}
            <TableCell align="center">Distance (N.M.)</TableCell>
            <TableCell align="center">Total Charge (USD)</TableCell>
            <TableCell align="left">Route</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );

});

export default ResulTable;