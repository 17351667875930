import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import ReactMapGL, {
    NavigationControl,
    Source,
    Layer,
    WebMercatorViewport,
    FlyToInterpolator,
    Marker,
    Popup,
} from "react-map-gl";
import bbox from "@turf/bbox";
import { makeStyles } from "@material-ui/core";
import { addLogo } from "../../../../shared/pdf";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import "./Map.css";

const dataLayer = {
    id: "data",
    type: "line",
    paint: {
        "line-opacity": 1,
        "line-color": ["get", "stroke"],
        "line-width": 3,
    },
};

const labelLayer = {
    id: "poi-labels",
    type: "symbol",
    layout: {
        "symbol-placement": "line-center",
        "text-field": ["concat", "$", ["get", "commonUsdCost"]],
        "text-anchor": "bottom",
        // 'text-font': ['Arial', 'Open Sans Regular'],
        "text-radial-offset": 0,
        "text-justify": "auto",
        visibility: "visible",
    },
    paint: {
        "text-color": ["get", "stroke"],
    },
};

const useStyles = makeStyles((theme) => ({
    root: {},
    hide: {
        position: "absolute",
        left: "-9999px",
    },
}));

const Map = (props, ref) => {
    const classes = useStyles({});

    const [viewport, setViewport] = useState({
        width: props.width - 100,
        height: props.height - 90,
        latitude: 0,
        longitude: 0,
        zoom: 20,
    });

    const [selectedMarker, setSelectedMarker] = useState(null);
    const mapRef = useRef(null);
    const rootClasses = props.hide
        ? [classes.root, classes.hide]
        : [classes.root];
    const changeViewportByFeatures = (featuresCollection) => {
        // calculate the bounding box of the feature
        const [minLng, minLat, maxLng, maxLat] = bbox(featuresCollection);
        // construct a viewport instance from the current state
        const webMeractorViewport = new WebMercatorViewport(viewport);
        const { longitude, latitude, zoom } = webMeractorViewport.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat],
            ],
            {
                padding: 40,
            }
        );
        setViewport({
            ...viewport,
            longitude,
            latitude,
            zoom,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
            transitionDuration: 1000,
        });
    };

    useImperativeHandle(ref, () => ({
        fitBounds(features) {
            changeViewportByFeatures(features);
        },
        addMapToPDF(
            doc,
            title,
            xPos,
            yPos,
            width,
            margin,
            PDF_MAX_HEIGHT,
            dataUrl
        ) {
            const MAP_HEIGHT = 100;
            const MAP_WIDTH = 200;
            if (yPos + MAP_HEIGHT + 15 > PDF_MAX_HEIGHT) {
                doc.addPage();
                addLogo(doc, dataUrl, title);
                yPos = margin.top + 15;
            }
            doc.addImage(
                mapRef.current.getMap().getCanvas().toDataURL("image/png", 1),
                "PNG",
                5,
                yPos,
                MAP_WIDTH,
                MAP_HEIGHT,
                null,
                "FAST"
            );
            doc.save(
                `${props.flightNumber}/${props.selectedDate.day}-${props.selectedDate.month}-${props.selectedDate.year}.pdf`
            );
        },
    }));
    useEffect(() => {
        if (props.geojson && props.width > 0) {
            changeViewportByFeatures(props.geojson);
        }
    }, [props.geojson, props.width]);
    const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

    console.log(props.geojson);
    let wayData = [];
    if (props.waypointsArray.length > 0) {
        wayData = JSON.parse(props.waypointsArray);
    }

    const SIZE = 10;
    return (
        <div className={rootClasses.join(" ")}>
            <ReactMapGL
                style={{ border: "solid #708090", borderRadius: "2%" }}
                {...viewport}
                mapStyle="mapbox://styles/urmjs/cka49y5pt16n71io7t5kh9fpx"
                mapboxApiAccessToken={
                    "pk.eyJ1IjoidXJtanMiLCJhIjoiY2thNGFrNm93MDcyOTNlbWJxczJ6Ym96aCJ9.cFm84e8mjb9O376GdeS5uQ"
                }
                onViewportChange={(nextViewport) => setViewport(nextViewport)}
                attributionControl={false}
                ref={mapRef}
                preserveDrawingBuffer
            >
                <Source type="geojson" data={props.geojson}>
                    <Layer {...dataLayer} />
                    <Layer {...labelLayer} />
                </Source>
                <div style={{ position: "absolute", top: 5, right: 10 }}>
                    <NavigationControl />
                </div>
                <div>
                    {wayData.map((waypoint, index) => (
                        <Marker
                            key={waypoint.fix}
                            latitude={waypoint.latitude}
                            longitude={waypoint.longitude}
                        >
                            {/* <div style={{fontSize:'x-small',color:'#6A5ACD'}}>{waypoint.fix}</div>  */}
                            <svg
                                onMouseEnter={() => {
                                    setSelectedMarker(waypoint);
                                }}
                                onMouseLeave={() => setSelectedMarker(null)}
                                height={SIZE}
                                viewBox="0 0 24 24"
                                style={{
                                    cursor: "pointer",
                                    fill: "#6183A6",
                                    stroke: "none",
                                    transform: `translate(${
                                        -SIZE / 2
                                    }px,${-SIZE}px)`,
                                }}
                            >
                                <path d={ICON} />
                            </svg>
                        </Marker>
                    ))}

                    {selectedMarker && (
                        <Popup
                            key={selectedMarker.fix}
                            latitude={selectedMarker.latitude}
                            longitude={selectedMarker.longitude}
                            closeButton={false}
                            closeOnClick={true}
                            anchor="top"
                            dynamicPosition={false}
                        >
                            <div>{selectedMarker.fix}</div>
                        </Popup>
                    )}
                </div>

                {/* {props.wayPointsC.map((waypoint) => (
     <CustomMarker
               key={`marker-${waypoint.seq_fix}`}
               index={waypoint.seq_fix}
               marker={waypoint}
               openPopup={openPopup}
              />
              ))} */}
            </ReactMapGL>
        </div>
    );
};

export default React.forwardRef(Map);
// {props.wayPointsC.map((waypoint)=>{
//   <Marker
//   key = {waypoint.fix}
//   latitude = {waypoint.latitude}
//   longitude = {waypoint.longitude} ></Marker>
// })}
