import * as React from "react";
import { useState } from "react";
import ExploreIcon from "@material-ui/icons/Explore";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles,
    Box,
    IconButton,
    Button,
    withStyles,
    Collapse,
    Tooltip,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import StyledTableCell from "../../../Utility/TableStyles/StyledTableCell";
import HelpIcon from "@mui/icons-material/Help";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        background: theme.palette.common.white,
        height: "420px",
    },
    tableHeader: {
        background: theme.palette.info.dark,
        "& th": {
            color: theme.palette.common.white,
            border: "1px solid #fff",
        },
    },
    row: {
        background: theme.palette.common.white,
    },
    strokeCell: {
        padding: "0px",
    },
    currencyCell: {
        background: "#1D9EFF",
        color: "#fff",
    },
    amountCell: {
        background: "#0070C0",
        color: "#fff",
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(4n + 1)": {
            backgroundColor: theme.palette.action.hover,
            padding: "none",
        },
        "&:nth-of-type(4n + 2)": {
            backgroundColor: theme.palette.action.hover,
            padding: "none",
        },
    },
}))(TableRow);

const StyledInnerTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#D7D7D7",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#F4F4F4",
        },
    },
}))(TableRow);

const StyledMiniTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#4287f5",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 12,
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

const getHoursMinutes = (seconds) =>
    new Date(seconds * 1000).toISOString().substr(11, 5);

const DetailedFirs = (props) => {
    const classes = useStyles();

    const firRows = props.firs.map((f) => {
        const {
            cost,
            country,
            waypoints,
            distance_nm,
            elapsed_time,
            geometry,
            flight_ref,
            long_name,
            tag,
            commonUsdCost,
            tag_order,
        } = f;
        return {
            tag_order,
            tag,
            cost,
            long_name,
            distance_nm,
            geometry,
            commonUsdCost,
            waypoints: waypoints && waypoints.join("→"),
            elapsed_time: getHoursMinutes(elapsed_time),
        };
    });

    const headers = [
        { label: " ", align: "center" },
        { label: "FIR TAG", align: "center" },
        { label: "FIR NAME", align: "center" },
        { label: "FIR COST", align: "center" },
        { label: "FIR USD COST", align: "center" },
        // { label: 'SVC-KPI', align: 'center' },
        { label: "DIST. (NM)", align: "center" },
        { label: "TIME (HH:MM)", align: "center" },
        { label: "WAYPOINTS", align: "center" },
    ];

    return (
        <>
            <Table size="small" aria-label="fir breakdown">
                <TableHead>
                    <TableRow>
                        {headers.map((h) => (
                            <StyledMiniTableCell key={h.label} align={h.align}>
                                {h.label}
                            </StyledMiniTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {firRows.map((f) => (
                        <StyledInnerTableRow key={f.tag_order}>
                            <StyledMiniTableCell
                                align="center"
                                style={{
                                    fontSize: "14px",
                                    color: props.stroke,
                                }}
                            >
                                <IconButton
                                    className={classes.strokeCell}
                                    style={{ color: props.stroke }}
                                    variant="outlined"
                                    onClick={() =>
                                        props.zoomToRoute(f.geometry)
                                    }
                                >
                                    <ExploreIcon />
                                </IconButton>
                            </StyledMiniTableCell>
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {f.tag}
                            </StyledMiniTableCell>
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {f.long_name}
                            </StyledMiniTableCell>
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                $ {f.cost.toFixed(2)}
                            </StyledMiniTableCell>
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                $ {f.commonUsdCost.toFixed(2)}
                            </StyledMiniTableCell>
                            {/* <StyledMiniTableCell align="center">{'N/A'}</StyledMiniTableCell> */}
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {f.distance_nm.toFixed(2)}
                            </StyledMiniTableCell>
                            <StyledMiniTableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {f.elapsed_time}
                            </StyledMiniTableCell>
                            <Tooltip title={f.waypoints || "N/A"}>
                                <StyledMiniTableCell
                                    align="center"
                                    style={{
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        color: props.stroke,
                                    }}
                                >
                                    <HelpIcon />
                                </StyledMiniTableCell>
                            </Tooltip>
                        </StyledInnerTableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

const UpdatedGreatCircleTable = React.forwardRef((props, ref) => {
    console.log("rowrowrowrowrowrowrowrow Harsh", props);
    const flightData = props.flightData;
    const classes = useStyles();
    let usdTotalAmount = 0;
    const commonCurrency = "USD";
    const headers = props.headers;
    const tcHeaders = props.tcHeaders;

    const createHeaders = (headers) => {
        return headers.map((h, index) => {
            return (
                <TableCell key={index} {...h} style={{ fontFamily: "Mukta" }}>
                    {h.value}
                </TableCell>
            );
        });
    };

    const createTCHeaders = (tcHeaders) => {
        return tcHeaders.map((h, index) => {
            return (
                <TableCell key={index} {...h} style={{ fontFamily: "Mukta" }}>
                    {h.value}
                </TableCell>
            );
        });
    };

    const rows = props.features.map((row, index) => {
        const [open, setOpen] = useState(false);

        function zoomAndOpen() {
            if (!open) {
                props.zoomToRoute(row);
            }
            setOpen(!open);
        }

        const firData = props.firData.filter(
            (item) => item.country === row.country
        );
        usdTotalAmount += row.commonUsdCost;
        return (
            <>
                <StyledTableRow key={index} className={classes.row}>
                    <StyledTableCell
                        align="center"
                        onClick={() => zoomAndOpen()}
                        style={{ cursor: "pointer" }}
                    >
                        <IconButton
                            className={classes.strokeCell}
                            style={{ color: row.properties.stroke }}
                            variant="outlined"
                        >
                            <ExploreIcon />
                        </IconButton>
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ fontFamily: "Mukta" }}
                    >
                        {row.properties.country}
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ fontFamily: "Mukta" }}
                    >
                        <NumberFormat
                            value={row.properties.dist_km}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                        />
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ fontFamily: "Mukta" }}
                    >
                        {row.properties.currency}
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ fontFamily: "Mukta" }}
                    >
                        <NumberFormat
                            value={row.properties.cost || "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ fontFamily: "Mukta" }}
                    >
                        <NumberFormat
                            value={row.commonUsdCost}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={"$ "}
                        />
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow
                    className="custom-collapse-function"
                    key={index + "Details"}
                    style={{ fontFamily: "Mukta" }}
                >
                    <Collapse
                        disableStrictModeCompact={true}
                        component={TableCell}
                        colSpan={6}
                        in={open}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box margin={1}>
                            <DetailedFirs
                                classes={classes}
                                firs={firData}
                                zoomToRoute={props.zoomToRoute}
                                stroke={row.properties.stroke}
                            />
                        </Box>
                    </Collapse>
                </StyledTableRow>
            </>
        );
    });

    return (
        <>
            <TableContainer
                component={Box}
                elevation={1}
                className={classes.tableContainer}
                style={{
                    height: "auto",
                }}
            >
                <Table size="small" aria-label="a dense table" ref={ref}>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>{createTCHeaders(tcHeaders)}</TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.departureTerminalCharges}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.departureUSDTerminalCharges}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.arrivalTerminalCharges}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.arrivalUSDTerminalCharges}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.departureTerminalChargesCurrency}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                style={{ fontFamily: "Mukta" }}
                            >
                                {flightData.arrivalTerminalChargesCurrency}
                            </StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer
                component={Box}
                elevation={1}
                className={classes.tableContainer}
            >
                <Table size="small" aria-label="a dense table" ref={ref}>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>{createHeaders(headers)}</TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                        <TableRow>
                            <TableCell colSpan={headers.length - 2}></TableCell>
                            <TableCell
                                align="center"
                                className={classes.currencyCell}
                                style={{ fontFamily: "Mukta" }}
                            >
                                {commonCurrency}
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.amountCell}
                                style={{ fontFamily: "Mukta" }}
                            >
                                <NumberFormat
                                    value={usdTotalAmount}
                                    displayType={"text"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    prefix={"$ "}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
});

export default UpdatedGreatCircleTable;
