// ------------- REACT -------------
import * as React from 'react';

// ------------- REACT -------------
import RightPanel from '../RightPanel/RightPanel';

export default function LeftPanel(props) {
  return (
    <div >
      <div>
        <RightPanel index={0} />
      </div>
    </div>
  );
};