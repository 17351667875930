import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
      "& .MuiTableCell-head": {
          color: "black",
          backgroundColor: "white"
      },
    },
    errorTableCell: {
        color: "white",
        backgroundColor: "red"
    },
    successTableCell: {
        color: "white",
        backgroundColor: "green"
    }
});

const StyledTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#E7E7E7",
            
        },
    },
}))(TableRow);

function InvoiceTableRow (props) {
  const {invoiceData} = props;

  const classes = useStyles();
  return (
      <StyledTableRow className={classes.root}>
          <TableCell align="center">
              <span >{invoiceData.state}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.ansp}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.toc}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.period}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.currency}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.billed_amount}</span>
          </TableCell>
          <TableCell align="center">
              <span>${(invoiceData.billed_amount / invoiceData.exch_rate).toFixed(2)}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.exch_rate}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.billed_date}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.due_date}</span>
          </TableCell>
          <TableCell align="center">
              <span>%{invoiceData.interest}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.email}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.contact}</span>
          </TableCell>
          <TableCell align="center">
              <span>{invoiceData.phone_number}</span>
          </TableCell>
          <TableCell align="center" className={new Date() > new Date(invoiceData.due_date) ? classes.errorTableCell : classes.successTableCell}>
              <span>{new Date() > new Date(invoiceData.due_date) ? "Yes" : "No"}</span>
          </TableCell>
      </StyledTableRow>
        
  );
}

export default InvoiceTableRow;