import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ListboxComponent from '../../Calculations/VirtualizationAutocomplete/ListboxComponent/ListboxComponent';


const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => (option.flight_ref),
});

export default function VirtualizedFlightRefAutocomplete(props) {
  const classes = useStyles();
  return (
    <Autocomplete
      value={props.value}
      fullWidth
      disableClearable
      disableListWrap
      onChange={(event, newValue) => props.onChange(newValue)}
      classes={classes}
      ListboxComponent={ListboxComponent}
      options={props.options}
      getOptionLabel={(option) => option?.flight_ref ?? ""}
      filterOptions={filterOptions}
      renderInput={(params) => <TextField {...params} label={props.label} InputLabelProps={{ shrink: true }} />}
      renderOption={(option) => <Typography noWrap>{option.flight_ref}</Typography>}
    />
  );
}
