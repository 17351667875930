import React, {useState, createContext, useEffect} from 'react';

import axios from '../../axios-usercharges';
// Create a Context
export const biginAuthContext = createContext();


// Create a Component wrapper from Context.Provider
export default function BiginAuthProvider(props) {
  // Live
  const [redirectUri, setRedirectUri] = useState("https://ual.flitegains.com");
  const [clientId, setClientId] = useState("1000.U11L7PBA61SKJ2FIXDPZW85NRUFVAI");

  // Testing
  // const [redirectUri, setRedirectUri] = useState("http://localhost:3000");
  // const [clientId, setClientId] = useState("1000.TU80Y8ZDI4RP3YL27SUIQN337ABHGI");

  const [hasCodes, setHasCodes] = useState(false);

  useEffect(() => {
    if(!localStorage.getItem("biginToken")) {
      setHasCodes(false);
    } else {
      const tokenValues = JSON.parse(localStorage.getItem("biginToken"));
      const refreshToken = tokenValues.refresh_token;
      const elapsedTimetoken = tokenValues.expires_in;
      const dateIssued = new Date(tokenValues.date_issued);
      const currentDate = new Date();
      const currentElapsedTime = (currentDate.getTime() - dateIssued.getTime())  / 1000;
      if(currentElapsedTime >= elapsedTimetoken){
        axios.get(`/api/bigin/refresh/?refresh_token=${refreshToken}`).then((result) => {
          if(result.data.access_token) {
            const biginTokensWithDate = {...result.data, refresh_token: refreshToken, date_issued: new Date()}
            console.log("tokens with date", biginTokensWithDate);
            setToken(JSON.stringify(biginTokensWithDate)); 
            
          }
        }).catch((error) => {
          console.log(error);
        })
      }
      setHasCodes(true);
    }
  }, [])

  // Function used to set a token
  const setToken = (tokenData) => {
    localStorage.setItem("biginToken", tokenData);
  }

  const getAccessToken = () => {
    const tokenData = JSON.parse(localStorage.getItem("biginToken")).access_token;
    if(tokenData) {
      return tokenData;
    } else {
      return "";
    }
  }

  // Provider data
  const providerData = {
    redirectUri, 
    clientId,
    hasCodes,
    setToken,
    getAccessToken
  };

  // Wrapper
  return (
    <biginAuthContext.Provider value={providerData}>
      {props.children}
    </biginAuthContext.Provider>
  );
};