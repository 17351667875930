// ------------- REACT -------------
import React from 'react';

// ------------- COMPONENTS -------------
import GroupTableRow from './GroupTableRow';
import SortSelector from '../Icons/SortSelector';

function GroupTable(props) {
  const groupTableRows = props.contacts.map((contact, index) => {
    return <GroupTableRow key={index} contact={contact}/>
  })


  return(
    <>
      <h4 style={{backgroundColor: "lightgrey", color: "grey", margin: "0", padding: "10px 20px"}}>{props.group}</h4>
      <table style={{width: "100%", borderCollapse: "collapse", marginBottom: "20px"}}>
        <thead>
          <tr style={{border: "1px solid lightgrey", color: "grey"}}>
            <th style={{padding: "10px", width: "30%"}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <SortSelector/>
                <span>NAME</span>
              </div>
            </th>
            <th style={{padding: "10px", width: "30%"}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <SortSelector/>
                <span>EMAIL</span>
              </div>  
            </th>
            <th style={{padding: "10px", width: "20%"}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <SortSelector/>
                <span>BILLABLE RATE</span>
              </div>  
            </th>
            <th style={{padding: "10px", width: "15%"}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <SortSelector/>
                <span>ROLE</span>
              </div>  
            </th>
            <th style={{padding: "10px", width: "5%"}}>
            </th>
          </tr>
        </thead>
        <tbody>
          {groupTableRows}
        </tbody>
      </table>
    </>
  )
}

export default GroupTable;