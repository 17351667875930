// ------------- REACT -------------
import React, { useEffect, useState, createRef, useRef } from "react";

// ------------- MUI -------------
import { makeStyles, Button, Box, CircularProgress } from "@material-ui/core";

// ------------- AXIOS -------------
import axios from "../../axios-usercharges";

// ------------- GENERATORS -------------
import { exportCSVFile } from "../../shared/csv";
import { generatePDF } from "../../shared/pdf";

// ------------- REACT TO PRINT -------------
import { useReactToPrint } from "react-to-print";

// ------------- MISC -------------
import { getHoursMinutesSecondsString } from "../../shared/util";
import { getFormattedDateFromMonthYear } from "../../shared/util";

// ------------- MOCKS -------------
import ATCCharges from "../../mocks/ATCCharges";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import CountryDateSelection from "./CountryDateSelection";
import UpdatedPsuedoInvoice from "./UpdatedPsuedoInvoice";
import BankingDetails from "./BankingDetails";
import Personaldetails from "./PersonalDetails";
import BetaInvoicePrint from "./BetaInvoicePrint";
import PlaceHolder from "../Utility/PlaceHolders/PlaceHolder";
import ATCChargesModal from "../Utility/ATCChargesModal";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px 0px",
        flexGrow: 1,
    },
    gridContainer: {
        minHeight: "100vh",
        width: "100%",
    },
}));

export default function BetaInvoice() {
    //------------- MUI STYLES -------------
    const classes = useStyles();

    //------------- REFS -------------
    const printRef = useRef();
    const psuedoInvoiceTableRef = createRef();

    // ------------- STATES -------------
    const [data, setData] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [country, setCountry] = useState(null);
    const [loading, setLoading] = useState(false);
    const [countryData, setCountryData] = useState(null);
    const [totalFlights, setTotalFlights] = useState(0);

    // --- Table Pagination ---
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    // --- Modal States ---
    const [ATCModalOpen, setATCModalOpen] = useState(false);
    const [PDModalOpen, setPDModalOpen] = useState(false);
    const [BDModalOpen, setBDModalOpen] = useState(false);

    // ------------- FUNCTIONS -------------
    // Function to handle printing of doucment
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const formatDateFromISO = (date) => {
        const stringDateSplit = date.split("T")[0].split("-");
        return `${stringDateSplit[2]}/${stringDateSplit[1]}/${stringDateSplit[0]}`;
    };

    // Function to handle exporting to pdf
    const onExportPDFClick = () => {
        const pseudoInvoiceHeadersSeq = [
            "flightNumber",
            "departure",
            "arrival",
            "aircraftModel",
            "etd",
            "mtow",
            "totalFirCalculatedInfo.distance_nm",
            "totalFirCalculatedInfo.cost",
        ];
        // (node, title, data, keys, currency, amount, filename, afterTableCreatedCallback, formatters)
        const title = `MONTHLY INVOICE ${country}`;
        const flights = selectedData.data;
        const currency = flights[0].totalFirCalculatedInfo.currency;
        const charge = flights.reduce((acc, currFlight) => {
            return acc + currFlight.totalFirCalculatedInfo.cost;
        }, 0);

        generatePDF(
            psuedoInvoiceTableRef.current,
            title,
            flights,
            pseudoInvoiceHeadersSeq,
            currency,
            charge.toFixed(2),
            // TODO: this box in the pdf is limited in size, need to fix for longer title
            `${country}.pdf`,
            null,
            {
                // TODO: better formatting for numbers
                mtow: (value) => value.MAX_TOW_KG.toLocaleString("en"),
                "totalFirCalculatedInfo.distance_nm": (value) =>
                    value.toFixed(2),
                "totalFirCalculatedInfo.cost": (value) => value.toFixed(2),
                etd: (value) => formatDateFromISO(value),
            }
        );
    };

    // Function to handle exporting to excel
    const onExportExcelClick = () => {
        const { country, date, data } = selectedData;
        const pseudoInvoiceHeaders = {
            flightNumber: "FLIGHT",
            departure: "ORIGIN",
            arrival: "DESTINATION",
            aircraftModel: "AIRCRAFT",
            etd: "DATE",
            mtow: "WEIGHT (kg)",
            "totalFirCalculatedInfo.distance_nm": "DISTANCE (nm)",
            // 'totalFirCalculatedInfo.elapsed_time': 'SEG Time',
            "totalFirCalculatedInfo.cost": "CHARGE",
        };

        const formattedData = data.map((flight) => {
            const totalFirCalculatedInfo = {
                ...flight.totalFirCalculatedInfo,
                cost: flight.totalFirCalculatedInfo.cost.toFixed(2),
                distance_nm:
                    flight.totalFirCalculatedInfo.distance_nm.toFixed(2),
                elapsed_time: getHoursMinutesSecondsString(
                    flight.totalFirCalculatedInfo.elapsed_time
                ),
            };

            return {
                ...flight,
                totalFirCalculatedInfo,
                etd: formatDateFromISO(flight.etd),
                mtow: flight.mtow.MAX_TOW_KG,
            };
        });

        const currency = formattedData[0].totalFirCalculatedInfo.currency;
        const headers = pseudoInvoiceHeaders;

        let preHeader = null; //[country, '', date, `Flights ${data.length}`, `Currency ${currency}`, `Charge ${charge}`, `Total Distance`, `${totalDistance} NM`, "Total Time", `${Math.round(totalTime / 60) % 60} MINS`]
        const fileTitle = `Beta Invoice - ${country} - ${date}`;
        exportCSVFile(headers, formattedData, fileTitle, preHeader);
    };

    // Get country data in order to pull the proper flag for the country from api (https://restcountries.com/)
    const getCountryData = async (selectedCountry) => {
        let newCountryData = null;

        // Load country data from rest countries api based on country name
        await fetch(
            `https://restcountries.com/v3.1/name/${selectedCountry.toLowerCase()}`
        )
            .then((res) => res.json())
            .then((data) => (newCountryData = data[0] || null))
            .catch((err) => console.log(err));

        // Load country data from rest countries api based on capital name if no data found
        if (!newCountryData) {
            await fetch(
                `https://restcountries.com/v3.1/capital/${selectedCountry.toLowerCase()}`
            )
                .then((res) => res.json())
                .then((data) => (newCountryData = data[0] || null))
                .catch((err) => console.log(err));
        }

        setCountryData(newCountryData);
    };

    const flightData2 = (selectedCountry, selectedYearMonth, signal) => {
        setLoading(true);
        setCountry(selectedCountry);
        getCountryData(selectedCountry);

        const [year, month] = selectedYearMonth.split(" ");
        // Get the data for the invoice from the backend
        axios
            .get(`/api/UAL/betaInvoice2/${selectedCountry}/${month}/${year}`, {
                signal,
            })
            .then((res) => {
                res.data.sort(function (a, b) {
                    return a - b;
                });
                const newData = res.data?.map((flight) => {
                    const totalFirCalculatedInfo = flight.firCalculatedInfo
                        .filter((fInfo) => fInfo.country === selectedCountry)
                        .reduce((acc, currFirInfo) => {
                            // let calcElapsedTime = acc.elapsed_time + currFirInfo.elapsed_time;
                            return {
                                ...acc,
                                cost: acc.cost + currFirInfo.cost,
                                elapsed_time:
                                    acc.elapsed_time + currFirInfo.elapsed_time,
                                distance_nm:
                                    acc.distance_nm + currFirInfo.distance_nm,
                            };
                        });
                    return { ...flight, totalFirCalculatedInfo };
                });

                // Get the total for the beta invoice
                const total = newData.reduce((accum, flight) => {
                    return accum + flight.totalFirCalculatedInfo.cost;
                }, 0);

                const selectedData = {
                    data: newData,
                    country: selectedCountry,
                    date: getFormattedDateFromMonthYear(selectedYearMonth),
                    total: total,
                };
                setTotalFlights(newData.length);

                setSelectedData(selectedData);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // ------------- HOOKS -------------
    useEffect(() => {
        if (localStorage.getItem("BetaInvoice")) {
            const betaInvoiceData = JSON.parse(
                localStorage.getItem("BetaInvoice")
            );
            const countriesYearMonth = betaInvoiceData.reduce((acc, curr) => {
                const year = `${curr.etd_year}`;
                const month = curr.etd_month;
                const country = curr.country;
                if (!acc[country]) {
                    acc[country] = {};
                }
                if (!acc[country][year]) {
                    acc[country][year] = [];
                }

                acc[country][year].push(month);
                return acc;
            }, {});

            setData(countriesYearMonth);
        } else {
            axios
                .get(`/api/UAL/betaInvoice`)
                .then((res) => {
                    const countriesYearMonth =
                        res.data.countriesByYearMonth.reduce((acc, curr) => {
                            const year = `${curr.etd_year}`;
                            const month = curr.etd_month;
                            const country = curr.country;
                            if (!acc[country]) {
                                acc[country] = {};
                            }
                            // check if the country entry exists in the current entry
                            if (!acc[country][year]) {
                                acc[country][year] = [];
                            }
                            acc[country][year].push(month);
                            return acc;
                        }, {});

                    setData(countriesYearMonth);
                    localStorage.setItem(
                        "BetaInvoice",
                        JSON.stringify(res.data.countriesByYearMonth)
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [country]);

    return (
        <div style={{ marginBottom: "35px" }}>
            <Navbar index={4} />
            <Box
                className={classes.gridContainer}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="stretch"
            >
                {!data ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress
                            size={70}
                            left={-20}
                            top={10}
                            style={{ marginTop: "40px" }}
                        />
                        <span style={{ fontSize: "2rem", marginTop: "20px" }}>
                            Loading
                        </span>
                    </div>
                ) : (
                    <div>
                        <CountryDateSelection
                            countriesByYearMonth={data}
                            setSelectedData={setSelectedData}
                            getFlightData={flightData2}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                        {/* Instructions when page is empty */}
                        {!selectedData && !loading && (
                            <PlaceHolder
                                title="Beta Invoice"
                                description="Please select a country and billing cycle to view an invoice"
                            />
                        )}
                    </div>
                )}
                {/* Loading State*/}
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress
                            size={70}
                            left={-20}
                            top={10}
                            style={{ marginTop: "40px" }}
                        />
                        <span style={{ fontSize: "2rem", marginTop: "20px" }}>
                            Loading
                        </span>
                    </div>
                ) : null}
                {/* Display buttons for when the page has loaded*/}
                {selectedData && (
                    <Box
                        className={classes.actionsContainer}
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                        style={{ width: "95%", margin: "0px auto" }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: "130px" }}
                                    onClick={handlePrint}
                                >
                                    PRINT
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: "130px" }}
                                    onClick={onExportPDFClick}
                                >
                                    EXPORT PDF
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: "130px" }}
                                    onClick={onExportExcelClick}
                                >
                                    EXPORT EXCEL
                                </Button>
                            </div>
                            <div>
                                {(country === "Jamaica" ||
                                    country === "Dominican Republic") && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ width: "130px" }}
                                            onClick={() => setBDModalOpen(true)}
                                        >
                                            BANKING INFO
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ width: "130px" }}
                                            onClick={() => setPDModalOpen(true)}
                                        >
                                            PERSONAL INFO
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: "130px" }}
                                    onClick={() => setATCModalOpen(true)}
                                >
                                    FORMULA
                                </Button>
                            </div>
                        </div>
                    </Box>
                )}

                {selectedData && (
                    <Box
                        className={classes.actionsContainer}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    ></Box>
                )}
                {selectedData && (
                    <div style={{ width: "95%", margin: "auto" }}>
                        <UpdatedPsuedoInvoice
                            ref={psuedoInvoiceTableRef}
                            country={country}
                            selectedData={selectedData}
                            countryData={countryData}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            totalFlights={totalFlights}
                        ></UpdatedPsuedoInvoice>
                    </div>
                )}
                {/* Component used to print beta invoice details */}
                <div style={{ display: "none" }}>
                    <div ref={printRef}>
                        <BetaInvoicePrint
                            country={country}
                            selectedData={selectedData}
                        />
                    </div>
                </div>

                <ATCChargesModal
                    object={ATCCharges.mexico}
                    modalOpen={ATCModalOpen}
                    setModalOpen={setATCModalOpen}
                />
                <BankingDetails
                    data={country}
                    modalOpen={BDModalOpen}
                    setModalOpen={setBDModalOpen}
                />
                <Personaldetails
                    data={country}
                    modalOpen={PDModalOpen}
                    setModalOpen={setPDModalOpen}
                />
            </Box>
        </div>
    );
}
