import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";

const RetrieveFloridaDataFromDatabase = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };

  const tableData = data
  ? data.filter((item) => {
      return ["Charge Type", "No Charges", "Base Rate", "Total"].includes(
        item.field_name
      );
    })
  : [];

  const groupedDataa = tableData.reduce((acc, entry) => {
    if (!acc[entry.field_name]) {
      acc[entry.field_name] = [];
    }
    acc[entry.field_name].push(entry.field_value);
    return acc;
  }, {});


  const groupedData = {};
  tableData.forEach((item) => {
    if (!groupedData[item.field_name]) {
      groupedData[item.field_name] = [];
    }
    groupedData[item.field_name].push(item.field_value);
  });

  const filteredResponse = data
  ? data
      .map((item) => {
        return item;
      })
      .filter((item) =>
        [
          "Supplier Name",
          "Invoice Number",
          "Invoice Date",
          "Address",
          "Country",
          "Total Due",
        ].includes(item.field_name)
      )
  : [];


  const duplicateData = data
  ? data.filter((item) =>
      [
        "Date",
        "Flight ID",
        "Org",
        "Dest",
        "Type",
        "Tail Number",
        "Flight Type",
        "Flight Total Sum",
      ].includes(item.field_name)
    )
  : [];


  const rows = [];
  for (let i = 0; i < duplicateData.length; i += 8) {
    const rowData = duplicateData.slice(i, i + 8);
    const cells = rowData.map((item) => {
      if (item.field_name === "Date") {
        // Format the "Date" fields
        const formattedDate = formatDate(item.field_value);
        return <td key={item.field_id}>{formattedDate}</td>;
      } else {
        // Display "-" for fields with no value
        return (
          <td key={item.field_id}>
            {item.field_value ? item.field_value : "-"}
          </td>
        );
      }
    });
    rows.push(<tr key={`row-${i}`}>{cells}</tr>);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <>
      <div>
      </div>
      <div className="retrieve-data-container" style={{width: "95%", margin: "0 auto"}}>
        <h2 className="invoice-detail-heading">Invoice Details</h2>
        <div className="table-container customTable">
          <table>
            <thead>
              <tr>
                {filteredResponse.map((item) => (
                  <th key={item.field_id}>{item.field_name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {filteredResponse.map((item) => (
                  <td key={item.field_id}>
                    {item.field_name === "Invoice Date"
                      ? formatDate(item.field_value)
                      : item.field_value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-container customTable">
      <table>
        <thead>
          <tr>
            {Object.keys(groupedDataa).map((fieldName) => (
              <th key={fieldName}>{fieldName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(Object.values(groupedDataa)[0]) &&
            Object.values(groupedDataa)[0].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(groupedDataa).map((fieldValues, index) => (
                  <td key={index}>{fieldValues[rowIndex]}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>

        <div className="table-container customTable" style={{ flexDirection: "column" }}>
          <table>
            <thead>
              <tr>
                {[
                  "Date",
                  "Flight ID",
                  "Org",
                  "Dest",
                  "Type",
                  "Flight Total Sum",
                  "Tail Number",
                  "Flight Type",
                ].map((fieldName) => (
                  // <th key={fieldName}>{fieldName}</th>
                  <th key={fieldName}>{fieldName === "Flight Total Sum" ? "Charge Rate" : fieldName}</th>
                ))}
              </tr>
            </thead>
            <tbody>{rows.slice(startIndex, endIndex)}</tbody>
          </table>
          <TablePagination
            component="div"
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default RetrieveFloridaDataFromDatabase;
