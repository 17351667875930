// ------------- REACT -------------
import React, { useState } from 'react';

// ------------- MUI -------------
import TableCell from '@material-ui/core/TableCell';
import { makeStyles, Tooltip } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// ------------- REACT TO CLIPBOARD -------------
import { CopyToClipboard } from "react-copy-to-clipboard";

// TOOD: remove paper theme from showing up when used in FlightInfoBanner
const tooltipTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1rem",
                backgroundColor: "#00205B",
                fontFamily: 'Mukta'
            }
        },
        MuiPaper: {
            root: {
                border: 'none',
                backgroundColor: 'red',
                margin: '0 100px',
                fontFamily: 'Mukta'
            },
        }
    }
});

// ------------- MUI STYLES -------------
const useStyles = makeStyles({
    root: {
        maxWidth: "20rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontFamily: 'Mukta'
    },
})

export default function FlightRouteTooltipCell({ route, className, rowSpan }) {
    // ------------- MUI STYLES -------------
    const classes = useStyles();

    // ------------- STATES -------------
    const [isCopied, setIsCopied] = useState(false);

    // ------------- FUNCTIONS -------------
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }

    return (
        <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip interactive={true} leaveDelay={250} title={<React.Fragment>{route}
                <CopyToClipboard text={route} onCopy={onCopyText}>
                    <button style={{
                        marginLeft: '90%', backgroundColor: 'Transparent',
                        backgroundRepeat: 'no-repeat',
                        border: 'none',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        outline: 'none'
                    }}><FileCopyIcon style={{ fontSize: '1rem', color: 'white' }} /></button>
                </CopyToClipboard>
                {isCopied && <span style={{ color: 'red' }}>Copied!</span>}
            </React.Fragment>} placement="top">
                <TableCell align="left" rowSpan={rowSpan ? rowSpan : 1} className={className ? className : classes.root}>
                    <CopyToClipboard text={route} onCopy={onCopyText}>
                        <button style={{
                            marginLeft: '0%', backgroundColor: 'Transparent',
                            backgroundRepeat: 'no-repeat',
                            border: 'none',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            outline: 'none'
                        }}><FileCopyIcon style={{ fontSize: '1rem', color: 'black' }} /></button>
                    </CopyToClipboard> {route}
                </TableCell>
            </Tooltip>
        </MuiThemeProvider>
    );
};

