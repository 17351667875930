import React, {useState, useEffect} from "react";

import { Modal,
      Box,
      Typography,
      TableContainer,
      Table,
      TableRow,
      TableCell,
      TableHead,
      TableBody,
      Button,
      TablePagination,
      Snackbar,
      Alert
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Icon} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import PrintIcon from '@mui/icons-material/Print';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// ------------- AXIOS -------------
import axios from "../../../../axios-usercharges";

// ------------- PDF  -------------
import jsPDF from 'jspdf';

//  ------------- MOCKS  -------------
import ectlReasonCodes from "../../../../mocks/ectlReasonCodes";
import { ectlGlossaryA, ectlGlossaryB, ectlGlossaryC } from "../../../../mocks/ectlGlossary";

// ------------- LOGOS -------------
import ECTLLogo from '../../../../../src/assets/logo/EuroControl.png';
import IATALogo from '../../../../../src/assets/logo/new_flitex_logo.jpg';

// ------------- MUI STYLES -------------
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-head": {
            backgroundColor: "#B8CCE4",
            border: "1px solid black",
            textAlign: "center"
        },
        "& .MuiTableCell-body": {
          border: "1px solid black",
          textAlign: "center"
      },
    },
    tableHead: {
      border: "2px solid black"
    },
    headerTopCell: {
      border: "1px solid black"
    },
    headerBottomCell: {
      border: "1px solid black",
      fontStyle: "italic"
    }
});

const useModalStyles = makeStyles({
  modalContent: {
    overflow: "scroll",
    maxHeight: 500
  }
})

function DisputeDataModal(props) {
  // ------------- DECONSTRUCTING PROPS -------------
  const {disputeData, selectedCountry, handleDeleteDispute, open, setOpen, emailId, setEmail} = props;
  
  // ------------- MUI STYLES -------------
  const ectlTableClasses = useStyles();
  const modalStyles = useModalStyles();
  
  // ------------- STATES -------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbar, setSnackbar] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [message, setMessage] = useState("")
  const [isEuroControl, setIsEuroControl] = useState(false);

  // ------------- Modal Styling -------------
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  const ectlModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    maxHeight: 800,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  const tableStyle = {
    minWidth: 500,
    marginBottom: "20px",
  };

  // ------------- FUNCTIONS/HOOKS -------------
  // --- Table Pagination ---
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useEffect(() => {
    if (!open) {
      setPage(0);
    }
  }, [open]);

  // --- Modal States ---
  const handleClose = () => {
    setOpen(false);
    setIsValidEmail(true);
    setIsEuroControl(false);
  };

  // --- Date Formatting ---
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const formatDate1 = (dateString) => {
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // --- Email validation and sending / printing ---
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
 
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
 
    setIsValidEmail(validateEmail(newEmail));
  };


  const handleEmailAndPrint = async (action, email) => {
    try {
      if (disputeData.length === 0) {
        setSnackbar({
          open: true,
          message: "No data available for action.",
          severity: "info",
        });
        return;
      }
 
      const doc = new jsPDF();

      const imgWidth = 40;
      const imgHeight = 40;
      const imgX = doc.internal.pageSize.getWidth() - imgWidth - 10;
      const imgY = 10;
      doc.addImage(IATALogo, "JPEG", imgX, imgY, imgWidth, imgHeight);
 
      doc.setProperties({
        title: "Monthly Dispute Data",
        subject: `Dispute Summary for Month/Year of ${formatDate1(
          disputeData[0].month_year
        )}`,
        author: "FliteX",
      });
 
      const headers = ["Date", "Flight Number", "Tail Number", "Disputed Amount", "Reason"];
 
      const data = disputeData.map((dispute) => [
        formatDate(dispute.date),
        dispute.flight_number,
        dispute.tail_number,
        `$${dispute.disputed_amount}`,
        dispute.dispute_reason,
      ]);
 
      const addHeaderFooter = async (data) => {
        // Header
        const headerText = "Dispute Summary";
        doc.setFontSize(16);
        const textWidth =
          (doc.getStringUnitWidth(headerText) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
        doc.text(headerText, textX, 15);
 
        // Footer
        const generatedByText = "Copyright © fliteX 2023";
        // const generatedByText = 'Generated By: fliteX';
        doc.setFontSize(10);
        doc.text(generatedByText, 10, doc.internal.pageSize.getHeight() - 10);
        doc.text(
          "Page " + data.pageNumber,
          doc.internal.pageSize.getWidth() - 20,
          doc.internal.pageSize.getHeight() - 10,
          { align: "right" }
        );
      };
 
      // Time Period
      const disputedDataMonth = formatDate1(disputeData[0].month_year);
      const timePeriodText = `Dispute Month: ${disputedDataMonth}`;
      doc.setFontSize(12);
      doc.text(timePeriodText, doc.internal.pageSize.getWidth() - 192, 30, {
        maxWidth: 150,
        align: "left",
      });
 
      // Country
      const disputedCountry = selectedCountry;
      const counrtyText = `Country: ${disputedCountry}`;
      doc.setFontSize(12);
      doc.text(counrtyText, doc.internal.pageSize.getWidth() - 192, 35, {
        maxWidth: 150,
        align: "left",
      });
 
      //Airline
      const airline = "Airline: UAL";
      doc.setFontSize(12);
      doc.text(airline, doc.internal.pageSize.getWidth() - 192, 40, {
        maxWidth: 150,
        align: "left",
      });
 
      if (disputeData.length > 0) {
        // Total Disputed Amount
        const totalDisputedAmount = disputeData
          .filter((dispute) => dispute.month_year === disputeData[0].month_year)
          .reduce((accumulator, dispute) => {
            const disputedAmountAsNumber = parseFloat(dispute.disputed_amount);
            return (
              accumulator +
              (isNaN(disputedAmountAsNumber) ? 0 : disputedAmountAsNumber)
            );
          }, 0);
        const totalDisputedAmountText = `Total Disputed Amount: $${totalDisputedAmount}`;
        doc.setFontSize(12);
        doc.text(
          totalDisputedAmountText,
          doc.internal.pageSize.getWidth() - 192,
          45,
          { maxWidth: 150, align: "left" }
        );
      } else {
        console.log("No data available for the specified month.");
      }
 
      doc.autoTable({
        head: [headers],
        body: data,
        startY: 50,
        styles: { fontSize: 10, cellPadding: 2, overflow: "linebreak" },
        columnStyles: { 3: { cellWidth: "wrap" } },
        margin: { top: 60 },
        didDrawPage: addHeaderFooter,
      });
 
      if (action === "print") {
        const filename = `${formatDate1(
          disputeData[0].month_year
        )}_${selectedCountry.replace(/ /g, "_")}_dispute_data.pdf`;
        doc.save(filename);
        setSnackbar({
          open: true,
          message: "PDF saved successfully.",
          severity: "success",
        });
      } else if (action === "email") {
        const pdfBlob = doc.output("blob");
 
        const formData = new FormData();
        formData.append("email", email);
        formData.append(
          "subject",
          `Dispute Summary of ${formatDate1(disputeData[0].month_year)}`
        );
        formData.append(
          "text",
          `Hello,\nPlease find attached Dispute Summary of ${formatDate1(
            disputeData[0].month_year
          )} for United Airline.`
        );
        formData.append(
          "filename",
          `${formatDate1(disputeData[0].month_year)}_${selectedCountry.replace(
            / /g,
            "_"
          )}_dispute_data.pdf`
        );
 
        formData.append("attachment", pdfBlob);
 
        const response = await axios.post("/api/email", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
 
        console.log("Email sent successfully", response.data);
 
        setSnackbar({
          open: true,
          message: "Email with PDF sent successfully.",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
      setSnackbar({
        open: true,
        message: "Error performing action.",
        severity: "error",
      });
    }
  };

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isEuroControl ? ectlModalStyle : modalStyle}>
          <CloseIcon
            onClick={() => handleClose()}
            style={{ marginLeft: "95%", cursor: "pointer" }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Dispute Data
          </Typography>
          <div style={{display: "flex", gap: "10px"}}>
            <Button variant="contained" onClick={() => setIsEuroControl(true)}>
              ECTL
            </Button>
            <Button variant="contained" onClick={() => setIsEuroControl(false)}>
              OTHER
            </Button>
          </div>
          {!isEuroControl ? <>
            {/* Other Claims Form */}
            <TableContainer>
              <Table sx={tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Flight Number</b>
                    </TableCell>
                    <TableCell>
                      <b>Tail Number</b>
                    </TableCell>
                    <TableCell>
                      <b>Disputed Amount</b>
                    </TableCell>
                    <TableCell>
                      <b>Reason</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disputeData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((dispute) => (
                      <TableRow key={dispute.id}>
                        <TableCell>{formatDate(dispute.date)}</TableCell>
                        <TableCell>{dispute.flight_number}</TableCell>
                        <TableCell>{dispute.tail_number}</TableCell>
                        <TableCell>${dispute.disputed_amount}</TableCell>
                        <TableCell>{dispute.dispute_reason}</TableCell>
                        <TableCell>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteDispute(dispute.id)}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={disputeData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </TableContainer>
          </>
          : 
          <>
          {/* Eurocontrol Claims Form*/}
          <div className={modalStyles.modalContent} style={{paddingTop: "20px", paddingBottom: "20px"}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "end", gap:"100px", marginBottom: "20px"}}>
                <Typography variant="h4" component="h5" style={{fontWeight: "bold"}}>Claim Against Eurocontrol Charges</Typography>
                <Icon style={{height:"125px", width:"125px", boxShadow: "0 4px 8px 0 grey, 0 6px 20px 0 grey"}}>
                  <img src={ECTLLogo} height="100%" width="100%"></img>
                </Icon> 
              </div>
              <div style={{border: "2px solid #4F81BD", borderRadius: "10px", padding: "10px"}}>
                <Typography style={{fontWeight: "bold", paddingLeft: "10px"}}>To:</Typography>
                <Typography style={{paddingLeft: "80px"}}>EUROCONTROL, Central Route Charges Office</Typography>
                <div style={{display: "flex"}}>
                  <Typography style={{paddingLeft: "80px"}}>Customer Relations</Typography>
                  <Typography style={{paddingLeft: "200px"}}>Fax : +32 2 729 90 93</Typography>
                </div>
                <Typography style={{paddingLeft: "80px"}}>Rue de la Fusée 96, B-1130 Brussels (Belgium) E-mail : r3.crco@eurocontrol.int</Typography>
              </div>
              <div style={{border: "2px solid #4F81BD", borderRadius: "10px", margin: "20px 0", padding: "10px"}}>
                <Typography style={{fontWeight: "bold", paddingLeft: "10px"}}>From:</Typography>
                <div style={{display: "flex"}}>
                  <div style={{paddingLeft: "80px"}}>
                    <Typography style={{textDecoration: "underline"}}>Airspace user name:</Typography>
                    <Typography style={{textDecoration: "underline"}}>Airspace user number:</Typography>
                    <Typography style={{textDecoration: "underline"}}>Date:</Typography>
                  </div>
                  <div style={{paddingLeft: "300px"}}>
                    <Typography style={{ textDecoration: "underline"}}>Person of contact:</Typography>
                    <Typography style={{ textDecoration: "underline"}}>Tel:</Typography>
                    <Typography style={{ textDecoration: "underline"}}>E-mail:</Typography>
                  </div>
                </div>
              </div>
              {/* Claims Table */}
              <TableContainer style={{marginBottom: "20px"}}>
                <Table>
                  <TableHead className={ectlTableClasses.tableHead}>
                    <TableRow className={ectlTableClasses.root}>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "20%"}} rowSpan={2}>
                          <b>Bill Reference</b>
                          <br/>
                          <b>(../...../../..../..)</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "12.5%"}}>
                        <b>Day*</b>
                      </TableCell >
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "12.5%"}}>
                        <b>Line N°*</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "12.5%"}}>
                        <b>Flight ID*</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "12.5%"}}>
                        <b>Reason Code</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "30%"}}>
                        <b>Correct Data / Evidence</b>
                      </TableCell>
                    </TableRow>
                    <TableRow className={ectlTableClasses.root}>
                      <TableCell className={ectlTableClasses.headerBottomCell}>
                        Col. 1 of Pro Forma Statement*
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerBottomCell}>
                        Col. 2 of Pro Forma Statement*
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerBottomCell}>
                        Col. 3 of Pro Forma Statement*
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerBottomCell}>
                        See Table
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerBottomCell}>
                        See Table
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {disputeData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((dispute) => (
                      <TableRow key={dispute.id} className={ectlTableClasses.root}>
                        <TableCell>${dispute.disputed_amount}</TableCell>
                        <TableCell>{formatDate(dispute.date)}</TableCell>
                        <TableCell>{dispute.flight_number}</TableCell>
                        <TableCell>{dispute.tail_number}</TableCell>
                        <TableCell>10</TableCell>
                        <TableCell>
                          {dispute.dispute_reason}
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteDispute(dispute.id)}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                </Table>
              </TableContainer>
              {/* Reason Code Table */}
              <TableContainer style={{marginBottom: "20px"}}>
                <Table>
                  <TableHead className={ectlTableClasses.tableHead}>
                    <TableRow className={ectlTableClasses.root}>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "10%"}} rowSpan={2}>
                        <b>Reason Code</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "25%"}} rowSpan={2}>
                        <b>Reason</b>
                      </TableCell >
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "65%"}} colSpan={2}>
                        <b>Correct Data / Evidence</b>
                      </TableCell>
                    </TableRow>
                    <TableRow className={ectlTableClasses.root}>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "40%"}}>
                        <b>Mandatory</b>
                      </TableCell>
                      <TableCell className={ectlTableClasses.headerTopCell} style={{width: "25%"}}>
                        <b>Optional</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ectlReasonCodes
                      .map((reasonCode) => (
                        <TableRow key={reasonCode.codeNumber} className={ectlTableClasses.root}>
                          <TableCell><b>{reasonCode.codeNumber}</b></TableCell>
                          <TableCell><b>{reasonCode.reason}</b></TableCell>
                          <TableCell ><span dangerouslySetInnerHTML={{__html: reasonCode.mandatory}}/></TableCell>
                          <TableCell>{reasonCode.optional}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow className={ectlTableClasses.root}>
                          <TableCell><b>99</b></TableCell>
                          <TableCell><b>Other/Multiple reasons</b></TableCell>
                          <TableCell colSpan={2}>Relevant supporting evidence mentioned above.</TableCell>
                        </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Glossary */}
              <div style={{border: "2px dotted #4F81BD", borderRadius: "10px", padding: "10px"}}>
                <Typography style={{fontWeight: "bold"}}>Glossary:</Typography>
                <table>
                  <tbody>
                    {ectlGlossaryA.map((glossaryValue, index) => (
                      <tr key={index}>
                        <td  style={{color: "#9C4543", width: "25%"}}>
                          <Typography><b>* {glossaryValue.glossaryName}:</b></Typography>
                        </td>
                        <td  style={{width: "75%"}}>
                          <Typography>{glossaryValue.glossaryDescription}</Typography>
                        </td>
                      </tr>
                    ))}
                    
                    </tbody>
                  </table>
                  <div style={{display: "flex", justifyContent: "space-evenly"}}>
                    <div style={{padding: "0 20px"}}>
                      {ectlGlossaryB.map((glossaryValue, index) => (
                        <Typography key={index}><b style={{color: "#9C4543"}}>{glossaryValue.glossaryName}</b> = {glossaryValue.glossaryDescription}</Typography>
                      ))}
                    </div>
                    <div style={{padding: "0 20px", borderLeft: "1px solid black"}}>
                      {ectlGlossaryC.map((glossaryValue, index) => (
                        <Typography key={index}><b style={{color: "#9C4543"}}>{glossaryValue.glossaryName}</b> = {glossaryValue.glossaryDescription}</Typography>
                      ))}
                    </div>
                  </div>
              </div>
          </div>
         
          </>}
            <div style={{ display: "flex" }}>
              <div>
                <TextField
                  size="small"
                  label="Enter Email"
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  placeholder="abc@gmail.com"
                  value={emailId}
                  onChange={handleEmailChange}
                  error={!isValidEmail}
                  helperText={
                    !isValidEmail ? "Enter a valid email address" : ""
                  }
                />
              </div>
              <div style={{ marginLeft: " 155px" }}>
                <Button
                  variant="contained"
                  endIcon={<MailIcon />}
                  onClick={() => handleEmailAndPrint("email", emailId)}
                >
                  Email
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="contained"
                  endIcon={<PrintIcon />}
                  onClick={() => handleEmailAndPrint("print")}
                >
                  Print
                </Button>
              </div>
              <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert
                  onClose={() => setSnackbar({ ...snackbar, open: false })}
                  severity={snackbar.severity}
                >
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </div>
            <p
              style={{ color: message.startsWith("Success") ? "green" : "red" }}
            >
              {message}
            </p>
          
        </Box>
      </Modal>
  )
}

export default DisputeDataModal;