// ------------- REACT -------------
import React, { useEffect, useState } from 'react';

// ------------- REACT APEX CHARTS -------------
import ReactApexChart from 'react-apexcharts';

// ------------- AXIOS -------------
import axios from '../../../axios-usercharges';

// ------------- MUI -------------
import { CircularProgress } from '@material-ui/core';
 
const Chart11 = () => {
    // ------------- STATES -------------
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'donut',
            },
            labels: [],
        },
        series: [],
    });
    const [isLoading, setIsLoading] = useState(true);
 
    useEffect(() => {
        // Set up data for donut chart
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/chart11');
                const series = response.data.map((item) => item.value);
                const labels = response.data.map((item) => `${item.fir} ${item.name}`);
 
                setChartData({
                    options: {
                        chart: {
                            type: 'donut',
                        },
                        labels: labels,
                    },
                    series: series,
                });
 
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
 
    return (
        <div className="antialiased">
            <div className="wrapper">
                <div style={{ width: "100%", height: "100%"}}>
                    <div style={{ display: "flex", justifyContent: "center", height: "400px"}}>
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "400px"}}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <ReactApexChart
                                style={{width: "90%"}}
                                options={chartData.options}
                                series={chartData.series}
                                type="donut"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Chart11;