import React, { useState, useEffect } from "react";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import GreatCircle from "../Calculations/RightPanel/GreatCircle/GreatCircle";
import { makeStyles, Box, Paper, Typography, TextField, CircularProgress, Grid, useTheme, FormControl, RadioGroup} from "@material-ui/core";
import { Button } from "@mui/material";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// ------------- CUSTOM HOOKS -------------
import useFetch from "../../hooks/useCalculationFetch";

import axios from '../../axios-usercharges';

import DateTimePanel from "../fliteROUTE/DateTimePanel";
import DateTimePanelRestricted from "./DateTimePanelRestricted/DateTimePanelRestricted";

import { DateTime } from "luxon";
import LuxonUtils from '@date-io/luxon';

import CustomRadio from "../Calculations/RightPanel/RouteFilter/CustomRadio/CustomRadio";

import TabPanel from "../Utility/TabPanel/TabPanel";
import { VirtualizedTailNumberOriginAutocomplete, VirtualizedTailNumberDestinationAutocomplete } from "../Calculations/VirtualizationAutocomplete/VirtualizedAutocomplete";
import VirtualizedFlightRefAutocomplete from "./VirtualizationAutocomplete/VirtualizedAutocompleteFlown";

import axiosCalculations from '../../axios-calculations';
import AlertDialog from "../Utility/Alert/Alert";



const useStyles = makeStyles((theme) => ({
  
  root: {
    padding: '10px 0px',
    flexGrow: 1
   },
   gridContainer: {
     minHeight: '100vh',
     width: '100%'
   },
   paper: {
    // border: "1px solid #000",
    // borderRadius: 0,
    marginTop: '20px',
    marginBottom: '10px'
  },
  heading: {
    textAlign: "center",
    backgroundColor: "#95C6CE",
    fontSize: '1.6rem',
    // color: theme.palette.getContrastText(theme.palette.info.main),
    fontFamily: 'Mukta, sans-serif',
    color: 'black',
    fontWeight: 'bold',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Mukta, sans-serif'
  },
  radioFormControl: {
    // marginTop: "25px",
    fontSize: "11px",
  },
  controlSection: {
    padding: "5px",
    minHeight: "110px",
    "& h2": {
      padding: "2px",
      fontWeight: 700,
      fontSize: "1rem",
    },
    fontFamily: 'Mukta, sans-serif'
  },
 }));

const FlownRoute = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [airportsRes, airportsError, airportsIsLoading, fetchAirports] = useFetch([], {
    method: 'get',
    url: '/api/iataairports'
  });

  const [airlinesOfTheWorlds] = useFetch([], {
    method: 'get',
    url: '/api/all-airlines'
  });

  const airline = airlinesOfTheWorlds.find(value => value.ICAO === "UAL");

  const classes = useStyles();
  const theme = useTheme();
  const GreatCircleRef = React.createRef();

  const flownRouteAireon = "flownrouteaireon";
  const flownRouteSwim = "flownrouteswim";

  const minDateFRA = '2023-08-01';
  const maxDateFRA = '2023-08-31';

  const [useAllDates, setUseAllDates] = useState(false);
  const [rsData, setRsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flightReference, setFlightReference] = useState("");
  const [basicInfo, setBasicInfo] = useState(flownRouteSwim);
  const [selectedDate, setSelectedDate] = useState(DateTime.utc());
  const [tailNumber, setTailNumber] = useState("");
  const [flightRef, setFlightRef] = useState("");
  const [flightRefs, setFlightRefs] = useState([]);
  const [loadingFlightRefs, setLoadingFlightRefs] = useState(false);
  const [availableFlights, setAvailableFlights] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);
  const [swimOrigin, setSwimOrigin] = useState("");
  const [swimDestination, setSwimDestination] = useState("");
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [loadingSwimData, setLoadingSwimData] = useState(false);
  const [routeString, setRouteString] = useState("");
  const [aircraft, setAircraft] = useState("")
  const [aircraftModel, setAircraftModel] = useState("");
  const [tailNumberModel, setTailNumberModel] = useState("");
  const [tailNumberValue, setTailNumberValue] = useState("");
  const [errorMsg, setErrorMsg] = useState('');
  const [destOriginARN, setDestOriginARN] = useState(null)
  const [loadingDestOriginARN, setLoadDestOriginARN] = useState(false);
  const [alertOpen, setAlertOpen] = React.useState({
    open: false,
    msg: ''
  });

  useEffect(() => {
      if(airline) {
        setLoadingSwimData(true);
        axios.get(`/api/route-string-tailnumber-ual?airline=${airline.ICAO}&date=${selectedDate}`).then((result) => {
          console.log("result values ===>", result);
          if (result) {
              const filteredOptions = result.data.filter((item) => {
                return !(item.departure[0] === 'K' && item.arrival[0] === 'K')
              })
              setAvailableFlights(filteredOptions);
              console.log(filteredOptions)
              let newOriginOptions = [];
              for(const value of filteredOptions) {
                const airport = airportsRes.find(airportVal => airportVal.icao_code === value.departure);
                console.log(airport);
                console.log(value.departure);
                if(airport) {
                  if(!newOriginOptions.find((departure) => {return departure.icao_code === airport.icao_code})){
                    newOriginOptions.push(airport);
                 }
                }
              }
              newOriginOptions = newOriginOptions.filter((option) => option != null);
              setOriginOptions(newOriginOptions);
              console.log("originoptions", newOriginOptions);
              
          }
          setLoadingSwimData(false);
        }).catch((error) => {
          console.log(error)
        })
      }
  }, [airline])

  // Detect if the basic info has changed if so reset all fields
  useEffect(() => {
    reset();
  }, [basicInfo])

  useEffect(() => {
    setLoadingFlightRefs(true);
    axiosCalculations.get(`/api/flownroute/flightref/?date=${selectedDate}`).then((result) => {
      if (result) {
        // setHandleFlite(true);
        setLoadingFlightRefs(false);
        setFlightRefs(result.data);
      }
    }).catch((error) => {
      console.log(error);
    })
  }, [])

  const loadFRAFlightRefsForDate = (date) => {
      setLoadingFlightRefs(true);
      axiosCalculations.get(`/api/flownroute/flightref/?date=${date}`).then((result) => {
        setLoadingFlightRefs(false);
        setFlightRefs(result.data);
        
      }).catch((error) => {
        console.log(error);
      })
  }

  const reset = () => {
    setFlightRefs([]);
    setFlightRef("");
    setRsData(null);
    setLoading(false);
    if(basicInfo === flownRouteAireon) {
      setSelectedDate(DateTime.fromISO(maxDateFRA));
      loadFRAFlightRefsForDate(DateTime.fromISO(maxDateFRA));
    } else {
      setSelectedDate(DateTime.utc())
      setSwimOrigin('');
      setSwimDestination('')
      setRouteString('');
      setAircraft('');
      setAircraftModel('');
      setFlightRef('');
      setTailNumberValue('');
    }
  }

  const handleDateChange = (date) => {
    
    if(basicInfo === flownRouteAireon) {
      setFlightRefs([]);
      setFlightRef("");
      loadFRAFlightRefsForDate(date);
    } else {
      setFlightRefs([]);
      setFlightRef("");
      setRsData(null);
      setLoading(false);
      setSwimOrigin('');
      setSwimDestination('')
      setRouteString('');
      setAircraft('');
      setAircraftModel('');
      setFlightRef('');
      setTailNumberValue('');
      setLoadingSwimData(true);
      axios.get(`/api/route-string-tailnumber-ual?airline=${airline.ICAO}&date=${date}`).then((result) => {
          console.log("result values ===>", result);
          if (result) {
              // setHandleFlite(true);
              const filteredOptions = result.data.filter((item) => {
                return !(item.departure[0] === 'K' && item.arrival[0] === 'K')
              })
              setAvailableFlights(filteredOptions);
              let newOriginOptions = [];
              
              for(const value of filteredOptions) {
                const airport = airportsRes.find(airport => airport.icao_code === value.departure);
                if(airport) {
                  if(!newOriginOptions.find((departure) => {return departure.icao_code === airport.icao_code})){
                    newOriginOptions.push(airport);
                  }
                }
              }
              newOriginOptions = newOriginOptions.filter((option) => option != null);
              setOriginOptions(newOriginOptions);
              console.log("originoptions", newOriginOptions);
             
          } 
          setLoadingSwimData(false);
        }).catch((error) => {
          console.log(error)
        })
    }
    console.log(date);
    setSelectedDate(date);
  }

  const handleBasicInfoChange = (event) => {
    setBasicInfo(event.target.value);
    reset();
  };


  const onCalculateClick = () => {
    if(basicInfo === flownRouteAireon) {

        if(flightReference.match(/UAL[0-9]+/)){
          setLoading(true);
          axiosCalculations.get(`api/flownroute/?flightref=${flightRef.flight_ref}&targetaddress=${flightRef.target_address}&date=${selectedDate}`)
          .then((response) => {
            console.log("response: ", response.data);
            console.log(flightRef)
            setRsData(response.data);
            setLoading(false);
          }).catch((err) => {
            console.log(err);
            setLoading(false);
            setAlertOpen({
              open: true,
              msg: err.response.data.message
            })
          })
        } else {
          setAlertOpen({
            open: true,
            msg: "Flight # must match the form UAL####"
          })
        }
    } else {
      if (tailNumberValue) {
        setLoading(true);
        console.log("routestring condition checked, ");
        axiosCalculations.get(`api/routestring/?routestring=${routeString}&model=${aircraftModel}&routeType=flownRoute&flight_ref=${flightRef}`)
          .then((response) => {
            console.log("response: ", response.data);
            setRsData(response.data);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false)
            console.log("error: ", error)
            setAlertOpen({
              open: true,
              msg: error.response ? error.response.data.message : "Something went wrong",
            });
          })
        resetError();          
      } else {
        setRouteFilterError(aircraft, routeString, null, tailNumberValue);
      }
    }

  }

  const setRouteFilterError = (aircraft, origin, destination) => {
    let msg = "Please select ";
    let errors = [];
    if (basicInfo === 'greatCircle') {
      if (!aircraft) {
        errors.push('Aircraft type');
      }
      if (!origin) {
        errors.push('Origin');
      }
      if (!destination) {
        errors.push('Destination');
      }
    } else if (basicInfo === 'routestring' || basicInfo === 'flownRoute' || basicInfo === 'bothRoute') {
      if (!origin) {
        errors.push('Departure-Arrival');
      }
    }
    setErrorMsg(msg + errors.join(', ').replace(/,(?=[^,]*$)/, ' and'));
  };


  const resetError = () => {
    setErrorMsg('');
  };

  const handleSwimOriginChange = (value) => {
    setSwimOrigin(value);
    setSwimDestination("");
    console.log("valuevalue", value);
    const filteredDepartureOptions = availableFlights.filter(flite => flite.departure === value.icao_code);
    console.log("filteredDestinations", filteredDepartureOptions);
    
    let newTailDestOptions = [];
    for(const value of filteredDepartureOptions) {
      const airport = airportsRes.find(airport => airport.icao_code === value.arrival && airport.icao_code !== value.icao_code);
      if(airport) {
        if(!newTailDestOptions.find((departure) => {return departure.icao_code === airport.icao_code})){
          newTailDestOptions.push(airport)
        }
      }
    }
    newTailDestOptions = newTailDestOptions.filter((option) => option != null);
    setDestinationOptions(newTailDestOptions);
    setRouteString('');
    setAircraft('');
    setAircraftModel('');
    setRsData(null);
    setFlightRef('');
    setTailNumberValue('');
    setTailNumberModel('')
  }

  const handleSwimDestinationChange = (value) => {
    setSwimDestination(value);;
    const fliteToDisplay = availableFlights.find(flite => flite.departure === swimOrigin.icao_code && flite.arrival === value.icao_code);
    console.log(fliteToDisplay);
    const aircraft = fliteToDisplay;
    const aircraftTailNumber = aircraft.registration;
    setTailNumberModel(aircraft);
    setTailNumberValue(aircraftTailNumber);
    const filteredData = availableFlights.filter((element) => {
      return element.registration === aircraftTailNumber;
    })
    console.log(filteredData);
    setAircraft(aircraft.aircraft_specs_value);
    setAircraftModel(aircraft.aircraft_specs_value);
    setRouteString(aircraft.legacy_format);
    setFlightRef(aircraft.flight_ref)
  }

  const handleTailNumberChange = (value) => {
    setTailNumber(value);
  }

  const handleFlightRefChange = (value) => {
    setFlightRef(value);
    setFlightReference(value.flight_ref);
    setLoadDestOriginARN(true)
    axiosCalculations.get(`/api/flownroute/destorgn/?flightref=${value.flight_ref}&targetaddress=${value.target_address}&date=${selectedDate}`).then((result) => {
      console.log(result.data);
      setDestOriginARN(result.data);
      setLoadDestOriginARN(false);
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleAlertClose = () => {
    setAlertOpen({
      open: false,
      msg: ''
    });
  };

  return (
    <>
      <Navbar index ={3}/>
      <Box
        className={classes.gridContainer}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="stretch"
      >
        <div style={{width: "95%", margin: "0 auto"}}>
          <Paper className={classes.paper} elevation={0}>
            <Typography className={classes.heading} variant="h6" component="h2" style={{backgroundColor: "#180aa2", color: "white"}}>
              Flown Route Inputs
            </Typography>
              <TabPanel
                  value={0}
                  index={0}
                  dir={theme.direction}
                  className={classes.tabPanel}
                >
                <Grid container>
                  <Grid md item className={classes.controlSection}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      className={classes.radioFormControl}
                    >
                      <RadioGroup row aria-label="basic info" name="basic-info" value={basicInfo} onChange={handleBasicInfoChange} className={classes.radioGroup}>
                        {" "}
                        {/* <CustomRadio value="country" label="Country Basis" disabled />
                        <CustomRadio value="airport" label="Airport Basis" disabled /> */}
                        {/* <CustomRadio value="route" label={<Typography style={{ fontSize: '1.3rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >Single Route</Typography>} /> */}
                        <CustomRadio value="flownrouteswim" label={<Typography style={{ fontSize: '1.3rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >Flown Route - SWIM</Typography>} style={{ 'whiteSpace': 'nowrap' }} />
                        <CustomRadio value="flownrouteaireon" label={<Typography style={{ fontSize: '1.3rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >Flown Route - AIREON</Typography>} style={{ 'whiteSpace': 'nowrap' }} />
                        {/* <CustomRadio value="greatCircle" label="ROUTE STRING" style={{ 'whiteSpace': 'nowrap' }} /> */}
                        {/* <CustomRadio value="overall" label="Overall Basis" disabled /> */}
                      </RadioGroup>
                  </FormControl>
                </Grid>
                {basicInfo === flownRouteSwim && 
                  <>
                    <Grid md item className={classes.controlSection}>
                      <Typography variant="h6" component="h2" style={{ paddingLeft: "15px", fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
                        Date
                      </Typography>
                      <Grid container>
                        <Grid xs item>
                          <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <DateTimePanel selectedDate={selectedDate} handleDateChange={handleDateChange} useAllDates={useAllDates} setUseAllDates={setUseAllDates} />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    <Grid md item className={classes.controlSection}>
                      {loadingSwimData ?
                       <div style={{display: "flex", flexDirection: "column"}}>
                        <CircularProgress size={70}
                          left={-20}
                          top={10}
                          style={{ marginLeft: '50%', marginTop: '40px' }}
                        />
                        <span style={{ fontSize: "2rem" }}>Loading</span>
                      </div>
                      :  
                      <div style={{ display: availableFlights.length > 0 ? "block" : "none" }}>
                        <Typography variant="h6" component="h2" style={{ paddingLeft: "15px",fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
                          Route Lookup
                        </Typography>
                        <Grid container>
                          <Grid xs item>
                            <FormControl fullWidth>
                              {
                                originOptions &&
                                <VirtualizedTailNumberOriginAutocomplete value={swimOrigin} options={originOptions} label={<Typography className={classes.select} >Origin</Typography>} onChange={handleSwimOriginChange} />
                              }
                            </FormControl>
                              {swimOrigin && swimDestination && <TextField
                              id="outlined-read-only-input"
                              label="Aircraft Model"
                              value={aircraftModel ? aircraftModel : ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.select,
                              }}
                            />}
                          </Grid>
                          <Grid xs item>
                            <FormControl fullWidth>
                              {
                                destinationOptions && swimOrigin && 
                                <VirtualizedTailNumberDestinationAutocomplete value={swimDestination} options={destinationOptions} label={<Typography className={classes.select} >Destination</Typography>} onChange={handleSwimDestinationChange} />
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                      
                      }
                      {
                        !loadingSwimData && availableFlights && 
                        <div style={{ display: availableFlights.length === 0 ? "block" : "none", marginTop: "50px" }}>
                          <h3>No Flights Found For Current Date</h3>
                        </div>
                      }
                    </Grid>
                    <Grid md item className={classes.controlSection}>
                    {routeString && <Grid md item className={classes.controlSection} style={{visibility : routeString.length > 0 ? "visible" : "hidden"}}>
                        <Typography variant="h6" component="h2" style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                          Route String Input
                        </Typography>
                        <Grid container>
                          <Grid xs item>
                            <FormControl fullWidth>
                              <TextField value={routeString} onChange={props.handleInputChange} variant="outlined" label="Enter your Route String"></TextField>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Typography variant="h6" component="h2" style={{ fontSize: '0.8rem', fontFamily: 'Mukta, sans-serif', whiteSpace: 'nowrap' }}>
                          Please include origin and destination airport (ex: KJFK..WAVEY..DIZNY..ZQA..MKJP)
                        </Typography>
                      </Grid>}
                    </Grid>
                  </>
                }
                {basicInfo === flownRouteAireon && 
                <>
                  <Grid md item className={classes.controlSection}>
                      <Typography variant="h6" component="h2" style={{ paddingLeft: "15px",fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
                        Date
                      </Typography>
                      <Grid container>
                        <Grid xs item>
                          <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <DateTimePanelRestricted selectedDate={selectedDate} handleDateChange={handleDateChange} minDate={minDateFRA} maxDate={maxDateFRA} />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid md item className={classes.controlSection}>
                    {loadingFlightRefs ? <div style={{display: "flex", flexDirection: "column"}}>
                    <CircularProgress size={70}
                      left={-20}
                      top={10}
                      style={{ marginLeft: '50%', marginTop: '40px' }}
                    />
                    <span style={{ fontSize: "2rem" }}>Loading</span>
                  </div>
                  : 
                  <div style={{ display: flightRefs.length > 0 ? "block" : "none" }}>
                      <Typography variant="h6" component="h2" style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
                        Flight Reference #
                      </Typography>
                      <div style={{ display: flightRefs.length > 0 ? "block" : "none" }}>
                        <Grid container>
                          <Grid xs item>
                            <FormControl fullWidth>
                            {
                              flightRefs && 
                              <VirtualizedFlightRefAutocomplete value={flightRef} options={flightRefs} label={<Typography className={classes.select} >FLIGHT REFERENCE #</Typography>} onChange={handleFlightRefChange} />
                            }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </div>}
                    {
                      !loadingFlightRefs && flightRefs && 
                      <div style={{ display: flightRefs.length === 0 ? "block" : "none", marginTop: "50px" }}>
                        <h3>No Flight Refs Found For Current Date</h3>
                      </div>
                    }
                  </Grid>
                </>
                }
                {basicInfo === flownRouteAireon && 
                <Grid md item className={classes.controlSection}>
                  {!loadingFlightRefs && destOriginARN && 
                    <>
                    {loadingDestOriginARN ?
                       <div style={{display: "flex", flexDirection: "column"}}>
                        <CircularProgress size={70}
                          left={-20}
                          top={10}
                          style={{ marginLeft: '50%', marginTop: '40px' }}
                        />
                        <span style={{ fontSize: "2rem" }}>Loading</span>
                      </div>
                      :  
                      <>
                      <Typography variant="h6" component="h2" style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
                        Origin/Destination
                      </Typography>
                      <TextField
                          id="outlined-read-only-input"
                          label="Origin"
                          value={`(${destOriginARN.origin.designator_iata}) ${destOriginARN.origin.name}`}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.select,
                          }}
                        />
                      <TextField
                          id="outlined-read-only-input"
                          label="Destination"
                          value={`(${destOriginARN.destination.designator_iata}) ${destOriginARN.destination.name}`}
                          style={{ paddingLeft: "2px" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.select,
                          }} 
                        />
                        </>}
                    </>
                  }
                </Grid>
                }
              </Grid>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: "10px"}} >
                <Button  variant="contained" size='small' style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1.3rem', backgroundColor: '#243A69', color: 'white' }} onClick={onCalculateClick}>
                  Calculate
                </Button>
                <Button  variant="contained" size='small' style={{ fontFamily: 'Mukta, sans-serif', fontSize: '1.3rem', backgroundColor: '#243A69', color: 'white' }} onClick={reset}>
                  Reset
                </Button>
              </div>
            </TabPanel>
          </Paper>
          <div style={{ alignItems: 'end', gridRow: '2 / 3', gridColumn: '1 / 3' }} >
              <hr />
          </div>

          {loading && 
            <div style={{display: "flex", justifyContent: "center", marginTop: "40px"}}>
              <CircularProgress color='#243A69' style={{ width: '80px', height: '80px', zIndex: '10' }} />
            </div>
          }
          {rsData && <GreatCircle ref={GreatCircleRef} rsData={rsData} />}
          <AlertDialog open={alertOpen.open} msg={alertOpen.msg} handleClose={handleAlertClose} />
        </div>
      </Box>
    </>
  );
}

export default FlownRoute;