// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { makeStyles } from "@material-ui/core/styles";

// ------------- COMPONENTS -------------
import FlightDayPicker from "./FlightDayPicker";

// ------------- MUI STYLES-------------
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 1rem 1rem",
    },
});
export default function DateTimePanel({
    selectedDate,
    handleDateChange,
    useAllDates,
    setUseAllDates,
    dataSourceType,
}) {
    // ------------- MUI STYLES -------------
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FlightDayPicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                useAllDates={useAllDates}
                setUseAllDates={setUseAllDates}
                dataSourceType={dataSourceType}
            />
        </div>
    );
}
