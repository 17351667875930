// ------------- REACT -------------
import React from 'react';

// ------------- RECHARTS -------------
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ClimbCruiseDescentLineChart = React.forwardRef((props, ref) => {
    const { gcData } = props;
    const width = 550;
    const height = 350;
    const cruiseFuel = gcData.features.reduce((accumulator, current) => {
        return accumulator + parseInt(current.properties.fuel);
    }, 0);
    const cruiseCO2 = cruiseFuel * 3.16;

    const graphData = [
        {
            name: "0%",
            flight: 10,
        },
        {
            name: "10%",
            flight: 10,
            phase: 'Climb',
            fuel: `Fuel: ${parseInt(gcData.climbData.climbFuel).toLocaleString()} (kg)`,
            co2: `CO2: ${parseInt(gcData.climbData.climbCO2).toLocaleString()} (kg)`
        },
        {
            name: "20%",
            flight: 10,
        },
        {
            name: "30%",
            flight: 80,
        },
        {
            name: "40%",
            flight: 80,
        },
        {
            name: "50%",
            flight: 80,
            phase: 'Cruise',
            fuel: `Fuel: ${parseInt(cruiseFuel.toFixed(0)).toLocaleString()} (kg)`,
            co2: `CO2: ${parseInt(cruiseCO2.toFixed(0)).toLocaleString()} (kg)`
        },
        {
            name: "60%",
            flight: 80,
        },
        {
            name: "70%",
            flight: 80,
        },
        {
            name: "80%",
            flight: 10,
        },
        {
            name: "90%",
            flight: 10,
            phase: 'Descent',
            fuel: `Fuel: ${parseInt(gcData.descentData.descentFuel).toLocaleString()} (kg)`,
            co2: `CO2: ${parseInt(gcData.descentData.descentCO2)} (kg)`
        },
        {
            name: "100%",
            flight: 10,
            fuel: ""
        }
    ];
    const CustomizedLabel = ({ x, y, stroke, value, index }) => {
        const fuelValue = graphData[index] && graphData[index].fuel;
        const CO2Value = graphData[index] && graphData[index].co2;
        const phase = graphData[index] && graphData[index].phase;
        return (<>
            <text x={x} y={y} dy={-10} fill={stroke} fontSize={14} fontFamily='Mukta, sans-serif' textAnchor="middle">
                {CO2Value}
            </text>
            <text x={x} y={y - 20} dy={-10} fill={stroke} fontSize={14} fontFamily='Mukta, sans-serif' textAnchor="middle">
                {fuelValue}
            </text>
            <text x={x} y={y + 30} dy={-10} fill={stroke} fontSize={14} fontWeight="bold" fontFamily='Mukta, sans-serif' textAnchor="middle">
                {phase}
            </text>
        </>);
    };
    return (
        <div style={{ 'width': `${width}px` }} ref={ref} >
            <LineChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                    top: 40,
                    right: 50,
                    left: 20,
                    bottom: 5
                }}
            >
                <XAxis dataKey="name" />
                <CartesianGrid strokeDasharray="3 3" />
                <Line type="monotone" dataKey="flight" stroke="#8884d8" label={<CustomizedLabel />}
                    isAnimationActive={false} // need this or the labels get covered up
                />
            </LineChart>
        </div>
    );
});


export default ClimbCruiseDescentLineChart;