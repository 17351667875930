// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { Typography, makeStyles } from "@material-ui/core";

// ------------- RECHARTS -------------
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

// ------------- MUI STYLES -------------
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: -999,
    paddingBottom: '20px'
  },
}));

const FirChargePerMinChart = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const width = 550;
  const height = 350;
  const getCostPerMin = (usercharge) => {
    if (usercharge.fir_time1) {
      const time = usercharge.fir_time1.split(':');
      return (+usercharge.cost / (+time[0]*60 + +time[1])).toFixed(2);
    } else {
      return 0;
    }
  };
  return (
    <div style={{ width: `${width}px` }} ref={ref} className={classes.root}>
      <Typography variant="h6" component="h3" align="center">
        Charge Per Min
      </Typography>
      <BarChart width={width} height={height} data={props.flight.usercharge}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fir" interval={0} angle={-45} textAnchor="end" height={100} />
        <YAxis type="number" interval={0} tickFormatter={(tick) => '$' + tick} allowDecimals={true} />
        
        <Tooltip />
        <Bar dataKey={getCostPerMin} fill="#9DBD5B" />
      </BarChart>
    </div>
  );
});

export default FirChargePerMinChart;
