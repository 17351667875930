// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { Typography } from "@material-ui/core";
import ArticleIcon from '@mui/icons-material/Article';

function PlaceHolder (props) {
  return (
    <>
      <div style={{
          width: "95%", 
          height: "75vh", 
          backgroundColor: "#f8f8f8",
          display: "flex", 
          margin: "0 auto",
          border: "5px dashed grey",
          borderRadius: "30px",
          flexDirection: "column", 
          justifyContent: "center", 
          alignItems: "center"}}
          >
          <ArticleIcon style={{color: "grey", height: "200px", width: "200px"}}/>
          <Typography variant="h4" component="h6" style={{width: "100%", fontWeight: "bold", color: "grey", textAlign: "center", paddingTop: "20px"}}>
              {props.title}
          </Typography>
          
          <Typography variant="h6" component="h6" style={{width: "100%", color: "grey", textAlign: "center", paddingTop: "20px"}}>
              {props.description}
          </Typography>
      </div>
    </>
  );
}

export default PlaceHolder;