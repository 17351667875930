// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { Icon } from "@material-ui/core";

// ------------- LOGOS -------------
import ualLogo from "../../assets/logo/ual_logo.jpg";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import ACTable from "./ACTable";

function Aircraft () {
  return (
    <>
      <Navbar index={10}/>
      <ACTable/>
    </>
  )
}

export default Aircraft;