// ------------- REACT -------------
import React, { useEffect, useState } from 'react';

// ------------- REACT APEX CHARTS -------------
import ReactApexChart from 'react-apexcharts';

// ------------- AXIOS -------------
import axios from '../../../axios-usercharges';

// ------------- MUI -------------
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';

// ------------- LUXON -------------
import { DateTime } from 'luxon';
import DateFnsUtils from '@date-io/luxon';

 
const Chart1 = () => {
    // ------------- STATES -------------
    const [chartData, setChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(DateTime.utc());
    const [isLoading, setIsLoading] = useState(true);
 
    useEffect(() => {
        fetchData(selectedDate);
    }, [selectedDate]);
 
    const fetchData = (date) => {
        setIsLoading(true);
        const date_1 = new Date(date);
        const year = date_1.getFullYear();
        const month = (date_1.getMonth() + 1).toString().padStart(2, '0');
        const day = date_1.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const selectedDateISO = formattedDate;
        console.log("selectedDateISO", selectedDateISO);
 
        axios.get(`/api/chart1?selectedDate=${selectedDateISO}`)
            .then((res) => {
                setChartData(res.data.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
 
    const funnelOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                distributed: true,
                barHeight: '80%',
                isFunnel: true,
            },
        },
        colors: [
            '#F44F5E',
            '#E55A89',
            '#D863B1',
            '#CA6CD8',
            '#B57BED',
            '#8D95EB',
            '#62ACEA',
            '#4BC3E6',
        ],
        dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex];
            },
            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: chartData.map(item => item.route),
        },
        yaxis: {
            categories: chartData.map(item => item.cost_sum),
        },
    };
 
    const funnelSeries = [
        {
            name: '',
            data: chartData.map(item => item.cost_sum),
        },
    ];
 
    const startDate = DateTime.fromISO('2021-01-15').minus({ day: 1 });
 
    const handleDateChange = (date) => {
        const date_1 = new Date(date);
        const year = date_1.getFullYear();
        const month = (date_1.getMonth() + 1).toString().padStart(2, '0');
        const day = date_1.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        if (formattedDate) {
            setSelectedDate(formattedDate);
        }
    };
 
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <KeyboardDatePicker
                        style={{ width: '200px', fontSize: '1.25rem' }}
                        value={selectedDate}
                        placeholder="01/01/2021"
                        onChange={date => handleDateChange(date)}
                        minDate={startDate}
                        maxDate={DateTime.utc()}
                        format="DD"
                    />
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <ReactApexChart options={funnelOptions} series={funnelSeries} type="bar" height={400} />
                    )}
                </div>
            </MuiPickersUtilsProvider>
        </>
    );
};
 
export default Chart1;