import { getChildProp } from './util';

export function convertToCSV(keys, objArray) {
    var array = objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var key of keys) {
            if (line !== '') line += ','

            line += getChildProp(array[i], key) || '';
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCSVFile(headers, items, fileTitle, preheader) {
    const tmpItems = items.slice();
    if (headers) {
        tmpItems.unshift(headers);
    }

    var csv = convertToCSV(Object.keys(headers), tmpItems);

    if (preheader) {
        csv = preheader.join(',') + '\r\n' + csv;
    }

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}