// ------------- REACT -------------
import React, { useState, useEffect } from "react"

// ------------- MUI -------------
import { MenuItem, FormControl, Select, InputLabel, CircularProgress } from "@mui/material" ;
import { Icon } from "@material-ui/core";

// ------------- LUXON -------------
import { DateTime } from "luxon";
import axios from "../../axios-usercharges";

// ------------- LOGOS -------------
import ualLogo from "../../assets/logo/ual_logo.jpg";

// ------------- COMPONENTS -------------
import PlaceHolder from "../Utility/PlaceHolders/PlaceHolder";
import PaymentApprovalForm from "./PaymentApprovalForm";

function DataExtract () {
  // ------------- STATES -------------
  const [selectedCountry, setSelectedCountry] = useState("");
  const [yearMonths, setYearMonths] = useState([]);
  const [showDataExtract, setShowDataExtract] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [anspData, setAnspData] = useState({});

  const getFormattedDateFromMonthYear = (yearMonthString) => {
      if (yearMonthString && typeof yearMonthString === 'string' && yearMonthString.includes(' ')) {
        const [year, month] = yearMonthString.split(" ");
        const date = DateTime.fromObject({ year, month });
        const formattedString = `${date.toFormat("MMMM yyyy")}`;
        return formattedString;
      } else {
        return "No data";
      }
    };

  const fetchCountries = async () => {
      try {
          const response = await axios.get("/api/get-countries");
          if (response.status === 200) {
              setCountries(response.data.countries);
          }
      } catch (error) {
          console.error("Error fetching countries:", error);
      }
  };

  const fetchYearMonthsForCountry = async (country) => {
      try {
          const response = await axios.post("/api/get-year_months", { country });
          if (response.status === 200) {
              setYearMonths(response.data.yearMonths);
          }
      } catch (error) {
          console.error("Error fetching year-months for country:", error);
      }
  };

  const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
          const response = await axios.post("/api/get-xtracta-data", {
              month_year: selectedMonthYear,
          });

          if (response.status === 200) {
              if (response.data.data) {
                  setData(response.data.data);
              } else {
                  setError("Data not found for the specified date.");
              }
          } else {
              setError("Data not found for the specified date.");
          }
      } catch (error) {
          setError("An error occurred while retrieving data from the database.");
      } finally {
          setIsLoading(false);
      }
  };

  const fetchAnspForCountry = async (country) => {
    try {
        const response = await axios.get(`/api/ansp/${country}`);
        if (response.status === 200) {
            setAnspData(response.data)
            console.log("ansp", response.data);
        }
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
  }

  useEffect(() => {
      fetchCountries();
  }, []);

  useEffect(() => {
      if (selectedCountry) {
          fetchYearMonthsForCountry(selectedCountry);
          fetchAnspForCountry(selectedCountry);
      }
  }, [selectedCountry]);

  useEffect(() => {
      if (selectedMonthYear) {
          fetchData();
      }
  }, [selectedMonthYear]);

  const handleCountrySelect = (event) => {
      const country = event.target.value;
      setSelectedCountry(country);
      setShowDataExtract(false);
  };

  const handleYearMonthSelect = (event) => {
      const ym = event.target.value;
      setSelectedMonthYear(ym);
      setShowDataExtract(true);
  };

  const getCountryMenuItems = () => {
      return countries.map((country) => (
          <MenuItem key={country} value={country}>
              {country}
          </MenuItem>
      ));
  };

  const getYearMonthsMenuItems = () => {
      const reversedYearMonths = [...yearMonths].reverse();

      return reversedYearMonths.map((ym) => {
          const formattedString = getFormattedDateFromMonthYear(ym);
          return (
              <MenuItem key={ym} value={ym}>
                  {formattedString}
              </MenuItem>
          );
      });
  };

  const ErrorMessage = ({ message }) => {
    return (
        <div style={{ color: "red", marginTop: "10px" }}>
            <b>{message}</b>
        </div>
    );
  };  

  return (
    <>
        <div style={{width:"95%", margin: "0 auto", paddingTop: "30px"}}>
            <div
                style={{
                    marginTop: '15px',
                    backgroundColor: '#180aa2',
                    fontSize: '22px',
                    padding: '10px 20px',
                    fontWeight: '700',
                    color: 'white'
                }}
            >
                <span>Air Navigation Payment Approval Form</span>
            </div>
          <div style={{padding: '25px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>   
                      <span style={{ fontSize: '18px' , minWidth: "150px"}}>Country:</span>
                      <FormControl sx={{ m: 1, minWidth: 300 }}>
                          <InputLabel id="country-label">Country</InputLabel>
                          <Select
                          labelId="country-label"
                          id="country-select"
                          value={selectedCountry}
                          label="Country"
                          onChange={handleCountrySelect}
                          >
                          {getCountryMenuItems()}
                          </Select>
                      </FormControl>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>   
                      <span style={{ fontSize: '18px', minWidth: "150px"}}>Date:</span>
                      <FormControl sx={{ m: 1, minWidth: 300 }}>
                          <InputLabel id="date-label">Date</InputLabel>
                          <Select
                          labelId="date-label"
                          id="date-select"
                          value={selectedMonthYear}
                          label="Date"
                          disabled={!selectedCountry}
                          onChange={handleYearMonthSelect}
                          >
                          {getYearMonthsMenuItems()}
                          </Select>
                      </FormControl>
                  </div>
              </div>
          </div>
      </div>
  
      {(!showDataExtract && !isLoading) && 
          <PlaceHolder 
              title="ANPA"
              description="Please select a country and date"
          />
      }
      {isLoading ? (
          <div
              style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "80px",
              }}
          >
              <CircularProgress size={65} />
          </div>
      ) : error ? (
          <ErrorMessage message={error} />
      ) : (
          showDataExtract && (
              <div>
                  <PaymentApprovalForm data={data} anspData={anspData}/>
              </div>
          )
      )}
    </>
  );
}

export default DataExtract;