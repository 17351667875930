// ------------- REACT -------------
import React, { useEffect, useState } from 'react';

// ------------- REACT APEX CHARTS -------------
import ReactApexChart from 'react-apexcharts';

// ------------- AXIOS -------------
import axios from '../../../axios-usercharges';

// ------------- MUI -------------
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';

// ------------- LUXON -------------
import { DateTime } from 'luxon';
import DateFnsUtils from '@date-io/luxon';
 
const Chart5 = () => {
    // ------------- STATES -------------
    const [chartData, setChartData] = useState({ data: [] });
    const [selectedDate, setSelectedDate] = useState(DateTime.utc());
    const [isLoading, setIsLoading] = useState(false);
 
    useEffect(() => {
        fetchData(selectedDate);
    }, [selectedDate]);
 
    const fetchData = (date) => {
        setIsLoading(true);
        const date_1 = new Date(date);
        const year = date_1.getFullYear();
        const month = (date_1.getMonth() + 1).toString().padStart(2, '0');
        const day = date_1.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const selectedDateISO = formattedDate;
 
        axios.get(`/api/chart5?selectedDate=${selectedDateISO}`)
            .then((res) => {
                setChartData(res.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
 
    const chartOptions = {
        chart: {
            height: 350,
            type: 'bar',
        },
        xaxis: {
            categories: chartData.data.map(item => item.country),
            title: {
                text: 'Countries',
            },
        },
        yaxis: {
            title: {
                text: 'Cost',
            },
        },
    };
 
    const chartSeries = [
        {
            name: 'Cost',
            data: chartData.data.map(item => item.value),
        },
    ];
    const startDate = DateTime.fromISO('2021-01-15').minus({ day: 1 });
 
    const handleDateChange = (date) => {
        const date_1 = new Date(date);
        const year = date_1.getFullYear();
        const month = (date_1.getMonth() + 1).toString().padStart(2, '0');
        const day = date_1.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        if (formattedDate) {
            setSelectedDate(formattedDate);
        }
    };
 
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <div style={{ marginLeft: "20px" }}>
                        <KeyboardDatePicker style={{ width: '200px', fontSize: '1.25rem' }}
                            value={selectedDate}
                            placeholder="01/01/2021"
                            onChange={date => handleDateChange(date)}
                            minDate={startDate}
                            maxDate={DateTime.utc()}
                            format="DD"
                        />
                    </div>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={400} />
                    )}
                </div>
            </MuiPickersUtilsProvider>
        </>
    );
};
 
export default Chart5;