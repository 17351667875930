// ------------- REACT -------------
import React, { useEffect, useState } from 'react';

// ------------- MUI -------------
import {
    makeStyles,
    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";

// ------------- MISC -------------
import { getFormattedDateFromMonthYear } from '../../shared/util';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 0px',
        flexGrow: 1
    },
    boldFont: {
        fontWeight: 'bold',
    },
    select: {
        fontWeight: 'bold',
        marginTop:'70px',
        fontSize:'1.5rem',
        color:'black'
    },
    page: {
        width:"95%", 
        margin: "30px auto"
    },
    title: {
        marginTop: '15px', 
        backgroundColor: '#180aa2', 
        fontSize: '22px', 
        padding: '10px 20px', 
        fontWeight: '700', 
        color: 'white' 
    },
    heading: {
        marginTop: '15px', 
        backgroundColor: '#180aa2',
        fontSize: '22px', 
        padding: '5px 20px', 
        color: 'white', 
        width: 'fit-content'
    }
}));

export default function CountryDateSelection(props) {
    // ------------- MUI STYLES -------------
    const classes = useStyles();
    
    // ------------- DESTRUCTURING PROPS -------------
    const { countriesByYearMonth, setSelectedData, getFlightData, getFlightDataCount } = props;
    
    // ------------- STATES -------------
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedYearMonth, setSelectedYearMonth] = useState('');
    const [yearMonths, setYearMonths] = useState([]);

    const countriesAvailable = Object.keys(countriesByYearMonth);

    // ------------- FUNCTIONS -------------
    const handleCountrySelect = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        const year = Object.keys(countriesByYearMonth[country]).sort().reverse();
        const latestYear = year[0];
        const months = countriesByYearMonth[country][latestYear].slice(-12);
        const yearMonths = months.map(ym => `${latestYear} ${ym}`);
        setYearMonths(yearMonths);
        setSelectedYearMonth('');
        setSelectedData('');
    };
    
    const handleYearMonthSelect = (event) => {
        const ym = event.target.value;
        setSelectedYearMonth(ym);  
    };

    const getCountryYearMonthsMenuItems = (country) => {
        const menuItems = yearMonths.map(ym => {
            const formattedString = getFormattedDateFromMonthYear(ym);

            return <MenuItem key={ym} value={ym}>{formattedString}</MenuItem>
        });
        return (
            menuItems
        );
    };

    // ------------- HOOKS -------------
     useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        if(selectedCountry && selectedYearMonth){
            getFlightData(selectedCountry,selectedYearMonth, signal);
        }
        return () => {
            // Cancel the request when the component unmounts
            abortController.abort();
        };
    },[selectedCountry,selectedYearMonth])

    return (
        <>
            <div className={classes.page}>
                <div className={classes.title}>
                    <span>Air Navigation Beta Invoice Generator</span>
                </div>
                <div className={classes.heading} >
                    <span>Invoice Details</span>
                </div>
                <div style={{marginTop: '15px', padding: '25px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* Country Selection */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>   
                            <span style={{ fontSize: '18px' }}>Country/ANSP:</span>
                                <FormControl fullWidth variant="outlined" style={{marginLeft:'30px', width: "300px"}} >
                                    <Select value={selectedCountry} className={classes.boldFont} displayEmpty onChange={handleCountrySelect}>
                                        {countriesAvailable &&
                                            countriesAvailable.sort().map((country) => (
                                                <MenuItem key={country} value={country}>{country}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                        </div>
                        {/* Year Month Selection*/}
                        <div style={{display: 'flex', alignItems: 'center', }}>   
                            <span style={{ fontSize: '18px' }}>BILLING CYCLE:</span>
                            <FormControl fullWidth variant="outlined" style={{marginLeft:'30px', width: "300px"}} > 
                                <Select value={selectedYearMonth} className={classes.boldFont} displayEmpty disabled={!selectedCountry} onChange={handleYearMonthSelect}>
                                    {getCountryYearMonthsMenuItems(selectedCountry)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};