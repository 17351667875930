// ------------- REACT -------------
import React, {useState, useEffect } from 'react';

// ------------- MUI -------------
import { Box, Typography } from '@material-ui/core';

// ------------- REACT APEX CHARTS -------------
import ReactApexChart from 'react-apexcharts';

export default function CountryCostPieChart({ data }) {
  // ------------- STATES -------------
  const [chartData, setChartData] = useState({
    options: {
        chart: {
            type: 'pie',
        },
        labels: [],
    },
    series: [],
  });

  useEffect(() => {
    // Set up data for donut chart
    const series = data.map((item) => Number(item.commonUsdCost.toFixed(2)));
    const labels = data.map((item) => `${item.country} $${item.commonUsdCost.toFixed(2)}`);
    const colors = data.map((item) => item.properties.stroke);

    setChartData({
        options: {
            chart: {
                type: 'pie',
            },
            labels: labels,
            colors: colors
        },
        series: series,
    });
}, []);
  
  
  return (
    <div >
      <Typography variant="h6" component="h3" align="center" style={{ backgroundColor: "#180aa2", color: "white", marginBottom: "30px", fontFamily: 'Mukta' }}>
        FIR Cost Per Country (USD)
      </Typography>
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ReactApexChart
              style={{ width: "100%" }}
              options={chartData.options}
              series={chartData.series}
              type="pie"
          />
      </Box>
     
      </div>
  )
};