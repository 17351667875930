// ------------- REACT -------------
import React, { useEffect, useState, useContext } from "react";

// ------------- CUSTOM HOOKS -------------
import useFetch from "../../../hooks/useFetch";

// ------------- MUI -------------
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    TextareaAutosize,
    Snackbar,
    Alert,
} from "@mui/material";

import { makeStyles } from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

// ------------- AXIOS -------------
import axios from "../../../axios-usercharges";

// ------------- LUXON -------------
import { DateTime } from "luxon";
import DateFnsUtils from "@date-io/luxon";
import moment from "moment";

// ------------- COMPONENTS -------------
import FlightMapModal from "../../fliteROUTE/FlightMapModal";
import FlightValidationTable from "./FlightValidation/FlightValidationTable";
import VarianceDetailsTableRevised from "./VarianceDetails/VarianceDetailsTableRevised";

// ------------- CONTEXT -----------
import { biginAuthContext } from "../../BiginContext/BiginContext";

const useStyles = makeStyles({
    title: {
        marginTop: "15px",
        backgroundColor: "#180aa2",
        fontSize: "22px",
        padding: "10px 20px",
        fontWeight: "700",
        color: "white",
    },
    heading: {
        marginTop: "15px",
        backgroundColor: "#180aa2",
        fontSize: "22px",
        padding: "5px 20px",
        color: "white",
        width: "fit-content",
    },
    formLabel: {
        fontSize: "18px"
    },
    formField: {
        fontSize: "18px",
        marginLeft: "50px",
        borderBottom: "2px solid blue",
    },
    formFieldLabelContainer: {
        display: "flex",
        alignItems: "center",
    }
});


const DataDisplayTable = (props) => {
    // ------------- MUI STYLES  -------------
    const classes=useStyles();

    const {
        getAccessToken
      } = useContext(biginAuthContext)
    
    // ------------- DESCTRUCTURING PROPS  -------------
    const {monthYearData, extractaData, disputesData, setDisputesData, anspData, month, year} = props;

    // ------------- REFS -------------
    const GreatCircleRef = React.createRef();

    // ------------- STATES  -------------   
    // --- TABLE PAGINATION --- 
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // --- DATA MANAGEMENT ---
    const [slicedData, setSlicedData] = useState(
        monthYearData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
            )
        );
    const [filteredData, setFilteredData] = useState(monthYearData);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedFlightInfo, setSelectedFlightInfo] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    
    // --- DISPUTE STATES --- 
    const [disputeData, setDisputeData] = useState({});
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [amount, setAmount] = useState("");
            
    // --- MODAL STATES ---
    const [openModal, setOpenModal] = useState(false);
    const [mapModalOpen, setMapModalOpen] = useState(false);

 
    
    // --- Auditor notes states ---
    const [auditorNotepad, setAuditorNotepad] = useState("");

    // --- OCR Filter States ---
    const [flightMismatchFilter, setFlightMismatchFilter] = useState(false);
    const [MTOWMismatchFilter, setMTOWMismatchFilter] = useState(false);
    const [chargeMismatchFilter, setChargeMismatchFilter] = useState(false);
    const [dateFilter, setDateFilter] = useState("");
    const [originFilter, setOriginFilter] = useState("");
    const [destinationFilter, setDestinationFilter] = useState("");

    // Fetch the user info from the api
    const [userRes, userError, userIsLoading, fetchUser] = useFetch(
        {},
        {
            method: "get",
            url: "/api/user",
        }
    );

    // const createDispute = async (item) => {
    //     const date = new Date(item.date);
    //     const year = date.getFullYear();
    //     const month = date.getMonth() + 1;

    //     const formattedMonthYear = `${year} ${month
    //         .toString()
    //         .padStart(2, "0")}`;
    //     const dispute_reason = selectedReasons.join(", ");
    //     const disputeData = {
    //         flight_ref: item.betaInvoiceFlihtID || "",
    //         flight_number: item.flight_ref || "0",
    //         disputed_amount: parseFloat(amount).toFixed(2),
    //         dispute_reason,
    //         month_year: formattedMonthYear || "-",
    //         date: item.date,
    //     };

    //     await axios
    //         .post("/api/dispute", disputeData)
    //         .then((res) => {
    //             if (res.status == 202) {
    //                 setSnackbar({
    //                     open: true,
    //                     message: res.data.message,
    //                     severity: "info",
    //                 });
    //             } else {
    //                 setSnackbar({
    //                     open: true,
    //                     message: res.data.message,
    //                     severity: "success",
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             setSnackbar({
    //                 open: true,
    //                 message: "Error creating dispute",
    //                 severity: "error",
    //             });
    //         });

    //     // try {
    //     //   const response = await axios.post("/api/dispute", disputeData);
    //     //   if (response.status === 200) {
    //     //     console.log("Dispute Added Successfully");
    //     //     setSnackbar({
    //     //       open: true,
    //     //       message: "Dispute Added Successfully",
    //     //       severity: "success",
    //     //     });
    //     //   } else {
    //     //     console.error("Failed to add dispute:", response.data.message);
    //     //     setSnackbar({
    //     //       open: true,
    //     //       message: "Already Claimed",
    //     //       severity: "error",
    //     //     });
    //     //   }
    //     // } catch (error) {
    //     //   console.error("Error creating dispute:", error);
    //     //   setSnackbar({
    //     //     open: true,
    //     //     message: "Error creating dispute",
    //     //     severity: "error",
    //     //   });
    //     // }
    // };

    // ------------- FUNCTIONS  -------------
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Function to handle opening of claims modal
    const handleClaimsOpen = (item) => {
        setSelectedItem(item);
        if (!item.betaInvoiceFlihtRef) {
            setSelectedReasons([...selectedReasons, "Flight did not operate"]);
            setAmount(parseInt(item.charge));
        } else if (item.betaInvoiceFlihtRef) {
            if (item.destination != item.end) {
                setSelectedReasons([
                    ...selectedReasons,
                    "Incorrect Destination airport",
                ]);
            }
            if (item.origin != item.start) {
                setSelectedReasons([
                    ...selectedReasons,
                    "Incorrect Origin airport",
                ]);
            }
            if (item.betaInvoiceMtow != item.mtow) {
                setSelectedReasons([...selectedReasons, "Incorrect MTOW"]);
            }
            if (item.BetaInvoiceCharge != item.charge) {
                setSelectedReasons([...selectedReasons, "Incorrect Charge"]);
            }
        }
        setOpenModal(true);
    };

    // // Function to handle closing of claims modal
    // const handleClaimsClose = () => {
    //     setOpenModal(false);
    // };

    // const handleOKClick = async (event) => {
    //     event.preventDefault();
    //     setDisputeData({ ...disputeData, reasons: selectedReasons });
    //     setSelectedReasons([]);
    //     setOpenModal(false);
    //     setAmount("");
    //     try {
    //         await createDispute(selectedItem, selectedReasons);
    //     } catch (error) {
    //         console.error("Error creating dispute:", error);
    //     }
    // };

    // const handleCheckboxChange = (reason) => {
    //     if (selectedReasons.includes(reason)) {
    //         setSelectedReasons(selectedReasons.filter((r) => r !== reason));
    //     } else {
    //         setSelectedReasons([...selectedReasons, reason]);
    //     }
    // };

    // const handleAmountChange = (input) => {
    //     if (/^\$?\d*\.?\d*$/.test(input)) {
    //         setAmount(input);
    //     }
    // };
    
    const getAuditorNotes = async () => {
        await axios.get(`/api/auditor_notes/${userRes.id}/${invoiceBackendNmber}`
        ).then((res) => {
            setAuditorNotepad(res.data.notes);
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    // --- TABLE PAGINATION --- 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Disable specific dates to be those only listed in the claim
    const disableFilterDays = (date) => {
        let availableDays = [];
        for (let item of monthYearData) {
            if (
                !availableDays.includes(
                    moment(new Date(item.date)).format("MM/DD/YYYY")
                )
            ) {
                availableDays.push(
                    moment(new Date(item.date)).format("MM/DD/YYYY")
                );
            }
        }
        return !availableDays.includes(
            moment(new Date(date)).add(1, "day").format("MM/DD/YYYY")
        );
    };

    // --- OCR Filter Functions ---
    const handleChargeFilterChange = (event) => {
        setChargeMismatchFilter(event.target.checked);
    };

    const handleMTOWFilterChange = (event) => {
        setMTOWMismatchFilter(event.target.checked);
    };

    const handleFlightMismatchFilter = (event) => {
        setFlightMismatchFilter(event.target.checked);
    };

    const handleDateFilterChange = (date) => {
        setDateFilter(date);
    };

    const handleOriginFilterChange = (event) => {
        setOriginFilter(event.target.value);
    };

    const handleDestinationFilterChange = (event) => {
        setDestinationFilter(event.target.value);
    };

    // --- OCR Filters ---
    useEffect(() => {
        // Functions used to filter the data displayed in the table
        let newData = monthYearData;
        if (flightMismatchFilter) {
            newData = newData.filter((item) => {
                return (
                    item.date !== item.betaInvoiceEtd ||
                    item.flight_ref !== item.betaInvoiceFlihtRef ||
                    item.origin !== item.start ||
                    item.destination !== item.end
                );
            });
        }

        if (MTOWMismatchFilter) {
            newData = newData.filter((item) => {
                return item.mtow !== (item.betaInvoiceMtow || 0);
            });
        }

        if (chargeMismatchFilter) {
            newData = newData.filter((item) => {
                return item.charge !== item.BetaInvoiceCharge;
            });
        }

        if (originFilter) {
            newData = newData.filter((item) => {
                return item.origin
                    .toLowerCase()
                    .includes(originFilter.toLowerCase());
            });
        }

        if (destinationFilter) {
            newData = newData.filter((item) => {
                return item.destination
                    .toLowerCase()
                    .includes(destinationFilter.toLowerCase());
            });
        }

        if (dateFilter) {
            newData = newData.filter((item) => {
                return (
                    new Date(item.date).toLocaleDateString("en-us") ===
                    new Date(dateFilter.ts).toLocaleDateString("en-us")
                );
            });
        }

        // Set the sliced data again based off of new data
        setFilteredData(newData);
        setSlicedData(
            newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        );
        setPage(0);
    }, [
        flightMismatchFilter,
        MTOWMismatchFilter,
        chargeMismatchFilter,
        destinationFilter,
        originFilter,
        dateFilter,
    ]);
    
    // Set up sliced data if the page has changed or rows per page has changed
    useEffect(() => {
        setSlicedData(
            filteredData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            )
        );
    }, [page, rowsPerPage]);

    useEffect(() => {
        getAuditorNotes();
    }, [userRes]);

   

    const backendFlight = () => {
        const flights = monthYearData.filter((ele) => {
            if (ele.betaInvoiceFlihtRef) {
                return ele;
            }
        });
        return flights.length;
    };

    const invoiceFlights = () => {
        return monthYearData.length;
    };

    const totalBackendFlights = backendFlight();
    const totalInvoiceFlights = invoiceFlights();
    const backendCharge = () => {
        let cost = 0;
        monthYearData.map((ele) => {
            if (ele.BetaInvoiceCharge) {
                cost += parseInt(ele.BetaInvoiceCharge);
            }
        });
        return cost;
    };

    const invoiceCharge = () => {
        let cost = 0;
        monthYearData.map((ele) => {
            if (ele.charge) {
                cost += parseInt(ele.charge);
            }
        });
        return cost;
    };

    const totalBackendCharge = backendCharge();
    const totalInvoiceCharge = invoiceCharge();

    const notOperatedFlights = () => {
        const flight = totalInvoiceFlights - totalBackendFlights;
        return flight;
    };

    const mismatchChargeFligth = () => {
        const flight = monthYearData.filter((ele) => {
            if (ele.BetaInvoiceCharge) {
                if (parseInt(ele.charge) !== parseInt(ele.BetaInvoiceCharge)) {
                    return ele;
                }
            }
        });
        return flight.length;
    };

    const invoiceNumber = () => {
        const flight = extractaData.find((ele) => {
            return ele.field_name === "Invoice Number"
        });
        return flight.field_value;
    };

    const invoiceDate = () => {
        const flight = extractaData.find((ele) => {
            return ele.field_name === "Invoice Date"
        });
        return flight.field_value;
    };

    const invoiceBackendNmber = invoiceNumber();
    const invoiceBackendDate = invoiceDate();

    const totalNotOperatedFlights = notOperatedFlights();
    const totalChargeMismatchFlights = mismatchChargeFligth();

    useEffect(() => {
        axios.post(`api/bigin/addinvoice`, {
            ansp: anspData.ansp,
            anspEmail: anspData.credit_email,
            closingDate: invoiceBackendDate,
            charge: totalInvoiceCharge,
            month: month,
            year: year,
            accessToken: getAccessToken(),
            subPipelineName: "Invoices",
            subPipelineStage: "Invoices Received"
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error)
        });

        axios.post(`api/bigin/addinvoice`, {
            ansp: anspData.ansp,
            anspEmail: anspData.credit_email,
            closingDate: invoiceBackendDate,
            charge: totalInvoiceCharge,
            month: month,
            year: year,
            accessToken: getAccessToken(),
            subPipelineName: "Audit",
            subPipelineStage: "Inventory"
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error)
        });
      }, [])

     if (!Array.isArray(monthYearData)) {
        return <div>No data available</div>;
    }

    const saveAuditorNotes = async () =>  {
        if(!userRes.id) {
            setSnackbar({
                open: true,
                message: "You must be logged in to continue",
                severity: "error"
            });
            return;
        } 
        await axios
            .post("/api/auditor_notes", 
                    {
                        notes: auditorNotepad,
                        inv_number: invoiceBackendNmber,
                        user_id: userRes.id
                    }
                )
            .then((res) => {
                if (res.status == 202) {
                    setSnackbar({
                        open: true,
                        message: res.data.message,
                        severity: "info",
                    });
                } else {
                    setSnackbar({
                        open: true,
                        message: res.data.message,
                        severity: "success",
                    });
                }
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: "Error creating notes",
                    severity: "error",
                });
            });
    }

    const handleTrackIcon = (event, tailNumber) => {
        const flight_ref = event;
        axios.get(`/api/ocr/track/${flight_ref}`).then((res) => {
            let flightInfo = res.data;
            const atcCost = Number(
                flightInfo[0].firCalculatedInfo
                    .map((fc) => fc.commonUsdCost)
                    .reduce((acc, curr) => acc + curr)
            );
            const totalCost = atcCost;

            // return { ...flightInfo, atcCost, totalCosts }

            flightInfo = { ...flightInfo, atcCost, totalCost };

            //  const derivedInfo = getFlightInfoPlusDerived(flightInfo);
            //  console.log(derivedInfo[0])
            const {
                flight_ref,
                mtow,
                departure,
                arrival,
                firCalculatedInfo,
                etd,
                eta,
                flightNumber,
                aircraftModel,
                legacy_format,
            } = flightInfo[0];
            const atcCosts = flightInfo.atcCost;
            const totalCosts = totalCost;
            setSelectedDate(DateTime.fromISO(flightInfo[0].etd));
            // console.log(selectedDate)
            const selectedInfo = {
                atcCosts,
                totalCosts,
                flight_ref,
                mtow,
                departure,
                arrival,
                firCalculatedInfo,
                etd,
                eta,
                flightNumber,
                aircraftModel,
                legacy_format,
                tailNumber
            };
            console.log("selectedInfoSelectedInfo", selectedInfo);
            // console.log(derivedInfo[0].flight_ref)
            axios
                .get(`/api/ocr/geometry/${flightInfo[0].flight_ref}`)
                .then((res) => {
                    const firRegionsPointData = res.data;

                    let getWayPoints = [];
                    axios
                        .get(`/api/ocr/waypoints/${flightInfo[0].flight_ref}`)
                        .then((res) => {
                            getWayPoints = res.data;

                            const firInfoWithPoints = firCalculatedInfo.map(
                                (f, i) => ({
                                    ...f,
                                    geometry: firRegionsPointData[i],
                                    wayPointsC: getWayPoints,
                                })
                            );

                            const gcData = {
                                type: "FeatureCollection",
                                features: firInfoWithPoints,
                            };

                            setSelectedFlightInfo({ gcData, ...selectedInfo });
                            //              setSelectedDate(DateTime.fromISO(selectedFlightInfo.gcData.etd))
                            //  console.log(selectedDate)

                            setMapModalOpen(true);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };

    return (
        <div style={{ marginTop: "0" }}>
            {/* <Modal
                open={openModal}
                onClose={handleClaimsClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "24px",
                        borderColor: "blue",
                    }}
                >
                    <Typography>
                        <b>Reason to claim:</b>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedReasons.includes(
                                        "Incorrect MTOW"
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange("Incorrect MTOW")
                                    }
                                />
                            }
                            label="Incorrect MTOW"
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedReasons.includes(
                                        "Flight did not operate"
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            "Flight did not operate"
                                        )
                                    }
                                />
                            }
                            label="Flight did not operate"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedReasons.includes(
                                        "Incorrect Origin airport"
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            "Incorrect Origin airport"
                                        )
                                    }
                                />
                            }
                            label="Incorrect Origin airport"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedReasons.includes(
                                        "Incorrect Destination airport"
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            "Incorrect Destination airport"
                                        )
                                    }
                                />
                            }
                            label="Incorrect Destination airport"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedReasons.includes(
                                        "Incorrect Charge"
                                    )}
                                    onChange={() =>
                                        handleCheckboxChange("Incorrect Charge")
                                    }
                                />
                            }
                            label="Incorrect Charge"
                        />
                        <h3>Claiming Amount</h3>
                        <TextField
                            label="Amount (in USD)"
                            value={amount}
                            onChange={(event) => {
                                const newValue = event.target.value;
                                handleAmountChange(newValue);
                            }}
                            variant="outlined"
                            fullWidth
                            placeholder="$0.00"
                        />
                    </Typography>
                    <Button onClick={handleOKClick}>Submit</Button>
                </Box>
            </Modal> */}

            {mapModalOpen && (
                <FlightMapModal
                    GreatCircleRef={GreatCircleRef}
                    setModalOpen={setMapModalOpen}
                    selectedFlightInfo={selectedFlightInfo}
                    selectedDate={selectedDate}
                />
            )}

            <>
                <div style={{ marginBottom: "20px" }}>
                    <div>
                        <div
                            style={{
                                padding: "0px 0px",
                                width: "95%",
                                margin: "auto",
                            }}
                        >
                            <div className={classes.title}>
                                <span>Air Navigation Audit Checklist</span>
                            </div>
                            <div className={classes.heading}>
                                <span>Invoice Details</span>
                            </div>
                            <div
                                style={{
                                    marginTop: "15px",
                                    padding: "25px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className={classes.formFieldLabelContainer}>
                                        <span className={classes.formLabel}>
                                            Country/ANSP:
                                        </span>
                                        <span className={classes.formField}>
                                            {anspData.ansp}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "4px solid black",
                                            padding: "5px",
                                        }}
                                    >
                                        <span style={{ fontSize: "18px" }}>
                                            Invoice Total
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "18px",
                                                marginLeft: "50px",
                                            }}
                                        >
                                            {totalInvoiceCharge.toLocaleString()}{" "}
                                            USD
                                        </span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: "20px",
                                    }}
                                >
                                    <div className={classes.formFieldLabelContainer}>
                                        <span className={classes.formLabel}>
                                            Inv. Number:
                                        </span>
                                        <span className={classes.formField}>
                                            {invoiceBackendNmber}
                                        </span>
                                    </div>
                                    <div className={classes.formFieldLabelContainer}>
                                        <span className={classes.formLabel}>
                                            Period:
                                        </span>
                                        <span className={classes.formField}>
                                            3/1/2023 - 3/31/2023
                                        </span>
                                    </div>

                                    <div className={classes.formFieldLabelContainer}>
                                        <span className={classes.formLabel}>
                                            Invoice Date:
                                        </span>
                                        <span className={classes.formField}>
                                            {invoiceBackendDate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*Flight Validation Section*/}
                            <div className={classes.heading}>
                                <span>Flight Validation</span>
                            </div>
                            <FlightValidationTable
                                totalInvoiceCharge={totalInvoiceCharge}
                                totalInvoiceFlights={totalInvoiceFlights}
                                totalBackendCharge={totalBackendCharge}
                                totalBackendFlights={totalBackendFlights}
                                totalNotOperatedFlights={totalNotOperatedFlights}
                            />
                            <div style={{ display: "flex" }}>
                                <div className={classes.heading}>
                                    <span>Auditor Notes</span>
                                </div>
                                <div
                                    style={{
                                        marginTop: "15px",
                                        backgroundColor: "green",
                                        fontSize: "22px",
                                        padding: "5px 20px",
                                        color: "white",
                                        width: "fit-content",
                                    }}
                                >
                                    <span>
                                        {userRes
                                            ? userRes.username
                                            : "Auditor Name"}
                                    </span>
                                </div>
                            </div>
                            <TextareaAutosize
                                value={auditorNotepad}
                                onChange={(e) =>
                                    setAuditorNotepad(e.target.value)
                                }
                                placeholder="Please input notes here..."
                                style={{
                                    width: "100%",
                                    minWidth: "100%",
                                    maxWidth: "100%",
                                    minHeight: "100px",
                                }}
                            />
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <Button variant="contained"
                                    onClick={saveAuditorNotes
                                }>Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div style={{ width: "95%", margin: "0 auto" }}>
                <div className={classes.heading}>
                    <span>Variance Details</span>
                </div>
                <div style={{ padding: "20px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    placeholder="01/01/2023"
                                    onChange={(e) => handleDateFilterChange(e)}
                                    value={dateFilter || DateTime.utc()}
                                    maxDate={DateTime.utc()}
                                    shouldDisableDate={disableFilterDays}
                                />
                            </MuiPickersUtilsProvider>
                            <Button
                                variant="contained"
                                onClick={() => setDateFilter("")}
                                style={{ marginLeft: "10px" }}
                            >
                                Clear Date
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                        }}
                    >
                        <div style={{ marginTop: "10px" }}>
                            <TextField
                                label="Origin"
                                onChange={(e) => handleOriginFilterChange(e)}
                                style={{ marginRight: "20px" }}
                            />
                            <TextField
                                label="Destination"
                                onChange={(e) =>
                                    handleDestinationFilterChange(e)
                                }
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <FormControlLabel
                                label="Mismatched Flight Data"
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleFlightMismatchFilter(e)
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Mismatched MTOW"
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleMTOWFilterChange(e)
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Mismatched Charge"
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleChargeFilterChange(e)
                                        }
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: "95%", margin: "0 auto"}}>
                <VarianceDetailsTableRevised
                    slicedData={slicedData} 
                    filteredData={filteredData}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPagee={handleChangeRowsPerPage}
                    handleClaimsOpen={handleClaimsOpen}
                    handleTrackIcon={handleTrackIcon}
                    disputesData={disputesData} 
                    setDisputesData={setDisputesData}
                    setSnackbar={setSnackbar}
                    month={month}
                    year={year}
                    anspData={anspData}
                    invoiceBackendDate={invoiceBackendDate}
                />
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DataDisplayTable;
