// ------------- REACT -------------
import React, { useEffect, useState } from "react";

// ------------- MUI -------------
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    Pagination,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Button } from "@mui/base";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";

// ------------- AXIOS -------------
import axios from "../../axios-usercharges";

// ------------- FORMIK -------------
import { useFormik } from "formik";

// ------------- YUP -------------
import * as yup from "yup";

// ------------- IMAGES -------------
import certificateImage from "../../assets/logo/CoA_8100_Sample.png";

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-head": {
            color: "white",
            backgroundColor: "black",
        },
    },
});

const ITEMS_PER_PAGE = 20;

const validationSchema = yup.object({
    registration: yup.string().required("REG-ID is required"),
    mtow_in_tonnes: yup.string().required("MTOW (tonne) is required"),
    aircraft_type: yup.string().required("UA_ARCFT_CD is required"),
    ual_aircraft_desc: yup.string().required("UA_ARCFT_DESCR is required"),
    icao: yup.string().required("ICAO is required"),
    iata: yup.string().required("IATA is required"),
    sch_id: yup.string().required("SCH_CD is required"),
    accnt_id: yup.string().required("ACCNT_ID is required"),
});

function ACTable() {
    const classes = useStyles();
    const [aircraftData, setAircraftData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [aircraftEditData, setAircraftEditData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        getTheAircraftData();
    }, [currentPage]);

    const getTheAircraftData = async () => {
        axios.get(`/api/oms_aircrafts?page=${currentPage}`).then((res) => {
            setAircraftData(res.data.data);
            setTotalCount(res.data.totalCount[0].count);
        });
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleEdit = (row) => {
        formik.setValues({
            registration: row.registration,
            mtow_in_tonnes: row.mtow_in_tonnes,
            aircraft_type: row.aircraft_type,
            ual_aircraft_desc: row.ual_aircraft_desc,
            icao: row.icao,
            iata: row.iata,
            sch_id: row.sch_id,
            accnt_id: row.accnt_id,
        });
        setAircraftEditData(row);
        setIsEdit(true);
        setOpenModal(true);
    };

    const handleDelete = (row) => {
        axios.put(`/api/oms_aircrafts/${row.registration}`).then((res) => {
            setSnackbar({
                open: true,
                message: "Aircraft deleted successfully",
                severity: "success",
            });
            setCurrentPage(1);
            getTheAircraftData();
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleAddAircraft = async (e, id) => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        formik.resetForm();
        setIsEdit(false);
        setOpenModal(false);
    };

    const openCertificate = () => {
        setShowCertificate((prevShowForgotPassword) => !prevShowForgotPassword);
    };

    const formik = useFormik({
        initialValues: {
            registration: aircraftEditData?.registration
                ? aircraftEditData.registration
                : "",
            mtow_in_tonnes: aircraftEditData?.mtow_in_tonnes
                ? aircraftEditData.mtow_in_tonnes
                : "",
            aircraft_type: aircraftEditData?.aircraft_type
                ? aircraftEditData.aircraft_type
                : "",
            ual_aircraft_desc: aircraftEditData?.ual_aircraft_desc
                ? aircraftEditData.ual_aircraft_desc
                : "",
            icao: aircraftEditData?.icao ? aircraftEditData.icao : "",
            iata: aircraftEditData?.iata ? aircraftEditData.iata : "",
            sch_id: aircraftEditData?.sch_id ? aircraftEditData.sch_id : "",
            accnt_id: aircraftEditData?.accnt_id
                ? aircraftEditData.accnt_id
                : "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.isEdit = isEdit;
            if (isEdit) {
                values.id = aircraftEditData.id;
            }
            await axios
                .post(`/api/oms_aircrafts`, values)
                .then((res) => {
                    console.log("resresresres", res);
                    setSnackbar({
                        open: true,
                        message: res.data.message,
                        severity: "success",
                    });
                })
                .catch((error) => {
                    setSnackbar({
                        open: true,
                        message: "Failed to Create Aircraft",
                        severity: "error",
                    });
                });
            setCurrentPage(1);
            setIsEdit(false);
            getTheAircraftData();
            formik.resetForm();
            handleCloseModal();
        },
    });

    return (
        <>
            <div style={{ width: "95%", margin: "auto", paddingTop: "30px" }}>
                <div
                    style={{
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        onClick={(e) => handleAddAircraft(e)}
                        variant="contained"
                        style={{
                            backgroundColor: "#180aa2",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            fontSize: "16px",
                            padding: "10px",
                            borderRadius: "5px",
                            border:"none"
                        }}
                    >
                        Add Aircraft
                        <AddCircleSharpIcon />
                    </Button>
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.root}>
                                <TableCell align="center">
                                    <span>CERTIFICATE</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>REG-ID</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>UA_ARCFT_CD</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>ICAO</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>MTOW (kg-tonne)</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>UA_ARCFT_DESCR</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>IATA</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>SCH_CD</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>ACCNT_ID</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>Actions</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {aircraftData.length > 0 &&
                                aircraftData.map((row) => (
                                    <TableRow key={row.registration}>
                                        <TableCell align="center">
                                            <PreviewRoundedIcon
                                                onClick={openCertificate}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.registration}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.aircraft_type}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.icao}
                                        </TableCell>
                                        <TableCell align="center">
                                            {parseFloat(
                                                row.mtow_in_tonnes
                                            ).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.ual_aircraft_desc}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.iata}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.sch_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.accnt_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ModeEditOutlineSharpIcon
                                                color="primary"
                                                onClick={() => handleEdit(row)}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            />
                                            <DeleteForeverSharpIcon
                                                style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    handleDelete(row)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        style={{
                            margin: "15px 0",
                        }}
                        count={Math.ceil(totalCount / ITEMS_PER_PAGE)}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        variant="outlined"
                        shape="rounded"
                    />
                </TableContainer>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbar.severity}
                    >
                        <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                        >
                            {snackbar.message}
                        </Typography>
                    </Alert>
                </Snackbar>
                {openModal ? (
                    <Dialog
                        open={openModal}
                        onClose={handleCloseModal}
                        fullWidth
                    >
                        <DialogTitle>
                            {aircraftEditData ? "Update" : "Add"} Aircraft
                        </DialogTitle>
                        <form onSubmit={formik.handleSubmit}>
                            <DialogContent>
                                <TextField
                                    label="REG-ID"
                                    name="registration"
                                    value={formik.values.registration}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.registration &&
                                        Boolean(formik.errors.registration)
                                    }
                                    helperText={
                                        formik.touched.registration &&
                                        formik.errors.registration
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="MTOW (kg-tonne)"
                                    name="mtow_in_tonnes"
                                    value={formik.values.mtow_in_tonnes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.mtow_in_tonnes &&
                                        Boolean(formik.errors.mtow_in_tonnes)
                                    }
                                    helperText={
                                        formik.touched.mtow_in_tonnes &&
                                        formik.errors.mtow_in_tonnes
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="UA_ARCFT_CD"
                                    name="aircraft_type"
                                    value={formik.values.aircraft_type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.aircraft_type &&
                                        Boolean(formik.errors.aircraft_type)
                                    }
                                    helperText={
                                        formik.touched.aircraft_type &&
                                        formik.errors.aircraft_type
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="UA_ARCFT_DESCR"
                                    name="ual_aircraft_desc"
                                    value={formik.values.ual_aircraft_desc}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.ual_aircraft_desc &&
                                        Boolean(formik.errors.ual_aircraft_desc)
                                    }
                                    helperText={
                                        formik.touched.ual_aircraft_desc &&
                                        formik.errors.ual_aircraft_desc
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="ICAO"
                                    name="icao"
                                    value={formik.values.icao}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.icao &&
                                        Boolean(formik.errors.icao)
                                    }
                                    helperText={
                                        formik.touched.icao &&
                                        formik.errors.icao
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="IATA"
                                    name="iata"
                                    value={formik.values.iata}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.iata &&
                                        Boolean(formik.errors.iata)
                                    }
                                    helperText={
                                        formik.touched.iata &&
                                        formik.errors.iata
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="SCH_CD"
                                    name="sch_id"
                                    value={formik.values.sch_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.sch_id &&
                                        Boolean(formik.errors.sch_id)
                                    }
                                    helperText={
                                        formik.touched.sch_id &&
                                        formik.errors.sch_id
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="ACCNT_ID"
                                    name="accnt_id"
                                    value={formik.values.accnt_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.accnt_id &&
                                        Boolean(formik.errors.accnt_id)
                                    }
                                    helperText={
                                        formik.touched.accnt_id &&
                                        formik.errors.accnt_id
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseModal}
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {aircraftEditData ? "Update " : "Add "}
                                    Aircraft
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                ) : null}
                <Dialog open={showCertificate} onClose={openCertificate}>
                    <img
                        src={certificateImage}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                </Dialog>
            </div>
        </>
    );
}

export default ACTable;