// ------------- REACT -------------
import React, { useState } from 'react';

// ------------- MUI -------------
import { Box, Tabs, Tab, Button, TextField } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';

// ------------- COMPONENTS -------------
import Navbar from '../navbar/Navbar';
import ContactTableRow from './ContactTable/ContactTableRow'; 
import GroupTable from './GroupTable/GroupTable';
import SortSelector from './Icons/SortSelector';

// ------------- SAMPLE DATA -------------
const testContacts = [
  {
    name: "You",
    email: "jamaica@flitegains.com",
    billableRate: 0,
    role: "Owner",
    group: "Admin"
  },
  {
    name: "",
    email: "dominica@flitegains.com",
    billableRate: 0,
    role: "",
    group: "Dev Group"
  }
];


function Contacts() {
  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // List of contact table rows to be displayed on page
  const contactTableRows = testContacts.map((contact, index) => {
    return <ContactTableRow
      key={index}
      name={contact.name}
      email={contact.email}
      billableRate={contact.billableRate}
      role={contact.role}
      group={contact.group}
    />
  })

  // Get a list of the available groups
  const getGroupList = () => {
    let newGroups = [];
    for(let contact of testContacts) {
      if(!newGroups.includes(contact.group)){
        newGroups.push(contact.group);
      }
    }
    return newGroups;
  }

  // Set up group tables based off of available groups
  const groupTables = getGroupList().map((group, index) => 
    <GroupTable 
      key={index}
      group={group}
      contacts={testContacts.filter(contact => group === contact.group)}
    />
  )

  return (
    <>
      <Navbar index={11} />
      <h3 style={{padding: "40px 60px", color: "grey"}}>Team</h3>
      <Box sx={{ typography: 'body1'}} style={{width: "95%", margin: "auto"}}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="contact tabs">
              <Tab label="Members" value="1" style={{backgroundColor: tabValue === 1 ? '#f6f6f6' : '#f8f8f8'}} />
              <Tab label="Groups" value="2" style={{backgroundColor: tabValue === 1 ? '#f6f6f6' : '#f8f8f8'}} />
              <Tab label="Reminders" value="3" style={{backgroundColor: tabValue === 1 ? '#f6f6f6' : '#f8f8f8'}}/>
            </Tabs>
          </Box>
          <TabPanel value="1" style={{backgroundColor: "#f6f6f6"}}>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <Button variant="outlined" size="large">Show All</Button>
                  <TextField label='search by name or email' variant="outlined" size="small" style={{width:"400px"}} ></TextField>
                </div>
                <div>
                  <Button variant="contained" size="large" color="primary">ADD NEW MEMBER</Button>
                </div>
              </div>
              <h4 style={{backgroundColor: "lightgrey", color: "grey", margin: "0", padding: "10px 20px"}}>Members</h4>
              <table style={{width: "100%", borderCollapse: "collapse"}}>
                <thead >
                  <tr style={{border: "1px solid lightgrey", color: "grey"}}>
                    <th style={{padding: "10px", width: "20%"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <SortSelector/>
                        <span>NAME</span>
                      </div>
                    </th>
                    <th style={{padding: "10px", width: "25%"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <SortSelector/>
                        <span>EMAIL</span>
                      </div>  
                    </th>
                    <th style={{padding: "10px", width: "20%"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <SortSelector/>
                        <span>BILLABLE RATE</span>
                      </div>  
                    </th>
                    <th style={{padding: "10px", width: "15%"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <SortSelector/>
                        <span>ROLE</span>
                      </div>  
                    </th>
                    <th style={{padding: "10px", width: "15%"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <SortSelector/>
                        <span>GROUP</span>
                      </div>  
                    </th>
                    <th style={{padding: "10px", width: "5%"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {contactTableRows}
                </tbody>
              </table>
          </TabPanel>
          <TabPanel value="2" style={{backgroundColor: "#f6f6f6"}}>
            {groupTables}
          </TabPanel>
          <TabPanel value="3" style={{backgroundColor: "#f6f6f6"}}>
            <h4 style={{backgroundColor: "lightgrey", color: "grey", margin: "0", padding: "10px 20px"}}>Reminders</h4>

          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default Contacts;