// ------------- REACT -------------
import React, {useState} from "react";

// ------------- MUI -------------
import { Icon, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

// ------------- LUXON -------------
import { DateTime } from "luxon";
import DateFnsUtils from '@date-io/luxon';

// ------------- LOGOS -------------
import docusignImage from "../../assets/logo/docusign_logo.png";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";

// ------------- CREATE MUI STYLES -------------
const useStyles = makeStyles({
    title: {
        marginTop: '15px',
        backgroundColor: '#180aa2',
        fontSize: '22px',
        padding: '10px 20px',
        fontWeight: '700',
        color: 'white'
    },
    heading: {
        marginTop: '15px',
        backgroundColor: '#180aa2',
        fontSize: '22px',
        padding: '5px 20px',
        color: 'white',
        width: 'fit-content' 
    },
    largeFormContainer: {
        marginTop: '15px',
        padding: '0 25px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    largeFormField: {
        marginLeft: '50px',
        borderBottom: '2px solid blue', 
        width: "800px"
    },
    signatureContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'end'
    },
    signatureLabelContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    signatureLabelBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
    },
    signatureOuterContainer: {
        marginTop: '15px',
        padding: '10px 25px',
        display: 'flex',
        justifyContent: 'baseline',
        alignItems: 'end',
        gap: "50px"
    }
});

function EFTR () {
    // ------------- MUI STYLES -------------
    const classes=useStyles();
    
    // ------------- STATES -------------
    const [wireDate, setWireDate] = useState(new Date());
    const [dateSubmitted, setDateSubmitted] = useState(new Date());


    return (
        <>
        <Navbar index={9}/>
        <div style={{width:"95%", margin: "0 auto", paddingTop: "30px"}}>
                <div className={classes.title}>
                    <span>Electronic Funds Transfer Request</span>
                </div>
                <div className={classes.heading}>
                    <span>Wire Information</span>
                </div>
                <div
                    style={{
                        marginTop: '15px',
                        padding: '0 25px',
                        
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: "50px"
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px',width: "150px" }}>Wire Date:</span>
                            <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                    autoOk
                                    placeholder="01/01/2023"
                                    onChange={(e) => setWireDate(e)}
                                    value={wireDate}
                                    maxDate={DateTime.utc()}
                                    />
                                </MuiPickersUtilsProvider>
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px',width: "150px" }}>Repetitive Line #:</span>
                            <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} style={{ marginLeft: '50px', borderBottom: '2px solid blue',width: "485px"}}></TextField>
                        </div>
                    </div> 
                    
                </div> 
                <div className={classes.largeFormContainer}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px',width: "150px" }}>Wire Amount:</span>
                            <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} style={{ marginLeft: '50px', borderBottom: '2px solid blue', width: "300px"}}></TextField>
                        </div>
                    </div> 
                <div
                    style={{
                        marginTop: '15px',
                        padding: '10px 25px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px' }}>Payment Currency:</span>
                            <span style={{ fontSize: '18px', marginLeft: '50px', textAlign:"center", width: "300px"}}>USD</span>
                        </div>
                    </div> 
                    
                </div>
                <div className={classes.heading}>
                    <span>Cash Forecast: [Must be completed for expenditures great than $1mm]</span>
                </div>  
                <div className={classes.heading}>
                    <span>Bank Information</span>
                </div>                
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Bene: Bank ABA/CHIPS/SWIFT:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Beneficiary Bank Name:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Bank Address:</span>
                    <TextField variant="outlined" placeholder="Optional Field" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Benficiary Account #:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div>
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Benficiary Account Name:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Benficiary Address:</span>
                    <TextField variant="outlined" placeholder="Optional Field" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Reference:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.heading}>
                    <span>Further Credit Bank Information (if applicable)</span>
                </div>        
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Further Credit Account #:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Further Credit Account Name:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.heading}>
                    <span>Authorization Information</span>
                </div>   
                <div className={classes.signatureOuterContainer}>
                    <div className={classes.signatureContainer}>
                        <span style={{ fontSize: '18px',width: "200px", textAlign: "start"}}>Approval Signature:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "300px"}}>
                            <Icon>
                                <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                            </Icon>
                        </span>
                    </div>
                    <div className={classes.signatureLabelBoxContainer}>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Name:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Phil Middleton</span>
                        </div>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Title/Emp #:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Dir, Ops Finance/295836</span>
                        </div>
                    </div>
                </div> 
                <div className={classes.signatureOuterContainer}>
                    <div className={classes.signatureContainer}>
                        <span style={{ fontSize: '18px',width: "200px", textAlign: "start"}}>Approval Signature:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "300px"}}>
                            <Icon>
                                <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                            </Icon>
                        </span>
                    </div>
                    <div className={classes.signatureLabelBoxContainer}>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Name:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Mike Stills</span>
                        </div>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Title/Emp #:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Dir, NOC Flight Dispatch/101262</span>
                        </div>
                    </div>
                    
                </div> 
                <div className={classes.signatureOuterContainer}>
                    <div className={classes.signatureContainer}>
                        <span style={{ fontSize: '18px',width: "200px", textAlign: "start"}}>Approval Signature:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "300px"}}>
                            <Icon>
                                <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                            </Icon>
                        </span>
                    </div>
                    <div className={classes.signatureLabelBoxContainer}>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Name:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Jim DeYoung</span>
                        </div>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Title/Emp #:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>MD, Network Operations/259860</span>
                        </div>
                    </div>
                    
                </div> 
                <div className={classes.signatureOuterContainer}>
                    <div className={classes.signatureContainer}>
                        <span style={{ fontSize: '18px',width: "200px", textAlign: "start"}}>Approval Signature:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "300px"}}>
                            <Icon>
                                <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                            </Icon>
                        </span>
                    </div>
                    <div className={classes.signatureLabelBoxContainer}>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Name:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Brian Billick</span>
                        </div>
                        <div className={classes.signatureLabelContainer}>
                            <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Title/Emp #:</span>
                            <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', border: '2px solid blue', width: "350px", textAlign: "center"}}>Sr Mgr, Ops Finance/305715</span>
                        </div>
                    </div>
                </div> 
                <div className={classes.heading}>
                    <span>Contact Information</span>
                </div>  
                <div
                    style={{
                        marginTop: '15px',
                        padding: '20px 25px',
                        display: 'flex',
                        justifyContent: 'baseline',
                        alignItems: 'end',
                        gap: "50px"
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'end'
                        }}
                        >
                        <span style={{ fontSize: '18px',width: "200px", textAlign: "start"}}>Contact Information:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "300px", textAlign: "center"}}>Christopher Esposito</span>
                    </div>  
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'end'
                        }}
                        >
                        <span style={{ fontSize: '18px',width: "150px", textAlign: "start"}}>Phone:</span>
                        <span variant="outlined" style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue', width: "350px", textAlign: "center"}}>(872) 825-0717</span>
                    </div>  
                </div> 
                <div className={classes.heading}>
                    <span>General Ledger Accounting Information (if multiple accounts attach acocunting entries as support and enter "See Attached")</span>
                </div>  
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>34-digit Oracle GL Code:</span>
                    <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div className={classes.heading}>
                    <span>Miscellaneous Information</span>
                </div>  
                <div className={classes.largeFormContainer}>
                    <span style={{ fontSize: '18px',width: "300px", textAlign: "start"}}>Reason For Payment:</span>
                    <TextField variant="outlined"  inputProps={{style: {fontSize: 18}}} className={classes.largeFormField}></TextField>
                </div> 
                <div
                    style={{
                        marginTop: '15px',
                        padding: '10px 25px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: "50px"
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px',width: "150px" }}>Date Submitted:</span>
                            <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                    autoOk
                                    placeholder="01/01/2023"
                                    onChange={(e) => setDateSubmitted(e)}
                                    value={dateSubmitted}
                                    maxDate={DateTime.utc()}
                                    />
                                </MuiPickersUtilsProvider>
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontSize: '18px',width: "250px" }}>Wire Number (for A/P use):</span>
                            <TextField variant="outlined" inputProps={{style: {fontSize: 18}}} style={{marginLeft: '50px', borderBottom: '2px solid blue',width: "385px"}}></TextField>
                        </div>
                    </div> 
                    
                </div> 
            </div>
        </>
    )
}

export default EFTR;