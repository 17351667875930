import * as jsPDF from "jspdf";
import { getChildProp } from "./util";
import IATALogo from "../assets/logo/new_flitex_logo.jpg";

/*
 node -> table element,
 data -> arraw of values
 keys -> sequece of header in form keys
*/
export function generatePDF(
    node,
    title,
    data,
    keys,
    currency,
    amount,
    filename,
    afterTableCreatedCallback,
    formatters
) {
    const doc = new jsPDF();
    const PDF_MAX_HEIGHT = 272.6;
    const fonts = Object.keys(doc.getFontList());
    // reduce the value to reduce the font size
    const reduceFontFactor = 0.8;
    const headers = [];
    const templateRows = [];
    for (let i = 0, rowCount = 0; i < node.rows.length && rowCount < 2; i++) {
        if (node.rows[i].querySelector("th")) {
            headers.push(
                parseRowContent(
                    fonts,
                    doc.internal.scaleFactor,
                    window,
                    node.rows[i],
                    false,
                    true,
                    reduceFontFactor
                )
            );
        } else {
            templateRows.push(
                parseRowContent(
                    fonts,
                    doc.internal.scaleFactor,
                    window,
                    node.rows[i],
                    false,
                    true,
                    reduceFontFactor
                )
            );
            rowCount++;
        }
    }
    console.log("templateRows", templateRows);
    const numberOfCells = templateRows[0].length;
    let resultRows = [];
    resultRows = data.map((charge, index) => {
        let template = [...templateRows[index % 2]];
        return template.map((cell, i) => {
            if (keys[i] !== "stroke") {
                if (formatters && formatters[keys[i]]) {
                    const formatted = getChildProp(
                        formatters,
                        keys[i]
                    )(getChildProp(charge, keys[i]));
                    return {
                        ...cell,
                        content: formatted,
                    };
                }
                return {
                    ...cell,
                    content: getChildProp(charge, keys[i]),
                };
            }
            return {
                ...cell,
                content: "-",
                styles: {
                    ...cell.styles,
                    textColor: getChildProp(charge, keys[i]),
                    cellWidth: 14,
                    cellPadding: 0,
                    fontStyle: "bold",
                    fontSize: 20,
                },
            };
        });
    });
    if (currency && amount) {
        resultRows.push([
            {
                rowSpan: 1,
                colSpan: numberOfCells - 2,
                styles: {
                    fillColor: [255, 255, 255],
                    cellPadding: {
                        top: 1.0583333333333331,
                        right: 0.79375,
                        bottom: 1.0583333333333331,
                        left: 0.79375,
                    },
                    halign: "left",
                    valign: "middle",
                    fontSize: 10.5,
                },
                _element: "",
                content: "",
            },
            {
                rowSpan: 1,
                colSpan: 1,
                styles: {
                    fillColor: [29, 158, 255],
                    textColor: [255, 255, 255],
                    lineColor: [255, 255, 255],
                    cellPadding: {
                        top: 1.0583333333333331,
                        right: 0.79375,
                        bottom: 1.0583333333333331,
                        left: 0.79375,
                    },
                    halign: "center",
                    valign: "middle",
                    fontSize: 10.5,
                },
                _element: "",
                content: currency,
            },
            {
                rowSpan: 1,
                colSpan: 1,
                styles: {
                    fillColor: [0, 112, 192],
                    textColor: [255, 255, 255],
                    lineColor: [255, 255, 255],
                    cellPadding: {
                        top: 1.0583333333333331,
                        right: 4.2333333333333325,
                        bottom: 1.0583333333333331,
                        left: 0.79375,
                    },
                    halign: "right",
                    valign: "middle",
                    fontSize: 10.5,
                },
                _element: "",
                content: amount,
            },
        ]);
    }

    toDataURL(IATALogo, (dataUrl) => {
        let xPos, yPos, width, margin;
        doc.autoTable({
            head: headers,
            body: resultRows,
            startY: 28,
            useCss: true,
            theme: "striped",
            styles: {
                fontSize: 10,
            },
            showHead: "everyPage",
            margin: {
                top: 30,
                right: 5,
                bottom: 5,
                left: 5,
            },
            didDrawPage: (data) => {
                xPos = data.cursor.x;
                yPos = data.cursor.y;
                width = data.table.width;
                margin = data.settings.margin;
                addLogo(doc, dataUrl, title);
            },
        });
        if (afterTableCreatedCallback) {
            afterTableCreatedCallback(
                doc,
                title,
                xPos,
                yPos,
                width,
                margin,
                PDF_MAX_HEIGHT,
                dataUrl
            );
        } else {
            doc.save(filename);
        }
    });
}

export function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
}

export function parseRowContent(
    supportedFonts,
    scaleFactor,
    window,
    row,
    includeHidden,
    useCss,
    reduceFontFactor
) {
    var resultRow = [];
    for (var i = 0; i < row.cells.length; i++) {
        var cell = row.cells[i];
        var style_1 = window.getComputedStyle(cell);
        if (includeHidden || style_1.display !== "none") {
            var cellStyles = void 0;
            if (useCss) {
                cellStyles = parseCss(
                    supportedFonts,
                    cell,
                    scaleFactor,
                    style_1,
                    window,
                    reduceFontFactor
                );
            }
            resultRow.push({
                rowSpan: cell.rowSpan,
                colSpan: cell.colSpan,
                styles: cellStyles,
                _element: cell,
                content: parseCellContent(cell),
            });
        }
    }
    var style = window.getComputedStyle(row);
    if (resultRow.length > 0 && (includeHidden || style.display !== "none")) {
        return resultRow;
    }
}
function parseCellContent(orgCell) {
    // Work on cloned node to make sure no changes are applied to html table
    var cell = orgCell.cloneNode(true);
    // Remove extra space and line breaks in markup to make it more similar to what
    // would be shown in html
    cell.innerHTML = cell.innerHTML.replace(/\n/g, "").replace(/ +/g, " ");
    // Preserve <br> tags as line breaks in the pdf
    cell.innerHTML = cell.innerHTML
        .split("<br>")
        .map(function (part) {
            return part.trim();
        })
        .join("\n");
    // innerText for ie
    return cell.innerText || cell.textContent || "";
}

function parseCss(
    supportedFonts,
    element,
    scaleFactor,
    style,
    window,
    reduceFontFactor
) {
    var result = {};
    var pxScaleFactor = 96 / 72;
    var color = parseColor(element, function (elem) {
        return window.getComputedStyle(elem)["backgroundColor"];
    });
    if (color != null) result.fillColor = color;
    color = parseColor(element, function (elem) {
        return window.getComputedStyle(elem)["color"];
    });
    if (color != null) result.textColor = color;
    color = parseColor(element, function (elem) {
        return window.getComputedStyle(elem)["borderTopColor"];
    });
    if (color != null) result.lineColor = color;
    var padding = parsePadding(style, scaleFactor);
    if (padding) result.cellPadding = padding;

    // style.borderWidth only works in chrome (borderTopWidth etc works in firefox
    // and ie as well)
    var bw = parseInt(style.borderTopWidth || "");
    bw = bw / pxScaleFactor / scaleFactor;
    if (bw) result.lineWidth = bw;
    var accepted = ["left", "right", "center", "justify"];
    if (accepted.indexOf(style.textAlign) !== -1) {
        result.halign = style.textAlign;
    }
    accepted = ["middle", "bottom", "top"];
    if (accepted.indexOf(style.verticalAlign) !== -1) {
        result.valign = style.verticalAlign;
    }
    var res = parseInt(style.fontSize || "");
    if (!isNaN(res)) {
        result.fontSize = res / pxScaleFactor;
        if (reduceFontFactor)
            result.fontSize = result.fontSize * reduceFontFactor;
    }

    var fontStyle = parseFontStyle(style);
    if (fontStyle) result.fontStyle = fontStyle;
    var font = (style.fontFamily || "").toLowerCase();
    if (supportedFonts.indexOf(font) !== -1) {
        result.font = font;
    }
    return result;
}

function parseColor(element, styleGetter) {
    var cssColor = realColor(element, styleGetter);
    if (!cssColor) return null;
    var rgba = cssColor.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d*))?\)$/
    );
    if (!rgba || !Array.isArray(rgba)) {
        return null;
    }
    var color = [parseInt(rgba[1]), parseInt(rgba[2]), parseInt(rgba[3])];
    var alpha = parseInt(rgba[4]);
    if (alpha === 0 || isNaN(color[0]) || isNaN(color[1]) || isNaN(color[2])) {
        return null;
    }
    return color;
}
function realColor(elem, styleGetter) {
    var bg = styleGetter(elem);
    if (
        bg === "rgba(0, 0, 0, 0)" ||
        bg === "transparent" ||
        bg === "initial" ||
        bg === "inherit"
    ) {
        if (elem.parentElement == null) {
            return null;
        }
        return realColor(elem.parentElement, styleGetter);
    } else {
        return bg;
    }
}

function parsePadding(style, scaleFactor) {
    var val = [
        style.paddingTop,
        style.paddingRight,
        style.paddingBottom,
        style.paddingLeft,
    ];
    var pxScaleFactor = 96 / (72 / scaleFactor);
    var linePadding =
        (parseInt(style.lineHeight) - parseInt(style.fontSize)) /
        scaleFactor /
        2;
    var inputPadding = val.map(function (n) {
        return parseInt(n) / pxScaleFactor;
    });
    var padding = marginOrPadding(inputPadding, 0);
    if (linePadding > padding.top) {
        padding.top = linePadding;
    }
    if (linePadding > padding.bottom) {
        padding.bottom = linePadding;
    }
    return padding;
}

function marginOrPadding(value, defaultValue) {
    var _a, _b, _c, _d;
    value = value || defaultValue;
    if (Array.isArray(value)) {
        if (value.length >= 4) {
            return {
                top: value[0],
                right: value[1],
                bottom: value[2],
                left: value[3],
            };
        } else if (value.length === 3) {
            return {
                top: value[0],
                right: value[1],
                bottom: value[2],
                left: value[1],
            };
        } else if (value.length === 2) {
            return {
                top: value[0],
                right: value[1],
                bottom: value[0],
                left: value[1],
            };
        } else if (value.length === 1) {
            value = value[0];
        } else {
            value = defaultValue;
        }
    }
    if (typeof value === "object") {
        if (typeof value.vertical === "number") {
            value.top = value.vertical;
            value.bottom = value.vertical;
        }
        if (typeof value.horizontal === "number") {
            value.right = value.horizontal;
            value.left = value.horizontal;
        }
        return {
            left:
                (_a = value.left) !== null && _a !== void 0 ? _a : defaultValue,
            top: (_b = value.top) !== null && _b !== void 0 ? _b : defaultValue,
            right:
                (_c = value.right) !== null && _c !== void 0
                    ? _c
                    : defaultValue,
            bottom:
                (_d = value.bottom) !== null && _d !== void 0
                    ? _d
                    : defaultValue,
        };
    }
    if (typeof value !== "number") {
        value = defaultValue;
    }
    return { top: value, right: value, bottom: value, left: value };
}

function parseFontStyle(style) {
    var res = "";
    if (
        style.fontWeight === "bold" ||
        style.fontWeight === "bolder" ||
        parseInt(style.fontWeight) >= 700
    ) {
        res = "bold";
    }
    if (style.fontStyle === "italic" || style.fontStyle === "oblique") {
        res += "italic";
    }
    return res;
}

export function addLogo(doc, dataUrl, title) {
    doc.addImage(dataUrl, "PNG", 15, 8, 20, 15);
    doc.setFillColor(51, 120, 175);
    doc.rect(37, 10, 80, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.text(title, 39, 17);
}
