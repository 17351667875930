import React from "react";

function FlightValidationTable (props) {

  const { totalInvoiceCharge, totalInvoiceFlights, totalBackendCharge, totalBackendFlights, totalNotOperatedFlights} = props;
  return (
    <>
      <table
        style={{
            border: "1px solid black",
            width: "100%",
            borderCollapse: "collapse",
            margin: "10px 0",
        }}
    >
        <thead>
          <tr>
              <th style={{ width: "25%" }}> </th>
              <th
                  style={{
                      borderLeft: "1px solid black",
                      width: "25%",
                  }}
                  colSpan={2}
              >
                  OFL
              </th>
              <th
                  style={{
                      borderLeft: "1px solid black",
                      width: "25%",
                  }}
                  colSpan={2}
              >
                  TNC
              </th>
              <th
                  style={{
                      borderLeft: "1px solid black",
                      width: "25%",
                  }}
                  colSpan={2}
              >
                  COMM
              </th>
          </tr>
      </thead>
      <tbody>
          <tr>
              <td></td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Amount
              </td>
              <td
                  style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Flights
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Amount
              </td>
              <td
                  style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Flights
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Amount
              </td>
              <td
                  style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      width: "12.5%",
                  }}
              >
                  Flights
              </td>
          </tr>
          <tr>
              <td>Invoiced Total</td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  $
                  {totalInvoiceCharge.toLocaleString()}
              </td>
              <td style={{ textAlign: "end" }}>
                  {totalInvoiceFlights.toLocaleString()}
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
          </tr>
          <tr>
              <td>Teradata Total</td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  $
                  {totalBackendCharge.toLocaleString()}
              </td>
              <td style={{ textAlign: "end" }}>
                  {totalBackendFlights.toLocaleString()}
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
          </tr>
          <tr
              style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
              }}
          >
              <td
                  style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                  }}
              >
                  Non-Validated Total
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  $
                  {(
                      totalInvoiceCharge -
                      totalBackendCharge
                  ).toLocaleString()}
              </td>
              <td style={{ textAlign: "end" }}>
                  {totalNotOperatedFlights.toLocaleString()}
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              >
                  0.00
              </td>
              <td style={{ textAlign: "end" }}>0</td>
          </tr>
          <tr>
              <td
                  style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                  }}
              >
                  Formula Check
              </td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              ></td>
              <td style={{ textAlign: "end" }}></td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              ></td>
              <td style={{ textAlign: "end" }}></td>
              <td
                  style={{
                      borderLeft: "1px solid black",
                      textAlign: "end",
                  }}
              ></td>
              <td style={{ textAlign: "end" }}></td>
          </tr>
        </tbody>
      </table>
      <table
        style={{
            border: "1px solid black",
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "10px",
        }}
    >
        <tbody>
            <tr>
                <td style={{ width: "25%" }}>
                    Invoiced Total
                </td>
                <td
                    style={{
                        borderLeft: "1px solid black",
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0.00
                </td>
                <td
                    style={{
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0
                </td>
                <td
                    style={{
                        borderLeft: "1px solid black",
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0.00
                </td>
                <td
                    style={{
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0
                </td>
                <td
                    style={{
                        borderLeft: "1px solid black",
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0.00
                </td>
                <td
                    style={{
                        textAlign: "end",
                        width: "12.5%",
                    }}
                >
                    0
                </td>
            </tr>
        </tbody>
      </table>
    </>
  );
}

export default FlightValidationTable;