import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';


export default function CurrentDateTime() {
    const [time, setTime] = useState(DateTime.utc());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(DateTime.utc());
        }, 1000)
        return () => clearInterval(interval);
    }, []);


    return (

        <Typography variant="h6">
            {time.toHTTP()}
        </Typography>
    );
};