import { withStyles, TableHead } from "@material-ui/core";

const StyledBlackTableHeader = withStyles((theme) => ({
    root: {
        background: theme.palette.grey[800],
        "& th": {
          color: theme.palette.common.white,
          border: '1px solid #fff',
        }
    },
  }))(TableHead);

export default StyledBlackTableHeader;