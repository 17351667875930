// ------------- REACT -------------
import React, {useState, useEffect} from 'react';

// ------------- MUI -------------
import { 
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Modal,
  Box,
  Dialog
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#FFFAFA',
    border: '0.1em solid #42647F',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    fontSize: "16px",
    textAlign: "center",
    backgroundColor: "#180aa2",
    fontWeight: "bold",
    color: "white",
    padding: "10px"
  },

  ListItemText: {
    color: 'red'
  }
}));

export default function ListDividers(props) {
  //------------- MUI STYLES -------------
  const classes = useStyles();

  //------------- DESTRUCTURING PROPS -------------
  const {data, modalOpen, setModalOpen} = props;

  //------------- STATES -------------
  const [myData, setMyData] = useState('Jamaica')

  //------------- HOOKS -------------
  useEffect(() => {
    if (data && data !== "Jamaica") {
      setMyData(data)
    }
  }, [data, myData])

  return (
    <>
      {myData == 'Jamaica' ?
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            
            <Box className={classes.root} >  
              <Typography className={ classes.title }>Personal Information</Typography>
              <List component="nav" aria-label="mailbox folders">
                {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Name:</strong> ABCDEF</Typography>} />
                </ListItem>
                <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText style={{ color: '#325C74' }} primary={<Typography style={{ fontSize: '1rem' }}><strong>4 Winchester Road, Kingston 10</strong> </Typography>} />
                </ListItem>
                <Divider />
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Telephone:</strong> (876)960-3948,960-3965</Typography>} />
                </ListItem>
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Fax:</strong> (876)920-0194</Typography>} />
                </ListItem>
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>info@jcaa.gov.jm</strong> </Typography>} />
                </ListItem>
              </List>
            </Box>
          </Modal>
          :
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Box className={classes.root} > 
              <Typography className={ classes.title }>Personal Information</Typography>
              <List component="nav" aria-label="mailbox folders">
                {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Name:</strong> {data}</Typography>} />
                </ListItem>
                <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText style={{ color: '#325C74' }} primary={<Typography style={{ fontSize: '1rem' }}><strong>P.O.BOX 669262</strong> </Typography>} />
                </ListItem>
                <Divider />
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Tel:</strong> (305)809-274-4322 Ext.</Typography>} />
                </ListItem>
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Fax:</strong> 809-688-7079</Typography>} />
                </ListItem>
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>facturacion@idac.gob.do</strong> </Typography>} />
                </ListItem>
                <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                  <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>www.idac.gob.do</strong> </Typography>} />
                </ListItem>
            </List>
          </Box>
        </Modal>
      }
    </>
  );
}