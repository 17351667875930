// ------------- REACT -------------
import React from 'react';

// ------------- MUI -------------
import { makeStyles } from '@material-ui/core/styles';

// ------------- COMPONENTS -------------
import FlightDayPickerRestricted from './FlightDayPickerRestricted';

// ------------- MUI STYLES-------------
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 1rem 1rem"
    },
});
export default function DateTimePanelRestricted({ selectedDate, handleDateChange, minDate, maxDate }) {
    // ------------- MUI STYLES -------------
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FlightDayPickerRestricted selectedDate={selectedDate} handleDateChange={handleDateChange} minDate={minDate} maxDate={maxDate} />
        </div>
    );
};