// ------------- REACT -------------
import React, {useContext, useEffect} from 'react';
import { useLocation, useHistory } from "react-router-dom";

// ------------- MUI -------------
import { Card, Grid, Icon, Link} from '@material-ui/core';
import { Button, Chip } from "@mui/material";

// ------------- AXIOS -------------
import axios from '../../axios-usercharges';

// ------------- LOGOS -------------
import ualLogo from "../../assets/logo/ual_logo.jpg";

// ------------- COMPONENTS  -------------
import Navbar from '../navbar/Navbar';
import Chart11 from './Chart/Chart11';
import Chart5 from './Chart/Chart5';
import Chart8 from './Chart/Chart8';
import Chart1 from './Chart/Chart1';

// ------------- CONTEXT PROVIDERS  -------------
import { biginAuthContext } from "../BiginContext/BiginContext";

function DashboardComponent() {
    const {
        hasCodes,
        redirectUri,
        clientId,
        setToken

    } = useContext(biginAuthContext)

    const location = useLocation();
    const history = useHistory();

    useEffect(() =>{  
      const queryParams = new URLSearchParams(location.search);
      const value = queryParams.get("code");
    if(value){
        axios.get(`/api/bigin/?code=${value}`).then((result) => {
            if (result) {
            if(result.data.access_token) {
                console.log(result.data)
                const biginTokensWithDate = {...result.data, date_issued: new Date()}
                setToken(JSON.stringify(biginTokensWithDate)); 
                
            }
            history.push('/');
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    }, [])

    const biginUrl = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=${clientId}&scope=ZohoBigin.modules.ALL,ZohoBigin.settings.ALL&access_type=offline&redirect_uri=${redirectUri}`

    return (
        <>
            <Navbar index={1} />
            <div style={{width: "95%", margin: "0 auto"}}>
                 <div style={{padding: "20px"}}>
                    {!hasCodes && <Button variant="contained">
                        <Link href={biginUrl} style={{color: "white"}}>
                            Connect With Bigin
                        </Link>
                    </Button>}
                    {hasCodes && <Chip style={{marginLeft: "20px"}} label="Bigin Connected" color="success" />}
                </div>
                <div style={{padding: "10px"}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card style={{margin: "10px", backgroundColor: "#e6e6e6", height: "500px"}}>
                                <h1 style={{ textAlign: "center", backgroundColor: "#180aa2", color: "white", fontSize: "24px", margin: "0 0 10px 0", padding: "5px 0"}}>
                                    Country Cost Today (USD)
                                </h1>
                                <div style={{width: "95%", margin: "auto"}}>
                                    <Chart5 />
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Card style={{margin: "10px",  backgroundColor: "#e6e6e6", height: "500px"}}>
                                <h1 style={{ textAlign: "center", backgroundColor: "#180aa2", color: "white", fontSize: "24px", margin: "0 0 10px 0", padding: "5px 0"}}>
                                    FIR to Total Cost Ratio (USD)
                                </h1>
                                <div style={{width: "95%", margin: "auto"}}>
                                    <Chart11 />
                                </div>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={6} >
                            <Card style={{margin: "10px", backgroundColor: "#e6e6e6", height: "500px"}}>
                                <h1 style={{ textAlign: "center", backgroundColor: "#180aa2", color: "white", fontSize: "24px", margin: "0 0 10px 0", padding: "5px 0"}}>
                                    Cost Per Aircraft (Currency in USD)
                                </h1>
                                <div style={{width: "95%", margin: "auto"}}>
                                    <Chart8 />
                                </div>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={6} >
                            <Card style={{margin: "10px", backgroundColor: "#e6e6e6", height: "500px"}}>
                                <h1 style={{ textAlign: "center", backgroundColor: "#180aa2", color: "white", fontSize: "24px", margin: "0 0 10px 0", padding: "5px 0"}}>
                                    Cosliest Routes Today (Currency in USD)
                                </h1>
                                <div style={{width: "95%", margin: "auto"}}>
                                    <Chart1 />
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default DashboardComponent;