import React from "react";
import { Droppable } from "react-beautiful-dnd";

const Column = (props) => {

  return (
    <>
    <div style={{
      backgroundColor: "grey",
      borderRadius: "2.5px",
      width: "300px",
      height: "600px",
      overflowY: "scroll",
      scrollbarWidth: "none",
      border: "1px solid black"
    }}>
      <h2 style={{textAlign: "center"}}> Column </h2>
      <Droppable droppableId={props.id}>
        {(provided, snapshot) => {
            return <div 
              style={{
                padding: "3px",
                backgroundColor: "grey",
                flexGrow: 1,
                minHeight: "100px"
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {provided.placeholder}
            </div>
          }
        }
      </Droppable>
    </div>
    </>
  )
}

export default Column;