// ------------- REACT -------------
import React, {useState, useEffect} from 'react';

// ------------- MUI -------------
import { makeStyles, List, ListItem, ListItemText, Divider, Typography, Modal, Box, Dialog} from '@material-ui/core';

// ------------- CSS -------------
import "./BankingDetails.css"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#FFFAFA',
    border: '0.1em solid #42647F',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    fontSize: "16px",
    textAlign: "center",
    backgroundColor: "#180aa2",
    fontWeight: "bold",
    color: "white",
    padding: "10px"
  },
  ListItemText: {
    color: 'red'
  }
}));

export default function ListDividers(props) {
  const classes = useStyles();
  const {data, anspData, modalOpen, setModalOpen} = props;
  const [myData, setMyData] = useState('Jamaica')

  useEffect(() => {
    if (data && data !== "Jamaica") {
      setMyData(data)
    }
  }, [data, myData])

  return (
    <>
      {myData == 'Jamaica' ?
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box className={classes.root} >  
            <Typography className={ classes.title }>Banking Information</Typography>
            <List component="nav" aria-label="mailbox folders">
              {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText style={{ color: '#325C74' }} primary={<Typography style={{ fontSize: '1rem' }}><strong>ANS Name:</strong> Jamaica Civil Aviation Authority</Typography>} />
              </ListItem>
              <Divider />
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Bank Account#:</strong> 7713-101443-005</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Bank Name:</strong> New Kingston Branch 1-7 Knutsford Boulevar Kinbgyson 5, Jamaica, West Indes</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Swift Code:</strong> JAMBMVMV</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Correspondent Bank:</strong> Jp Morgan Chase Bank</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>IBAN:</strong> ABA 0002</Typography>} />
              </ListItem>
              <Divider light />
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Swift Code:</strong> CHASUS33</Typography>} />
              </ListItem>
            </List>
          </Box>
        </Modal>
          :
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box className={classes.root} >  
            <Typography className={ classes.title }>Banking Information</Typography>
            <List component="nav" aria-label="mailbox folders">
              {/* <h4 style={{textAlign:'center'}}>Banking Details</h4> */}
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText style={{ color: '#325C74' }} primary={<Typography style={{ fontSize: '1rem' }}><strong>OFL Country: </strong>{data}</Typography>} />
              </ListItem>
              <Divider />
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>TNC Country:</strong>-</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>COMM Country:</strong>-</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Invoice Received Date:</strong> 7/10/2023</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>External Invoice #:</strong> 202311602619</Typography>} />
              </ListItem>
              <ListItem divider style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Invoice Date:</strong> 7/7/2023</Typography>} />
              </ListItem>
              <Divider light />
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>PAYMENT DUE DATE:</strong> 7/25/2023</Typography>} />
              </ListItem>
              <Divider light />
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>Beg. Invoice Period:</strong> 6/1/2023</Typography>} />
              </ListItem>
              <Divider light />
              <ListItem style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                <ListItemText primary={<Typography style={{ fontSize: '1rem', color: '#325C74' }}><strong>End. Invoice Period:</strong> 6/30/2023</Typography>} />
              </ListItem>
            </List>
          </Box>
        </Modal>
      }
    </>

  );

}
