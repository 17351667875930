// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import {makeStyles, Box} from "@material-ui/core";

// ------------- COMPONENTS -------------
import LeftPanel from "./LeftPanel/LeftPanel";
import Navbar from '../navbar/Navbar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 0px',
    flexGrow: 1
  },
  leftPanel: {
    borderRight: "1px solid #ececec",
  },
  gridContainer: {
    minHeight: '100vh',
    width: '100%'
  }
}));

export default function CalculationRoute(props) {
  const classes = useStyles();

  return (
    <div>
      <Box
        className={classes.gridContainer}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="stretch"
      >
        <Navbar index={5} />
        <LeftPanel />
      </Box>
    </div>
  );
}
