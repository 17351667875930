import { DateTime } from 'luxon';

export function getChildProp(map, key) {
    if (map[key]) return map[key];
    if (key.indexOf('.') > -1) {
        const props = key.split('.');
        return map[props[0]][props[1]];
    }
    return '';
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    let errorMessages = [];

    if (!rules) {
        return true;
    }

    if (rules.required) {
        let required = value.trim() !== '';
        isValid = required && isValid;
        if (!required) errorMessages.push('This field is required.');
    }

    if (rules.minLength) {
        let isMinLength = value.length >= rules.minLength;
        isValid = isMinLength && isValid;
        if (!isMinLength) errorMessages.push(`Minimum Length is ${rules.minLength}.`);
    }

    if (rules.maxLength) {
        let isMaxLength = value.length <= rules.maxLength
        isValid = isMaxLength && isValid;
        if (!isMaxLength) errorMessages.push(`Maximum Length is ${rules.maxLength}.`);
    }

    if (rules.isEmail) {

        let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let isEmail = re.test(value);
        isValid = isEmail && isValid;
        if (!isEmail) errorMessages.push(`Invalid Email.`);
    }

    return {
        isValid,
        errorMessages
    };
};

export const getFormattedDateFromMonthYear = (yearMonthString) => {
    const [year, month] = yearMonthString.split(' ');
  
    const date = DateTime.fromObject({ year, month });
    // longest month is 9 letters long
    const PADDING = 9;
    // TODO: fix this so the formatting is nicer, believe it's a menuitem stripping whitespace
    return `${date.monthLong} ${date.year}`;
};

export const getTime = (utcString) => utcString.split('T')[1].slice(0, 5);

export const getHoursMinutesSecondsString = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.round(timeInSeconds / 60) % 60;
    const seconds = timeInSeconds % 60;

    return `${hours}:${minutes}:${seconds}`;
};


export const formatDateFromISO = (date) => {
    const stringDateSplit = date.split('T')[0].split('-');
    return `${stringDateSplit[2]}/${stringDateSplit[1]}/${stringDateSplit[0]}`
};