// ------------- REACT -------------
import React from 'react';

// ------------- MUI -------------
import {
  makeStyles,
  Radio,
  FormControlLabel
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  radio: {
      padding: '4px'
  },
  root: {
    width: '135px',
    marginLeft: '-5px'
  }
}));

function CustomRadio(props) {
    const classes = useStyles();
    return (
      <FormControlLabel
        className={classes.root}
        {...props}
        control={<Radio className={classes.radio} />}
        
      />
    );
}

export default CustomRadio;