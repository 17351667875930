// ------------- REACT -------------
import React from 'react';

// ------------- MUI -------------
import { Table, TableContainer, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';

// ------------- LUXON -------------
import { DateTime } from 'luxon';

// ------------- IMAGES -------------
import FliteXLogo from '../../assets/logo/new_flitex_logo.jpg';

// Component to set up row of the printable table
function BetaInvoicePrintTableRow (props) {
  	const {flight} = props; 
  
 	 return (
		<TableRow>
			<TableCell align="center" style={{fontSize: '12px'}}>{DateTime.fromISO(flight.etd).toLocaleString(DateTime.DATE_MED)}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.flightNumber}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.departure}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.arrival}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.aircraftModel}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{Number(flight.mtow.MAX_TOW_KG).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.totalFirCalculatedInfo.distance_nm.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
			<TableCell align="center" style={{fontSize: '12px'}}>{flight.totalFirCalculatedInfo.cost.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
		</TableRow>
 	)
}

// Component to set up the printable invoice
function BetaInvoicePrint (props) {
	// Create table rows for the invoice
	const tableRows = props.selectedData ? props.selectedData.data.map((flight, index) => {
		return <BetaInvoicePrintTableRow key={index} flight={flight}/>
	}) :
	[];

	return (
	<>
		<style>
			{'@page {margin: 20px auto'}
		</style>
		<div style={{display: "flex", alignItems: "center"}}>
			<img alt="IATA" src={FliteXLogo} width="20%" height="25%" style={{pageBreakAfter: 'avoid'}}/>
			<h2 style={{backgroundColor: "#1A75D1", color:"white", width: "50%", marginLeft: "2.5%", paddingLeft:"10px", pageBreakAfter: 'avoid'}}> MONTHLY INVOICE {props.country}</h2>
			</div> 
			<TableContainer>
				<Table>
					<TableHead>
					<TableHead style={{backgroundColor: "#1A75D1", color: "white", marginTop: "20px"}}>
						<TableRow>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white", width: "15%"}}>DATE</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>FLIGHT</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>ORIGIN</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>DESTINATION</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>AIRCRAFT</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>WEIGHT (kg)</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>DISTANCE (nm)</TableCell>
							<TableCell align="center" style={{fontSize: '12px',fontWeight: '600', color: "white"}}>CHARGE</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{tableRows}
						</TableBody>
					</TableHead>
				</Table>
			</TableContainer>
			<div style={{display: "flex", justifyContent: "end"}}>
			<h2 style={{backgroundColor: "#1A75D1", color: "white", padding: "5px 10px"}}>{props.selectedData && (props.selectedData.data.length > 0) && props.selectedData.data[0].totalFirCalculatedInfo.currency}</h2>
			<h2 style={{backgroundColor: "#1A75D1", color: "white", padding: "5px 10px"}}>{props.selectedData && props.selectedData.total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h2>
		</div>
	</>
	);
}

export default BetaInvoicePrint;