// ------------- REACT -------------
import React from "react";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import DataExtract from "./DataExtract";

function ANPA() {
  return (
    <>
      <Navbar index ={8}/>
      <DataExtract/>
    </>
  );
}

export default ANPA;