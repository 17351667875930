import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import DataExtract from "./DataExtract/DataXtracta-florida";
import axios from "../../axios-usercharges";

function TrackDataComponent({ documentId }) {
  const [trackedData, setTrackedData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const api_key = "bea7577a2f07e365b8cd8c8e0e2840638df251b0";

  const fetchTrackData = async () => {
    try {
      const requestData = {
        document_id: documentId,
        api_key: api_key,
      };

      const response = await axios.post(`/api/track-data`, requestData);
      const responseData = response.data;

      setTrackedData([...trackedData, responseData]);
      setError(null);

      if (responseData.length === 0) {
        setLoading(false);
        return;
      }

      setTimeout(fetchTrackData, 20000);
    } catch (error) {
      setError("An error occurred while retrieving data from Xtracta.");
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchTrackData();
  }, [documentId]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <div style={{ marginTop: "20px" }}>
            <CircularProgress size={60} />
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
      {!loading && documentId !== null && (
        <DataExtract documentId={documentId} />
      )}
    </div>
  );
}

export default TrackDataComponent;
