const ectlGlossaryA = [
  {
    glossaryName: "Pro Forma Statement",
    glossaryDescription: "Detailed list of flights for which en route air navigation services were made available during a given calendar month (list attached to a given bill)."
  },
  {
    glossaryName: "Day",
    glossaryDescription: "is shown in the first column of the Pro Forma Statement and corresponds to the date of ATD (for flights departing within the CRCO area) or ATO (for flights departing outside the CRCO area)."
  },
  {
    glossaryName: "Line N°",
    glossaryDescription: "is attributed by chronological order and listed in the second column of the Pro Forma Statement."
  },
  {
    glossaryName: "Flight ID",
    glossaryDescription: "corresponds to the flight or registration number as shown in the third column of the Pro Forma Statement."
  }
]

const ectlGlossaryB = [
  {
    glossaryName: "ADEP",
    glossaryDescription: "Aerodrome of Departure"
  },
  {
    glossaryName: "ADES",
    glossaryDescription: "Aerodrome of Destination"
  },
  {
    glossaryName: "ATA",
    glossaryDescription: "Actual Time of Arrival"
  },
  {
    glossaryName: "ATD",
    glossaryDescription: "Actual Time of Departure"
  },
  {
    glossaryName: "ATO",
    glossaryDescription: "Actual Time Over"
  },
  {
    glossaryName: "CNL",
    glossaryDescription: "Cancellation"
  }
]

const ectlGlossaryC = [
  {
    glossaryName: "EOBD",
    glossaryDescription: "Estimated Off Block Date"
  },
  {
    glossaryName: "FPL",
    glossaryDescription: "Flight Plan"
  },
  {
    glossaryName: "IFPLID",
    glossaryDescription: "Unique identifier assigned to a flight by IFPS"
  },
  {
    glossaryName: "IFPS",
    glossaryDescription: "Integrated initial Flight Plan Processing system"
  },
  {
    glossaryName: "MTOW",
    glossaryDescription: "Max Take-Off Weight"
  },
]


export {ectlGlossaryA, ectlGlossaryB, ectlGlossaryC};