// ------------- REACT -------------
import React, { useState } from "react";

// ------------- MUI -------------
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles,
    Box,
    TablePagination,
    TableFooter,
    IconButton,
} from "@material-ui/core";

// --- MUI ICONS ---
import ExploreIcon from "@material-ui/icons/Explore";

// ------------- LUXON -------------
import { DateTime } from "luxon";

// ------------- AXIOS -------------
import axios from "../../axios-usercharges";

// ------------- MISC -------------
import { getHoursMinutesSecondsString } from "../../shared/util";
import NumberFormat from "react-number-format";
import ANSPLookup from "../../ANSPLookup/ANSPLookup";

// ------------- COMPONENTS -------------
import StyledTableRow from "../Utility/TableStyles/StyledTableRow";
import StyledTableCell from "../Utility/TableStyles/StyledTableCell";
import TablePaginationActions from "../Utility/TableStyles/TablePaginationActions";
import FlightMapModal from "../fliteROUTE/FlightMapModal";

// ------------- LOGOS -------------
import Dominican from "./../../assets/logo/dominicanRepublica.png";
import Seneam from "./../../assets/logo/Seneam_logo.jpg";

// ------------- MUI STYLES -------------
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        background: theme.palette.common.white,
    },
    tableHeader: {
        background: theme.palette.info.dark,
        "& th": {
            color: theme.palette.common.white,
            border: "1px solid #fff",
        },
    },
    highlightTableHeader: {
        fontSize: "20px",
    },
    row: {
        background: theme.palette.common.white,
        height: "30px",
    },
    currencyCell: {
        background: "#1D9EFF",
        color: "#fff",
    },
    amountCell: {
        background: "#0070C0",
        color: "#fff",
    },
    imageContainer: {
        overflow: "hidden",
        fontSize: "1.5rem",
        flexShrink: "0",
        userSelect: "none",
    },
}));

const UpdatedPsuedoInvoice = React.forwardRef((props, ref) => {
    // ------------- MUI STYLES -------------
    const classes = useStyles();

    // ------------- DECONSTRUCTING PROPS -------------
    const { country, data, date } = props.selectedData;
    const { page, setPage, rowsPerPage, setRowsPerPage, totalFlights } = props;

    // ------------- REFS -------------
    const GreatCircleRef = React.createRef();

    // ------------- STATES -------------
    const [selectedFlightInfo, setSelectedFlightInfo] = React.useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [mapModalOpen, setMapModalOpen] = useState(false);

    // //------------- VARIABLES -------------
    const currency =
        data.length > 0 &&
        data[0].totalFirCalculatedInfo &&
        data[0].totalFirCalculatedInfo.currency;
    const totalTime = data.reduce((acc, currFlight) => {
        return acc + currFlight.totalFirCalculatedInfo.elapsed_time;
    }, 0);
    const totalDistance = data.reduce((acc, currFlight) => {
        return acc + currFlight.totalFirCalculatedInfo.distance_nm;
    }, 0);

    const charge = data.reduce((acc, currFlight) => {
        return acc + currFlight.totalFirCalculatedInfo.cost;
    }, 0);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    //------------- FUNCTIONS -------------
    // --- TABLE PAGINATION ---
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // --- ROW COMPONENT ---
    const renderRow = (flight) => {
        const weight = flight.mtow.MAX_TOW_KG;
        // TODO: change date_of_flight to be more accuracte
        const dateOfFlight = DateTime.fromISO(flight.etd);

        const formatDateFromISO = (date) => {
            const stringDateSplit = date.split("T")[0].split("-");
            return `${stringDateSplit[2]}/${stringDateSplit[1]}/${stringDateSplit[0]}`;
        };

        const totalDist = flight.totalFirCalculatedInfo.distance_nm;
        const totalCost = flight.totalFirCalculatedInfo.cost;

        const handleTrackIcon = (event) => {
            const flight_ref = event;
            axios
                .get(`/api/UAL/betaInvoice/track/${flight_ref}`)
                .then((res) => {
                    let flightInfo = res.data;

                    const atcCost = Number(
                        flightInfo[0].firCalculatedInfo
                            .map((fc) => fc.commonUsdCost)
                            .reduce((acc, curr) => acc + curr)
                    );
                    const totalCost = atcCost;
                    flightInfo = { ...flightInfo, atcCost, totalCost };

                    const {
                        flight_ref,
                        mtow,
                        departure,
                        arrival,
                        firCalculatedInfo,
                        etd,
                        eta,
                        flightNumber,
                        aircraftModel,
                        legacy_format,
                    } = flightInfo[0];
                    const atcCosts = flightInfo.atcCost;
                    const totalCosts = totalCost;
                    setSelectedDate(DateTime.fromISO(flightInfo[0].etd));
                    const selectedInfo = {
                        atcCosts,
                        totalCosts,
                        flight_ref,
                        mtow,
                        departure,
                        arrival,
                        firCalculatedInfo,
                        etd,
                        eta,
                        flightNumber,
                        aircraftModel,
                        legacy_format,
                    };

                    axios
                        .get(
                            `/api/UAL/betainvoice/geometry/${flightInfo[0].flight_ref}`
                        )
                        .then((res) => {
                            const firRegionsPointData = res.data;

                            let getWayPoints = [];
                            axios
                                .get(
                                    `/api/UAL/betainvoice/waypoints/${flightInfo[0].flight_ref}`
                                )
                                .then((res) => {
                                    getWayPoints = res.data;

                                    const firInfoWithPoints =
                                        firCalculatedInfo.map((f, i) => ({
                                            ...f,
                                            geometry: firRegionsPointData[i],
                                            wayPointsC: getWayPoints,
                                        }));

                                    const gcData = {
                                        type: "FeatureCollection",
                                        features: firInfoWithPoints,
                                    };

                                    setSelectedFlightInfo({
                                        gcData,
                                        ...selectedInfo,
                                    });
                                    setMapModalOpen(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        // const iconColor = colors.forEach(color=><ExploreTwoToneIcon style={{color:}}/>)
        return (
            <StyledTableRow key={flight.flight_ref} className={classes.row}>
                <StyledTableCell align="center">
                    <IconButton
                        variant="outlined"
                        onClick={() => handleTrackIcon(flight.flight_ref)}
                    >
                        {<ExploreIcon style={{ color: "#bd0026" }} />}
                    </IconButton>
                    {DateTime.fromISO(flight.etd).toLocaleString(
                        DateTime.DATE_MED
                    )}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {"UAL" + flight.flightNumber.slice(3)}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {flight.departure}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {flight.arrival}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {flight.aircraftModel}
                </StyledTableCell>
                <StyledTableCell align="center">
                    <NumberFormat
                        value={weight}
                        displayType={"text"}
                        thousandSeparator={true}
                    />
                </StyledTableCell>
                <StyledTableCell align="center">
                    {totalDist.toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                    <>
                        <NumberFormat
                            value={totalCost}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                        <span>{` ${flight.totalFirCalculatedInfo.currency}`}</span>
                    </>
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    return (
        <TableContainer
            component={Box}
            elevation={1}
            className={classes.tableContainer}
        >
            {mapModalOpen && (
                <FlightMapModal
                    GreatCircleRef={GreatCircleRef}
                    setModalOpen={setMapModalOpen}
                    selectedFlightInfo={selectedFlightInfo}
                    selectedDate={selectedDate}
                />
            )}
            <div
                style={{
                    marginTop: "10px",
                    padding: "0px 50px",
                    border: "3px solid black",
                    borderRadius: "30px",
                    marginBottom: "40px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <span className={classes.imageContainer}>
                        <img
                            alt={
                                props.countryData
                                    ? props.countryData.flags.alt
                                    : "IATA"
                            }
                            src={
                                props.countryData
                                    ? props.countryData.flags.png
                                    : Dominican
                            }
                            style={{
                                marginTop: "25px",
                                width: "auto",
                                height: "80px",
                                border: "1px solid black",
                            }}
                        />
                    </span>
                    <span className={classes.imageContainer}>
                        <h4>{`ANSP ${props.countryData?.name?.common}`}</h4>
                        {/* <img alt="IATA" src={ANSPLookup[country.toLowerCase()] ? `https://caa-ansp.s3.ca-central-1.amazonaws.com/${ANSPLookup[country.toLowerCase()]}` : Seneam} style={{ marginTop: "25px", width: 'auto', height: '80px' }} /> */}
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxHeight: "80px",
                    }}
                >
                    <h3
                        style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            background: "#180aa2",
                            color: "white",
                            padding: "8px",
                        }}
                    >
                        {country}
                    </h3>
                    <h6
                        style={{
                            fontSize: "20px",
                            fontWeight: "700",
                            background: "#180aa2",
                            color: "white",
                            padding: "8px",
                        }}
                    >
                        {date}
                    </h6>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                        Total Flights :{" "}
                        <NumberFormat
                            value={totalFlights}
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                    </span>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "16px",
                        }}
                    >
                        Currency : <p>{currency}</p>
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontWeight: "600",
                        fontSize: "16px",
                    }}
                >
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                        Total Charge :{" "}
                        <NumberFormat
                            value={charge}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                    </span>
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                        Total Distance :{" "}
                        <NumberFormat
                            value={parseInt(totalDistance)}
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                    </span>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "16px",
                        }}
                    >
                        Total Time :{" "}
                        <p>{getHoursMinutesSecondsString(totalTime)}</p>
                    </span>
                </div>
            </div>
            <Table size="small" aria-label="a dense table" ref={ref}>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            DATE
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            FLIGHT
                        </TableCell>
                        {/* <TableCell align="center">Flight Number</TableCell> */}
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            ORIGIN
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            DESTINATION
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            AIRCRAFT
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            WEIGHT (kg)
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            DISTANCE (nm)
                        </TableCell>
                        {/* <TableCell align="center">SEG TIME (HH:MM:SS)</TableCell> */}
                        <TableCell
                            align="center"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                            CHARGE
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? data.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                          )
                        : data
                    ).map((row, index) => renderRow(row))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            colSpan={10}
                            count={totalFlights}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
});

export default UpdatedPsuedoInvoice;
