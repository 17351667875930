// ------------- REACT -------------
import * as React from 'react';

// ------------- MUI -------------
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Box } from '@material-ui/core';

// ------------- MISC -------------
import NumberFormat from 'react-number-format';

// ------------- COMPONENTS -------------
import StyledTableRow from '../../../../Utility/TableStyles/StyledTableRow';
import StyledTableCell from '../../../../Utility/TableStyles/StyledTableCell';
import StyledBlackTableHeader from '../../../../Utility/TableStyles/StyledBlackTableHeader';

// ------------- MUI STYLES -------------
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    background: theme.palette.common.white
  },
  headingContainer: {
    background: theme.palette.common.white,
    padding: '10px 0px',
    '& img': {
        width: '70px'
    }
  },
  tableHeader: {
    // background: theme.palette.info.dark,
    background: '#DFCE97',
    "& th": {
      color: theme.palette.common.white,
      border: '1px solid #fff',
    },
  },
  heading: {
      background: theme.palette.primary.light,
      color: theme.palette.common.white,
      maxWidth: '300px',
      padding: '5px',
  },
  row: {
      background: theme.palette.common.white
  },
  currencyCell: {
    background: '#1D9EFF',
    color: '#fff'
  },
  amountCell: {
    background: '#0070C0',
    color: '#fff'
  }
}));


const FlightSummaryTable = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const date = props.flight.flight.date_of_flt;
  const src = props.flight.flight.dep_apt_iata;
  const dest  = props.flight.flight.arr_apt_iata;
  const flight = props.flight.flight.acft_type;
  const flightInfo = `Actual TOW ${parseInt(props.flight.flight.est_tow)} kgs `;
  const groundDistance = `${props.flight.flight.ground_dist}`;
  const currency = 'USD';
  const totalAmount = props.flight.flight.sum_user_charge;
  return (
    <TableContainer component={Box} elevation={1} className={classes.tableContainer}>
      <Table  size="small" aria-label="a dense table" ref={ref} >
      <StyledBlackTableHeader>
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{src}</TableCell>
            <TableCell align="left">{dest}</TableCell>
            <TableCell align="center">{flight}</TableCell>
            <TableCell align="center">Actual TOW <NumberFormat value={flightInfo} displayType={'text'} thousandSeparator={true} /> kgs</TableCell>
            <TableCell align="center" colSpan={2}>Ground Distance <NumberFormat value={groundDistance} displayType={'text'} thousandSeparator={true} /></TableCell>
          </TableRow>
        </StyledBlackTableHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left">FIR Code</TableCell>
            <TableCell align="left">FIR Name</TableCell>
            <TableCell align="left">FIR Country</TableCell>
            <TableCell align="center">SEG DIST</TableCell>
            <TableCell align="center">Billing Currency</TableCell>
            <TableCell align="center">Invoiced</TableCell>
            <TableCell align="center">Converted USD$</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.flight.usercharge.map((row, index) => (
            <StyledTableRow key={index} className={classes.row}>
              <StyledTableCell align="left">{row.icao_fir}</StyledTableCell>
              <StyledTableCell align="left">{row.fir_name ? row.fir_name : row.fir}</StyledTableCell>
              <StyledTableCell align="left">{row.country}</StyledTableCell>
              <StyledTableCell align="center"><NumberFormat value={row.fir_dist} displayType={'text'} thousandSeparator={true} /></StyledTableCell>
              <StyledTableCell align="center">{row.country_currency_code}</StyledTableCell>
              <StyledTableCell align="center"><NumberFormat value={row.Cost_Domestic_Currency} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
              <StyledTableCell align="right"><NumberFormat value={row.cost || ''} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></StyledTableCell>
            </StyledTableRow>
          ))}
          <TableRow>
            <TableCell colSpan={5}></TableCell>
            <TableCell align="center" className={classes.currencyCell}>{currency}</TableCell>
            <TableCell align="right" className={classes.amountCell}><NumberFormat value={totalAmount} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});


export default FlightSummaryTable;