// ------------- REACT -------------
import React from 'react';

// ------------- MUI -------------
import {Button, TextField, Chip, Typography, MenuItem, FormControl, Select } from '@material-ui/core';

// ------------- COMPONENTS -------------
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Component to create rows in the contacts table
function ContactTableRow(props) {
  const handleGroupSelect = (event) => {
    // value that is returned from the event to use in future
    return event.target.value;
  };

  return (
    <tr style={{border: "1px solid lightgrey", textAlign: "center"}}>
      <td>
        <div style={{padding: "10px", display: "flex", justifyContent:"center", alignItems: "center", color: "grey"}}>
          <input type="checkbox"/>
          <span style={{marginLeft: "2px"}}>
            {props.name ? props.name : "(not joined yet)"}
          </span>
        </div>
      </td>
      <td>
        <span style={{fontWeight: "bold"}}>{props.email}</span>
      </td>
      <td>
      <TextField
        variant='outlined'
        size="small"
        value={props.billableRate}
        InputProps={{endAdornment: 
          <Button variant="outlined">Change</Button>
        }}
      />
      </td>
      <td>
        {props.role ? 
          <Chip label={props.role} color="primary"/>
          :
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2"}}>
            <Chip label="+" color="primary" size="small"/>
            <Typography color="primary" style={{marginLeft: "5px"}}>Role</Typography>
          </div>
        }
      </td>
      <td>
        <FormControl fullWidth>
          <Select
            value={props.group}
            label={props.group}
            onChange={handleGroupSelect}
          >
            <MenuItem value={"Admin"}>Admin</MenuItem>
            <MenuItem value={"Dev Group"}>Dev Group</MenuItem>
          </Select>
        </FormControl>
      </td>
      <td>
        <MoreVertIcon/>
      </td>
    </tr>
  );
}

export default ContactTableRow;