// @flow
import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

import { Typography, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: -999
  }
}));



const FirChargePerKmChart = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const width = 550;
  const height = 350;
  const getCostPerKM = (usercharge) => {
    return +usercharge.cost / (+usercharge.fir_dist * 1.852); 
  }
  return (
    <div style={{ 'width' : `${width}px` }} ref={ref} className={classes.root}>
      <Typography variant="h6" component="h3" align="center">
        Charge Per KM
      </Typography>
      <BarChart width={width} height={height} data={props.flight.usercharge}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fir" interval={0} angle={-45} textAnchor="end" height={100} />
        <YAxis type="number" interval={0} tickFormatter={(tick) => '$' + tick}  allowDecimals={true} />
        
        <Tooltip />
        <Bar dataKey={getCostPerKM} fill="#4E81BD" />
      </BarChart>
    </div>
  );
});

export default FirChargePerKmChart;
