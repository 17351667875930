import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
    Typography,
    makeStyles,
    Grid,
    Button,
    FormControl,
    RadioGroup,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Tabs,
    Tab,
    useTheme,
    Box,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import CustomRadio from "./CustomRadio/CustomRadio";
import TabPanel from "../../../Utility/TabPanel/TabPanel";
import VirtualizedAutocompleteAircraftTypes from "../../VirtualizationAutocomplete/VirtualizedAutocompleteAircraftTypes";
import VirtualizedAutocomplete, {
    VirtualizedAirlinesAutocomplete,
    VirtualizedTailNumberOriginAutocomplete,
    VirtualizedTailNumberDestinationAutocomplete,
} from "../../VirtualizationAutocomplete/VirtualizedAutocomplete";
import DateTimePanel from "../../../fliteROUTE/DateTimePanel";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const tabHeight = "36px";

const useStyles = makeStyles((theme) => ({
    root: {
        // border: "1px solid #000",
        // borderRadius: 0,
        marginBottom: "10px",
    },
    heading: {
        textAlign: "center",
        backgroundColor: "#95C6CE",
        fontSize: "1.6rem",
        // color: theme.palette.getContrastText(theme.palette.info.main),
        fontFamily: "Mukta, sans-serif",
        color: "black",
        fontWeight: "bold",
    },
    controlSection: {
        padding: "5px",
        minHeight: "110px",
        "& h2": {
            padding: "2px",
            fontWeight: 700,
            fontSize: "1rem",
        },
        fontFamily: "Mukta, sans-serif",
    },
    formControl: {},
    radioFormControl: {
        // marginTop: "25px",
        fontSize: "11px",
    },
    tabRoot: {
        minHeight: tabHeight,
        height: tabHeight,
    },
    tab: {
        minHeight: tabHeight,
        height: tabHeight,
    },
    select: {
        color: "black",
        weight: "bold",
        fontFamily: "Mukta, sans-serif",
        fontSize: "1rem",
    },
    radioGroup: {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Mukta, sans-serif",
    },
}));

function RouteFilter(props) {
    const classes = useStyles();
    const theme = useTheme();
    let aircraftModels = {};

    if (props.aircrafts) {
        props.aircrafts.map(
            (aircraft) => (aircraftModels[aircraft.acft_model] = aircraft)
        );
    }
    // list of unqiue aircraft models
    let aircraftModelsList = Object.keys(aircraftModels);

    // In case if aircraft model is selected then filter tail number
    let filteredAircrafts = [...props.aircrafts];
    if (props.aircraftModel) {
        filteredAircrafts = filteredAircrafts.filter(
            (aircraft) => aircraft.acft_model === props.aircraftModel
        );
    }

    return (
        <Paper className={classes.root} elevation={0}>
            <Typography
                className={classes.heading}
                variant="h6"
                component="h2"
                style={{ backgroundColor: "#180aa2", color: "white" }}
            >
                Inputs
            </Typography>
            <Box>
                {/* <Tabs
          value={props.index}
          onChange={props.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabRoot}
        >
          <Tab className={classes.tab} label="Aircraft Specification" />
          <Tab className={classes.tab} label="Psuedo Invoice" />
        </Tabs> */}
            </Box>

            <TabPanel
                value={props.index}
                index={0}
                dir={theme.direction}
                className={classes.tabPanel}
            >
                <Grid container>
                    <Grid md item className={classes.controlSection}>
                        <FormControl
                            fullWidth
                            component="fieldset"
                            className={classes.radioFormControl}
                        >
                            <RadioGroup
                                row
                                aria-label="basic info"
                                name="basic-info"
                                value={props.basicInfo}
                                onChange={props.handleBasicInfoChange}
                                className={classes.radioGroup}
                            >
                                {" "}
                                {/* <CustomRadio value="country" label="Country Basis" disabled />
                <CustomRadio value="airport" label="Airport Basis" disabled /> */}
                                {/* <CustomRadio value="route" label={<Typography style={{ fontSize: '1.3rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}  >Single Route</Typography>} /> */}
                                <CustomRadio
                                    value="greatCircle"
                                    label={
                                        <Typography
                                            style={{
                                                fontSize: "1.3rem",
                                                fontFamily: "Mukta, sans-serif",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            GCD Draw
                                        </Typography>
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                />
                                <CustomRadio
                                    value="routestring"
                                    label={
                                        <Typography
                                            style={{
                                                fontSize: "1.3rem",
                                                fontFamily: "Mukta, sans-serif",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Plan Route
                                        </Typography>
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                />
                                <CustomRadio
                                    value="flownRoute"
                                    label={
                                        <Typography
                                            style={{
                                                fontSize: "1.3rem",
                                                fontFamily: "Mukta, sans-serif",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Flown Route
                                        </Typography>
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                />
                                <CustomRadio
                                    value="bothRoute"
                                    label={
                                        <Typography
                                            style={{
                                                fontSize: "1.3rem",
                                                fontFamily: "Mukta, sans-serif",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Combo Plan and Flown
                                        </Typography>
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                />
                                {/* <CustomRadio value="greatCircle" label="ROUTE STRING" style={{ 'whiteSpace': 'nowrap' }} /> */}
                                {/* <CustomRadio value="overall" label="Overall Basis" disabled /> */}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* {
            (props.isRouteStringBasis || props.isFlownRouteBasis || props.isBothRouteBasis) && 
            <Grid md item className={classes.controlSection}>
            <Typography variant="h6" component="h2" style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }} >
              Please Select Origin To Destination
            </Typography>
            <Grid container>
              <Grid xs item>
                <FormControl fullWidth>
                  {
                    props.americalAirlineArray &&
                    <>
                      <label className={classes.select} >Origin-Destination</label>
                      <select value={props.selectedValue} onChange={props.handleOriginToDestination}>
                        {props.americalAirlineArray.map((option) => (
                          <option value={option.originToDestination}>{option.originToDestination ? option.originToDestination : "Please Select"}</option>
                        ))}
                      </select>
                    </>
                  }
                </FormControl>
              </Grid>
              <Grid xs item>
                <TextField
                  id="outlined-read-only-input"
                  label="AIRCRAFT MODEL"
                  value={props.aircraftModel}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.select,
                  }}
                />
              </Grid>
              <Grid xs item>
                <TextField
                    id="outlined-read-only-input"
                    label="MTOW (KG)"
                    value={props.mtow ? props.mtow : ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.select,
                    }}
                  />
              </Grid>
            </Grid>
          </Grid>
          } */}
                    {(props.isRouteBasis || props.isGreatCircleBasis) && (
                        <Grid md item className={classes.controlSection}>
                            <Typography
                                variant="h6"
                                component="h2"
                                style={{
                                    fontSize: "1.2rem",
                                    fontFamily: "Mukta, sans-serif",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                A/C Specification
                            </Typography>
                            <Grid container>
                                <Grid xs item>
                                    <FormControl fullWidth>
                                        {props.aircrafts && (
                                            <VirtualizedAutocompleteAircraftTypes
                                                value={props.aircraft}
                                                options={props.aircrafts}
                                                label={
                                                    <Typography
                                                        className={
                                                            classes.select
                                                        }
                                                    >
                                                        AIRCRAFT MODEL
                                                    </Typography>
                                                }
                                                onChange={
                                                    props.handleAircraftModelChange
                                                }
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs item>
                                    <div style={{ display: "flex" }}>
                                        <TextField
                                            id="outlined-read-only-input"
                                            label={`MTOW (${props.lbsKgValue})`}
                                            value={
                                                props.lbsKgValue === "KG"
                                                    ? props.mtowValue === ""
                                                        ? props.mtowValue
                                                        : parseInt(
                                                              props.mtowValue
                                                          ).toLocaleString()
                                                    : props.mtowValue === ""
                                                    ? props.mtowValue
                                                    : parseInt(
                                                          (
                                                              props.mtowValue *
                                                              2.2
                                                          ).toFixed(0)
                                                      ).toLocaleString()
                                            }
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            type="text"
                                            onChange={props.handleMtowValue}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.select,
                                            }}
                                        ></TextField>
                                        {/* <CompareArrowsIcon style={{cursor: "pointer"}} onClick={props.changeWeight} titleAccess="ABC" /> */}
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                marginLeft: "70px",
                                                marginTop: "-3px",
                                            }}
                                            onClick={props.changeWeight}
                                            title="Click to change KG to LB or LB to KG"
                                        >
                                            <CompareArrowsIcon />
                                        </div>
                                    </div>
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "11px",
                                        }}
                                    >
                                        Click to change KG to LB or LB to KG
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {(props.isRouteStringBasis ||
                        props.isFlownRouteBasis ||
                        props.isBothRouteBasis) && (
                        <Grid md item className={classes.controlSection}>
                            <Typography
                                variant="h6"
                                component="h2"
                                style={{
                                    fontSize: "1.2rem",
                                    fontFamily: "Mukta, sans-serif",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Airline Reference
                            </Typography>
                            <Grid container>
                                <Grid xs item>
                                    <FormControl fullWidth>
                                        {props.airlinesOfTheWorlds && (
                                            <VirtualizedAirlinesAutocomplete
                                                value={props.airlineValue}
                                                options={
                                                    props.airlinesOfTheWorlds
                                                }
                                                label={
                                                    <Typography
                                                        className={
                                                            classes.select
                                                        }
                                                    >
                                                        AIRLINE
                                                    </Typography>
                                                }
                                                onChange={
                                                    props.handleAirlineChange
                                                }
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs item>
                                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                                        <DateTimePanel
                                            selectedDate={props.selectedDate}
                                            handleDateChange={
                                                props.handleDateChange
                                            }
                                            useAllDates={props.useAllDates}
                                            setUseAllDates={
                                                props.setUseAllDates
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {(props.isRouteStringBasis ||
                        props.isFlownRouteBasis ||
                        props.isBothRouteBasis) && (
                        <Grid md item className={classes.controlSection}>
                            <div>
                                {props.handleLoader ? (
                                    <div>
                                        <CircularProgress
                                            size={70}
                                            left={-20}
                                            top={10}
                                            style={{
                                                marginLeft: "50%",
                                                marginTop: "40px",
                                            }}
                                        />
                                        <span style={{ fontSize: "2rem" }}>
                                            Loading
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display:
                                                props.fliteTailNumber.length > 0
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            style={{
                                                fontSize: "1.2rem",
                                                fontFamily: "Mukta, sans-serif",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Route Lookup
                                        </Typography>
                                        <Grid container>
                                            <Grid xs item>
                                                <FormControl fullWidth>
                                                    {props.fliteTailNumber && (
                                                        <VirtualizedTailNumberOriginAutocomplete
                                                            value={
                                                                props.tailOrigin
                                                            }
                                                            options={
                                                                props.tailOriginOptions
                                                            }
                                                            label={
                                                                <Typography
                                                                    className={
                                                                        classes.select
                                                                    }
                                                                >
                                                                    Origin
                                                                </Typography>
                                                            }
                                                            onChange={
                                                                props.handleTaiNumberOriginChange
                                                            }
                                                        />
                                                    )}
                                                </FormControl>
                                                {props.tailOrigin &&
                                                    props.tailDestination && (
                                                        <TextField
                                                            id="outlined-read-only-input"
                                                            label="Aircraft Model"
                                                            value={
                                                                props.aircraftModel
                                                                    ? props.aircraftModel
                                                                    : ""
                                                            }
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                className:
                                                                    classes.select,
                                                            }}
                                                        />
                                                    )}
                                            </Grid>
                                            <Grid xs item>
                                                <FormControl fullWidth>
                                                    {props.fliteTailNumber &&
                                                        props.tailOrigin && (
                                                            <VirtualizedTailNumberDestinationAutocomplete
                                                                value={
                                                                    props.tailDestination
                                                                }
                                                                options={
                                                                    props.tailDestinationOptions
                                                                }
                                                                label={
                                                                    <Typography
                                                                        className={
                                                                            classes.select
                                                                        }
                                                                    >
                                                                        Destination
                                                                    </Typography>
                                                                }
                                                                onChange={
                                                                    props.handleTaiNumberDestChange
                                                                }
                                                            />
                                                        )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {props.fliteTailNumber && (
                                    <div
                                        style={{
                                            display:
                                                props.fliteTailNumber.length ===
                                                0
                                                    ? "block"
                                                    : "none",
                                            marginTop: "50px",
                                        }}
                                    >
                                        <h3>No City Pair Combination Found</h3>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    )}
                    {/* {props.isRouteBasis && <Grid md item className={classes.controlSection}>
            <Typography variant="h6" component="h2" style={{ fontSize: '1.2rem', fontFamily: 'Mukta, sans-serif', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              City Pair Specification
            </Typography>
            <Grid container>
              <Grid xs item>
                <FormControl fullWidth>
                  <InputLabel className={classes.select} shrink>ORIGIN</InputLabel>
                  <Select
                    value={props.origin}
                    displayEmpty
                    onChange={props.handleOriginChange}
                  >
                    {props.origins &&
                      props.origins.map((org) => (
                        <MenuItem key={org.dep_apt} value={org.dep_apt} style={{ fontFamily: 'Mukta, sans-serif', whiteSpace: 'nowrap' }}>{org.dep_apt_iata}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs item className={classes.select}>
                <FormControl fullWidth>
                  <InputLabel shrink className={classes.select}>DESTINATION</InputLabel>
                  <Select
                    value={props.dest}
                    displayEmpty
                    onChange={props.handleDestinationChange}
                  >
                    {props.destinations &&
                      props.destinations.map((dest) => (
                        <MenuItem key={dest.arr_apt} value={dest.arr_apt} style={{ fontFamily: 'Mukta, sans-serif', whiteSpace: 'nowrap' }}>{dest.arr_apt_iata}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>} */}
                    {props.isGreatCircleBasis && (
                        <Grid md item className={classes.controlSection}>
                            <Typography
                                variant="h6"
                                component="h2"
                                style={{
                                    fontSize: "1.2rem",
                                    fontFamily: "Mukta, sans-serif",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                IATA Airport Specification
                            </Typography>
                            <Grid container>
                                <Grid xs item>
                                    <FormControl fullWidth>
                                        {props.originAirports && (
                                            <VirtualizedAutocomplete
                                                value={props.originAirport}
                                                options={props.originAirports}
                                                label={
                                                    <Typography
                                                        className={
                                                            classes.select
                                                        }
                                                    >
                                                        ORIGIN
                                                    </Typography>
                                                }
                                                onChange={
                                                    props.handleOriginAirportChange
                                                }
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs item>
                                    <FormControl fullWidth>
                                        {props.destinationAirports && (
                                            <VirtualizedAutocomplete
                                                value={props.destAirport}
                                                options={
                                                    props.destinationAirports
                                                }
                                                label={
                                                    <Typography
                                                        className={
                                                            classes.select
                                                        }
                                                    >
                                                        DESTINATION
                                                    </Typography>
                                                }
                                                onChange={
                                                    props.handleDestinationAirportChange
                                                }
                                                className={classes.select}
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {(props.isRouteStringBasis ||
                        props.isFlownRouteBasis ||
                        props.isBothRouteBasis) &&
                    !props.handleLoader ? (
                        <Grid
                            md
                            item
                            className={classes.controlSection}
                            style={{
                                visibility:
                                    props.fliteTailNumber.length > 0
                                        ? "visible"
                                        : "hidden",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h2"
                                style={{
                                    fontSize: "1.2rem",
                                    fontFamily: "Mukta, sans-serif",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Route String Input
                            </Typography>
                            <Grid container>
                                <Grid xs item>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={props.routestring}
                                            onChange={props.handleInputChange}
                                            variant="outlined"
                                            label="Enter your Route String"
                                        ></TextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography
                                variant="h6"
                                component="h2"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Mukta, sans-serif",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Please include origin and destination airport
                                (ex: KJFK..WAVEY..DIZNY..ZQA..MKJP)
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </TabPanel>
            <TabPanel value={props.index} index={1} dir={theme.direction}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Grid md={4} item className={classes.controlSection}>
                        <Grid container>
                            <Grid xs item>
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink
                                        className={classes.select}
                                    >
                                        COUNTRY
                                    </InputLabel>
                                    <Select
                                        value={props.country}
                                        displayEmpty
                                        onChange={props.handleCountryChange}
                                        style={{
                                            fontFamily: "Mukta, sans-serif",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {props.countries &&
                                            props.countries.map((obj) => (
                                                <MenuItem
                                                    key={obj.country}
                                                    value={obj.country}
                                                    style={{
                                                        fontFamily:
                                                            "Mukta, sans-serif",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {obj.country}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs item>
                                <FormControl fullWidth>
                                    <InputLabel
                                        shrink
                                        className={classes.select}
                                    >
                                        DATE
                                    </InputLabel>
                                    <Select displayEmpty>
                                        <MenuItem
                                            key="JAN, 2021"
                                            value="JAN, 2021"
                                        >
                                            JAN, 2021
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>
        </Paper>
    );
}

export default RouteFilter;
