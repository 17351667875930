import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import invoiceViewerData from "../../mocks/InvoiceViewerData";
import InvoiceTableRow from "./InvoiceTableRow";

const useStyles = makeStyles({
  root: {
      "& .MuiTableCell-head": {
          color: "white",
          backgroundColor: "black"
      },
  }
});

function InvoiceTable () {
  const classes = useStyles();

  const invoiceTableData = invoiceViewerData.map((data) => {return <InvoiceTableRow invoiceData={data}/>});
  return (
    <>
    <TableContainer>
        <Table>
          <TableHead>
              <TableRow className={classes.root}>
                  <TableCell align="center">
                      <span>State</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>ANSP</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Type Of Charge</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Period</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Currency</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Billed Amount</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Equiv USD</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Exch Rate</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Billed Date</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Due Date</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Interest</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Email</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Contact</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Phone #</span>
                  </TableCell>
                  <TableCell align="center">
                      <span>Overdue?</span>
                  </TableCell>
              </TableRow>
              
          </TableHead>
          <TableBody>
              {invoiceTableData}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default InvoiceTable;