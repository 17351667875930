const ectlReasonCodes = [
  {
    codeNumber: 10,
    reason: "Cancelled Flight",
    mandatory: `Copy of <b>CNL</b> message`,
    optional: ""
  },
  {
    codeNumber: 11,
    reason: `Unknown Flight`,
    mandatory: "",
    optional: ""
  },
  {
    codeNumber: 12,
    reason: "Incorrect Aircraft Type",
    mandatory: `Correct Aircraft Type and A/C Registration marks.`,
    optional: ""
  },
  {
    codeNumber: 13,
    reason: "Incorrect ADEP",
    mandatory: `Correct <b>ADEP</b>, <b>EOBD</b>, <b>IFPLID</b> or copy of last agreed <b>FPL</b>.`,
    optional: ""
  },
  {
    codeNumber: 14,
    reason: "Incorrect ADES",
    mandatory: `<u>In the case of INCORRECT AERODROME:</u>
      Correct <b>ADES</b> and <b>IFPLID</b>.
      <u>In the case of a DIVERSION:</u>
      Correct <b>ADES</b>, <b>ATA</b> and the route description 
      from the point of diversion supported by 
      relevant documentation.`,
    optional: ""
  },
  {
    codeNumber: 15,
    reason: "VFR Flight",
    mandatory: ``,
    optional: ""
  },
  {
    codeNumber: 16,
    reason: "Training Flight",
    mandatory: `Copy of last agreed <b>FPL</b>, names of instructor and student, number of passengers on board.`,
    optional: ""
  },
  {
    codeNumber: 18,
    reason: "Other exemptions",
    mandatory: `Copy of last agreed FPL.`,
    optional: ""
  },
  {
    codeNumber: 40,
    reason: "Duplicate flight",
    mandatory: `Line of the correct flight (i.e. pro forma line number).`,
    optional: ""
  },
  {
    codeNumber: 61,
    reason: "Transfer Of Liability",
    mandatory: `Aircraft Registration. All contact details of new owner or operator. Start date and end date of period claimed.`,
    optional: "Copy of sales or leasing contract."
  },
  {
    codeNumber: 62,
    reason: "Incorrect Weight Coefficient",
    mandatory: `Aircraft Type and Registration. Start date and end date of period claimed. Correct <b>MTOW</b>. Copy of full Aircraft Flight Manual (AFM).`,
    optional: ""
  },
  {
    codeNumber: 70,
    reason: "Incorrect FPL “Route” (airports of departure and arrival correctly identified on the bill.)",
    mandatory: `Copy of last agreed <b>FPL</b> and specific reason for claim. Provide correct data.`,
    optional: ""
  },
  {
    codeNumber: 90,
    reason: "VAT",
    mandatory: `EU VAT Identification Number or an original, duly certified and stamped document from your National Tax Authority confirming your VAT registration number, or your equivalent fiscal registration number or your tax payer number.`,
    optional: ""
  }
]

export default ectlReasonCodes;