// ------------- REACT -------------
import React from "react"

// ------------- MUI -------------
import { Icon } from "@material-ui/core";

// ------------- AXIOS -------------
import axios from "../../axios-usercharges";

// ------------- LOGOS -------------
import docusignImage from "../../assets/logo/docusign_logo.png";

function PaymentApprovalForm (props) {
  const {data, anspData} = props;
  

  const filteredResponse = data
  ? data
      .map((item) => {
        return item;
      })
      .filter((item) =>
        [
          "Supplier Name",
          "Invoice Number",
          "Invoice Date",
          "Address",
          "Country",
          "Total Due",
        ].includes(item.field_name)
      )
  : [];

  const groupedData = {};
  filteredResponse.forEach((item) => {
    if (!groupedData[item.field_name]) {
      groupedData[item.field_name] = [];
    }
    groupedData[item.field_name].push(item.field_value);
  });

  // Attempt at docusign functionality TODO: get this working later
  const requestSignature = async (country) => {
    try {
        const response = await axios.get("/api/docusign");
        console.log(response);
    } catch (error) {
        console.error("Error requesting signature", error);
    }
};

  return (
    <>
      
      <div style={{ margin: '20px' }}>
          <div>
              <div style={{ margin: '0 auto', width: '95%', margin: 'auto'}}>
                  <div
                      style={{
                          marginTop: '15px',
                          backgroundColor: '#180aa2',
                          fontSize: '22px',
                          padding: '5px 20px',
                          color: 'white',
                          width: 'fit-content'
                      }}
                  >
                      <span>Payment Info</span>
                  </div>
                  <div
                      style={{
                          marginTop: '15px',
                          padding: '25px'
                      }}
                  >
                      <div
                          style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                      >
                          <div
                              style={{
                                  display: 'flex',
                                  alignItems: 'center',
                              }}
                          >
                              <span style={{ fontSize: '18px' }}>Vendor Name:</span>
                              <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}>{anspData.ansp}</span>
                          </div>
                          <div

                              style={{
                                  display: 'flex',
                                  alignItems: 'center',
                              }}
                          >
                              <span style={{ fontSize: '18px' }}>Invoice Number:</span>
                              <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}>{groupedData["Invoice Number"]}</span>
                          </div>
                          <div

                              style={{
                                  display: 'flex',
                                  alignItems: 'center',
                              }}
                          >
                              <span style={{ fontSize: '18px' }}>Invoice Date:</span>
                              <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}>{groupedData["Invoice Date"]}</span>
                          </div>
                      </div>
                      <div

                          style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '20px'
                          }}
                      >
                          <div

                              style={{
                                  display: 'flex',
                                  alignItems: 'center'
                              }}
                          >

                              <span style={{ fontSize: '18px' }}>Vendor Address:</span>
                              <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}> 
                                {anspData.vendor_address_1}
                                {anspData.vendor_address_2 && " (" + anspData.vendor_address_2 + ")"}
                              </span>
                          </div>

                      </div>
                      <div
                      style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '20px'
                      }}
                      >
                      <div

                          style={{
                              display: 'flex',
                              alignItems: 'center'
                          }}
                      >

                          <span style={{ fontSize: '18px' }}>Description:</span>
                          <span style={{ fontSize: '18px', marginLeft: '50px', borderBottom: '2px solid blue' }}> 
                            {(anspData && groupedData) &&`${anspData.ansp} Nav Charges: 
                            (${new Date(new Date(groupedData["Invoice Date"]).getFullYear(), new Date(groupedData["Invoice Date"]).getMonth(), 1).toLocaleDateString("en-us")} - 
                            ${new Date(new Date(groupedData["Invoice Date"]).getFullYear(), new Date(groupedData["Invoice Date"]).getMonth() + 1, 0).toLocaleDateString("en-us")})`}
                          </span>
                      </div>

                      </div>
                  </div>
                  {/*Flight Validation Section*/}
                  <div
                      style={{
                          marginTop: '15px',
                          backgroundColor: '#180aa2',
                          fontSize: '22px',
                          padding: '5px 20px',
                          color: 'white',
                          width: 'fit-content'
                      }}
                  >
                      <span>Account Allocation</span>
                      
                  </div>
                  <table style={{border: "1px solid black", width: "100%", borderCollapse: "collapse", margin: "10px 0"}}>
                    <thead style={{border: "1px solid black"}}>
                      <tr>
                        <th style={{border: "1px solid black", width:"5%", fontSize: '18px' }}>Station</th>
                        <th style={{border: "1px solid black", width:"15%", fontSize: '18px' }} >22 Digit Code</th>
                        <th style={{border: "1px solid black", width:"25%", fontSize: '18px' }} >Oracle Code</th>
                        <th style={{border: "1px solid black", width:"15%", fontSize: '18px' }} >Amount</th>
                        <th style={{border: "1px solid black", width:"5%", fontSize: '18px' }} >Per</th>
                        <th style={{border: "1px solid black", width:"15%", fontSize: '18px' }} >GL Description</th>
                        <th style={{border: "1px solid black", width:"20%", fontSize: '18px' }} >Airport Name</th>
                      </tr>
                      <tr>
                        <td style={{width:"5%", fontSize: '18px' }}>LAT</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} >5010.5370101999.27003150</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} >5270101.0000.0914331.5003.1100.0000.0000</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} >{groupedData["Total Due"]}</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} >0423</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} >20230505DRLAT</td>
                        <td style={{borderLeft: "1px solid black", fontSize: '18px' }} ></td>
                      </tr>
                      <tr>
                        <td style={{borderTop: "1px solid black", fontSize: '18px' }} colSpan={7}>SUBTOTAL: {groupedData["Total Due"]}</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '18px' }} colSpan={7}>FX RATE: 1.0000000 Per USD (Corp. Fx Rate)</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '18px' }} colSpan={7}>TOTAL: {groupedData["Total Due"]}</td>
                      </tr>
                    </thead>
                  </table>
                  <div
                      style={{
                          marginTop: '15px',
                          backgroundColor: '#180aa2',
                          fontSize: '22px',
                          padding: '5px 20px',
                          color: 'white',
                          width: 'fit-content'
                      }}
                  >
                      <span>Signature Approvals</span>
                  </div>
                  <div style={{marginTop: "60px", display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", gap: "30px", alignItems: "end"}}>
                      <div>
                        <div>
                          <Icon>
                            <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                          </Icon>
                        </div>
                        <div style={{borderTop: "1px solid black", width: "400px", fontSize: '18px' }}>Heins, Joseph - VIP Network Operations</div>
                      </div>
                      <div>
                        <div style={{ fontSize: '18px' }}>U171148</div>
                        <div style={{borderTop: "1px solid black", width: "100px", fontSize: '18px' }}>Employee #</div>
                      </div>
                      <div>
                        <div style={{ fontSize: '18px' }}>-</div>
                        <div style={{borderTop: "1px solid black", width: "100px", fontSize: '18px' }}>Date</div>
                      </div>
                    </div>
                    <div style={{display: "flex", gap: "30px", alignItems: "end"}}>
                      <div>
                        <div>
                          <Icon>
                            <img src={docusignImage} alt="docusign" height={"40px"} width={"40px"}/>
                          </Icon>
                        </div>
                        <div style={{borderTop: "1px solid black", width: "400px", fontSize: '18px' }}>Lichtenwalter, Barb -Sr.Analyst- FP&A</div>
                      </div>
                      <div>
                        <div style={{ fontSize: '18px' }}>U171148</div>
                        <div style={{borderTop: "1px solid black", width: "100px", fontSize: '18px' }}>Employee #</div>
                      </div>
                      <div>
                        <div style={{ fontSize: '18px' }}>-</div>
                        <div style={{borderTop: "1px solid black", width: "100px", fontSize: '18px' }}>Date</div>
                      </div>
                    </div>
                  </div>
                  
              </div>
          </div>
      </div>
  </>
  );
}

export default PaymentApprovalForm;