import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import axios from '../../axios-usercharges';
import './OCR.css';

const DataExtractFromXtracta = ({ documentId }) => {
    const [response, setResponse] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiResponse = await axios.post(`/api/retrieve-data`, { document_id: documentId });

                setResponse(apiResponse.data);
                setError(null);
                await insertData(apiResponse.data);
                console.log("apiResponse.data", apiResponse.data);
            } catch (err) {
                console.error('API Error:', err);
                setError(err.message);
                setResponse([]);
            }
        };

        const insertData = async (data) => {
            try {
              const filteredData = data.filter((item) => {
                return ["Charge Type", "No Charges", "Base Rate", "Rate Adjustment", "Total", "Supplier Name", "Invoice Number", "Invoice Date", "Address", "Country", "GST", "QST", "HST", "Total Due", "Date", "Charge Date", "Flight ID", "Org", "Dest", "Type", "MTOW", "KM", "Terminal GST", "Terminal QST", "Terminal HST", "Flight Total Charge", "Flight Total Sum"].includes(item.field_name);
              });
              console.log("filteredDatafilteredData", filteredData);
          
              await axios.post(`/api/insert-data`, { data: filteredData });
            } catch (err) {
              console.error('Data Insertion API Error:', err);
            }
          };
          


        fetchData();

        return () => {
        };
    }, [documentId]);

    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(inputDate).toLocaleDateString(undefined, options);
    }

    const tableData = response.filter((item) => {
        return ["Charge Type", "No Charges", "Base Rate", "Rate Adjustment", "Total"].includes(item.field_name)
    });


    const groupedData = {};
    tableData.forEach((item) => {
        if (!groupedData[item.field_name]) {
            groupedData[item.field_name] = [];
        }
        groupedData[item.field_name].push(item.field_value);
    });

    const staticSupplierName = "FliteX";

    const filteredResponse = response.map((item) => {
        if (item.field_name === "Supplier Name") {
            item.field_value = staticSupplierName;
        }
        return item;
    }).filter((item) =>
        ["Supplier Name", "Invoice Number", "Invoice Date", "Address", "Country", "GST", "QST", "HST", "Total Due"].includes(item.field_name)
    );


    const duplicateData = response.filter((item) =>
        ["Date", "Charge Date", "Flight ID", "Org", "Dest", "Type", "MTOW", "KM", "Terminal GST", "Terminal QST", "Terminal HST", "Flight Total Charge", "Flight Total Sum"].includes(item.field_name)
    );

    const rows = [];
    for (let i = 0; i < duplicateData.length; i += 13) {
        const rowData = duplicateData.slice(i, i + 13);
        const cells = rowData.map((item) => {
            if (item.field_name === "Flight ID" && item.field_value.length >= 3) {
                const replacedFlightID = "FLX" + item.field_value.slice(3);
                return <td key={item.field_id}>{replacedFlightID}</td>;
            } else if (item.field_name === "Date" || item.field_name === "Charge Date") {
                // Format the "Date" and "Charge Date" fields
                const formattedDate = formatDate(item.field_value);
                return <td key={item.field_id}>{formattedDate}</td>;
            } else {
                // Display "-" for fields with no value
                return <td key={item.field_id}>{item.field_value ? item.field_value : "-"}</td>;
            }
        });
        rows.push(<tr key={`row-${i}`}>{cells}</tr>);
    }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return (
        <>
            <div className="retrieve-data-container">
                <h2>Invoice Detail :-</h2>
                <div className="table-container customTable">
                    <table>
                        <thead>
                            <tr>
                                {filteredResponse.map((item) => (
                                    <th key={item.field_id}>{item.field_name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {filteredResponse.map((item) => (
                                    <td key={item.field_id}>
                                        {item.field_name === "Invoice Date"
                                            ? formatDate(item.field_value)
                                            : item.field_value}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-container customTable">
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(groupedData).map((fieldName) => (
                                    <th key={fieldName}>{fieldName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {Object.values(groupedData).map((fieldValues, index) => (
                                    <td key={index}>
                                        {fieldValues.map((value, subIndex) => (
                                            <div key={subIndex}>{value}</div>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-container customTable" style={{ flexDirection: "column" }}>
                    <table>
                        <thead>
                            <tr>
                                {["Date", "Charge Date", "Flight ID", "Org", "Dest", "Type", "MTOW", "KM", "Terminal GST", "Terminal QST", "Terminal HST", "Flight Total Charge", "Flight Total Sum"].map((fieldName) => (
                                    // <th key={fieldName}>{fieldName}</th>
                                    <th key={fieldName}>{fieldName === "Flight Total Sum" ? "Charge Rate" : fieldName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.slice(startIndex, endIndex)}
                        </tbody>
                    </table>
                    <TablePagination
                        component="div"
                        count={rows.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
};

export default DataExtractFromXtracta;








// This is real original data code //
// -----------------------------------//

// import React, { useState, useEffect } from 'react';
// import TablePagination from '@mui/material/TablePagination';
// import axios from '../../../axios-usercharges';

// const RetrieveDataComponent = ({ documentId }) => {
//     const [response, setResponse] = useState([]);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const apiResponse = await axios.post(`/api/retrieve-data`, { document_id: documentId }, {
//                 });

//                 setResponse(apiResponse.data);
//                 setError(null);
//             } catch (err) {
//                 console.error('API Error:', err);
//                 setError(err.message);
//                 setResponse([]);
//             }
//         };

//         fetchData();

//         return () => {
//         };
//     }, [documentId]);


//     const filteredResponse = response.filter((item) =>
//         ["Supplier Name", "Invoice Number", "Invoice Date", "Address", "Country", "Total Due", "Total"].includes(item.field_name)
//     );

//     const duplicateData = response.filter((item) =>
//         ["Date", "Flight ID", "Org", "Dest", "Type", "MTOW", "Terminal GST", "Terminal QST", "Flight Total Charge", "Flight Total Sum"].includes(item.field_name)
//     );

//     const rows = [];
//     for (let i = 0; i < duplicateData.length; i += 10) {
//         const rowData = duplicateData.slice(i, i + 10);
//         const cells = rowData.map((item) => (
//             <td key={item.field_id}>{item.field_value}</td>
//         ));
//         rows.push(<tr key={`row-${i}`}>{cells}</tr>);
//     }

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     const startIndex = page * rowsPerPage;
//     const endIndex = startIndex + rowsPerPage;

//     return (
//         <>
//             <div className="retrieve-data-container">
//                 <h2>Nav Canada Invoice Detail</h2>
//                 <div className="table-container">
//                     <table>
//                         <thead>
//                             <tr>
//                                 {filteredResponse.map((item) => (
//                                     <th key={item.field_id}>{item.field_name}</th>
//                                 ))}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 {filteredResponse.map((item) => (
//                                     <td key={item.field_id}>{item.field_value}</td>
//                                 ))}
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>

//                 <div className="table-container" style={{ flexDirection: "column" }}>
//                     <table>
//                         <thead>
//                             <tr>
//                                 {["Date", "Flight ID", "Org", "Dest", "Type", "MTOW", "Terminal GST", "Terminal QST", "Flight Total Charge", "Flight Total Sum"].map((fieldName) => (
//                                     <th key={fieldName}>{fieldName}</th>
//                                 ))}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {rows.slice(startIndex, endIndex)}
//                         </tbody>
//                     </table>
//                     <TablePagination
//                         component="div"
//                         count={rows.length}
//                         page={page}
//                         onPageChange={handleChangePage}
//                         rowsPerPage={rowsPerPage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}
//                     />
//                 </div>
//             </div>
//         </>
//     );
// };

// export default RetrieveDataComponent;



