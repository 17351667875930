import * as React from 'react';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        left: '-900px',
        width: '800px',
        height: '600px'
    }
}));
const MapContainer = React.forwardRef((props,ref) => {
    const classes = useStyles();
    const json = JSON.stringify(props.geoJSON);
    const src = `https://api.mapbox.com/styles/v1/urmjs/cka49y5pt16n71io7t5kh9fpx/static/geojson(${json})/auto/800x600?access_token=pk.eyJ1IjoidXJtanMiLCJhIjoiY2thNGFrNm93MDcyOTNlbWJxczJ6Ym96aCJ9.cFm84e8mjb9O376GdeS5uQ`;
  return (
    <img ref={ref}  src={src} className={classes.root} alt="Route map"/>
  );
});

export default MapContainer;