// ------------- REACT -------------
import React, { useState } from "react";

// --- ROUTER DOM ---
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// ---  CUSTOM HOOKS ---
import useFetch from "../../hooks/useFetch";

// ------------- MUI -------------
import { ListItemButton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Grid,
    IconButton,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemText,
    Box,
    ListItemIcon,
    Avatar,
    Toolbar,
    AppBar,
    Icon,
    Menu,
    MenuItem,
} from "@material-ui/core";

// --- MUI ICONS ---
import DashboardIcon from "@mui/icons-material/Dashboard";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import TimerIcon from "@mui/icons-material/Timer";
import CalculateIcon from "@mui/icons-material/Calculate";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PublicIcon from "@mui/icons-material/Public";
import ContactsIcon from "@mui/icons-material/Contacts";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AssignmentIcon from "@mui/icons-material/Assignment";

// ------------- COMPONENTS -------------
import CurrentDateTime from "../Utility/CurrentTimeDate";

// ------------- LOGOS -------------
import FliteXLogo from "../../assets/logo/new_flitex_logo.jpg";
import ualLogo from "../../assets/logo/ual_logo.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 300,
        "& .MuiButton-root": {
            fontSize: "12px",
        },
    },
    activeMenuItem: {
        color: "white",
        backgroundColor: "#4287f5",
        fontWeight: 700,
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: "0.9rem",
        textAlign: "center",
    },
    activeMenuIcon: {
        color: "white",
    },
    logoRoot: {
        display: "flex",
        width: "150px",
        height: "50px",
        background: "white",
        left: "50%",
        top: "-15px",
        justifyContent: "center",
        paddingRight: "400px",
    },
    ualLogoRoot: {
        width: "350px",
        height: "50px",
        marginLeft: "50px",
        display: "flex",
        alignItems: "center",
    },
    imgStyle: {
        marginTop: "25px",
    },
    headingContainer: {
        position: "relative",
    },
    loginDetails: {
        position: "absolute",
        right: "10px",
        top: "5px",
        color: "grey",
        fontSize: "1rem",
    },
    typography: {
        marginTop: "12px",
        position: "absolute",
        color: "grey",
        right: "250px",
    },
    brandName: {
        textAlign: "center",
        fontFamily: "'Mukta', sans-serif",
        color: "#5590d0",
        marginTop: "6px",
        paddingLeft: "10px",
    },
    sideBarHeading: {
        fontFamily: "'Mukta', sans-serif",
        color: "grey",
        padding: "0px 10px",
    },
    appBar: {
        display: "flex",
        justifyContent: "space-between",
        height: "50px",
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: theme.mixins.toolbar,
}));

const drawerWidth = 240;

// --------- DRAWER MENU ITEMS ----------
const menuSectionA = [
    { name: "Dashboard", path: "/", indexVal: 1, icon: <DashboardIcon /> },
    {
        name: "planROUTE",
        path: "/flitegains",
        indexVal: 2,
        icon: <PublicIcon />,
    },
    {
        name: "flownROUTE",
        path: "/flownroute",
        indexVal: 3,
        icon: <PublicIcon />,
    },
    {
        name: "OFL Audit",
        path: "/betaInvoicing",
        indexVal: 4,
        icon: <TimerIcon />,
    },
    {
        name: "Calculations",
        path: "/calculations",
        indexVal: 5,
        icon: <CalculateIcon />,
    },
    {
        name: "Invoice List",
        path: "/invoice_viewer",
        indexVal: 6,
        icon: <FormatListBulletedIcon />,
    },
    //{ name: "Invoices ARM", path: "/invoicesarm", indexVal: 7, icon: <FormatListBulletedIcon/> },
];

const menuSectionB = [
    { name: "Claims", path: "/ocr-data", indexVal: 7, icon: <ReceiptIcon /> },
    { name: "ANPA", path: "/anpa", indexVal: 8, icon: <AssignmentIcon /> },
    { name: "EFTR", path: "/eftr", indexVal: 9, icon: <AssignmentIcon /> },
];

const menuSectionC = [
    {
        name: "Aircraft",
        path: "/aircraft",
        indexVal: 10,
        icon: <AirplanemodeActiveIcon />,
    },
    {
        name: "Contacts",
        path: "/contacts",
        indexVal: 11,
        icon: <ContactsIcon />,
    },
];

export default function Navbar(props) {
    const classes = useStyles();
    const history = useHistory();

    // ------------- STATES -------------
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState();

    const [userRes, userError, userIsLoading, fetchUser] = useFetch(
        {},
        {
            method: "get",
            url: "/api/user",
        }
    );

    const recordAvatarButtonPosition = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    let closeMenu = () => {
        setMenuOpen(false);
    };

    const handleLogoutClick = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("BetaInvoice");
        history.push("/");
    };

    return (
        <div>
            {/* --- TOP APPBAR --- */}
            <AppBar
                position="fixed"
                elevation={1}
                className={classes.appBar}
                style={{ background: "white" }}
            >
                <div className={classes.appBar}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h4"
                            component="h1"
                            className={classes.brandName}
                        >
                            fliteGAINS
                        </Typography>
                         {/* 
                        <Icon classes={{ root: classes.ualLogoRoot }}>
                            <img
                                alt="UAL"
                                src={ualLogo}
                                width="80%"
                                height="95%"
                            />
                        </Icon>
                        */}
                    </div>
                    <Icon classes={{ root: classes.logoRoot }}>
                        <img
                            alt="IATA"
                            src={FliteXLogo}
                            width="80%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                        />
                    </Icon>
                    <div className={classes.typography}>
                        <CurrentDateTime />
                    </div>
                    <div classes={classes.headingContainer}>
                        <div className={classes.loginDetails}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item direction="row" alignItems="center">
                                    <IconButton
                                        onClick={recordAvatarButtonPosition}
                                        style={{
                                            height: "40px",
                                            width: "40px",
                                        }}
                                    >
                                        <Avatar>
                                            {userRes.username &&
                                                userRes.username
                                                    .slice(0, 1)
                                                    .toUpperCase()}
                                        </Avatar>
                                    </IconButton>
                                    {/* User Options Dropdown Menu */}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={menuOpen}
                                        onClose={closeMenu}
                                    >
                                        <MenuItem disabled>
                                            {userRes.username}
                                        </MenuItem>
                                        <MenuItem onClick={handleLogoutClick}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </AppBar>
            {/* --- SIDE MENU --- */}
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={classes.sideBarHeading}
                    >
                        FLIGHTS
                    </Typography>
                    <List>
                        {menuSectionA.map((section, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                className={
                                    section.indexVal === props.index
                                        ? classes.activeMenuItem
                                        : ""
                                }
                            >
                                <ListItemButton
                                    component={Link}
                                    to={section.path}
                                >
                                    <ListItemIcon
                                        style={{ minWidth: "30px" }}
                                        className={
                                            section.indexVal === props.index
                                                ? classes.activeMenuIcon
                                                : ""
                                        }
                                    >
                                        {section.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={section.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={classes.sideBarHeading}
                    >
                        CLAIMS
                    </Typography>
                    <List>
                        {menuSectionB.map((section, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                className={
                                    section.indexVal === props.index
                                        ? classes.activeMenuItem
                                        : ""
                                }
                            >
                                <ListItemButton
                                    component={Link}
                                    to={section.path}
                                >
                                    <ListItemIcon
                                        style={{ minWidth: "30px" }}
                                        className={
                                            section.indexVal === props.index
                                                ? classes.activeMenuIcon
                                                : ""
                                        }
                                    >
                                        {section.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={section.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={classes.sideBarHeading}
                    >
                        FLEET
                    </Typography>
                    <List>
                        {menuSectionC.map((section, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                className={
                                    section.indexVal === props.index
                                        ? classes.activeMenuItem
                                        : ""
                                }
                            >
                                <ListItemButton
                                    component={Link}
                                    to={section.path}
                                >
                                    <ListItemIcon
                                        style={{ minWidth: "30px" }}
                                        className={
                                            section.indexVal === props.index
                                                ? classes.activeMenuIcon
                                                : ""
                                        }
                                    >
                                        {section.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={section.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </div>
    );
}
