// ------------- REACT -------------
import React, { useState } from "react";

// ------------- MUI -------------
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,
    TableSortLabel,
    TableHead,
    Paper,
    Button,
} from "@material-ui/core";
import { Tooltip } from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";

// ------------- LUXON -------------
import { DateTime } from "luxon";
import { getTime } from "../../shared/util";

// ------------- COMPONENTS -------------
import FlightRouteTooltipCell from "./FlightRouteTooltipCell";

// ------------- CSS -------------
import "./FlightInfoTable.css";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#d4cffc",
        color: theme.palette.common.black,
        fontWeight: "bold",
        fontSize: 16,
        borderBottomColor: theme.palette.common.black,
        fontFamily: "Mukta",
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#E7E7E7",
        },
    },
}))(TableRow);

const StyledTableSortLabel = withStyles((theme) => ({
    root: {
        "&:hover": {
            color: "#E5176E",
        },
        "&$active": {
            color: theme.palette.common.black,
        },
    },
    active: {},
    icon: {
        color: "#E5176E !important",
    },
}))(TableSortLabel);

const useStyles = makeStyles((theme) => ({
    root: {},
    // TODO: possible figure out better css to limit the height
    container: {},
    paper: {
        margin: "0 auto",
        width: "95%",
        //  width:"90vw"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    liveDataRouteDisplay: {
        maxWidth: "10rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

const getFlightInfoPlusDerived = (f) => {
    const atcCost = Number(
        f.firCalculatedInfo
            .map((fc) => fc.commonUsdCost)
            .reduce((acc, curr) => acc + curr)
    );
    const totalCosts = atcCost;
    return { ...f, atcCost, totalCosts };
};

const isSameDay = (selectedDate, b) => {
    const a = selectedDate;
    b = DateTime.fromISO(b);
    return a.hasSame(b, "year") && a.hasSame(b, "day") && a.hasSame(b, "month");
};

// Need to implement sorting, Route (bolded terms?)
export default function FlightInfoTable({
    handleVerify,
    flightInfo,
    selectedDate,
    useAllDates,
    handleMap,
    dataSourceType,
}) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("totalCosts");
    const [weightType, setWeightType] = useState("LB");

    if (!flightInfo[0]?.isLiveData) {
        flightInfo = flightInfo.map(getFlightInfoPlusDerived);
    } else {
        flightInfo = useAllDates
            ? flightInfo.map(getFlightInfoPlusDerived)
            : flightInfo
                  .filter((f) => isSameDay(selectedDate, f.etd))
                  .map(getFlightInfoPlusDerived);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const descendingComparator = (a, b, orderBy) => {
        let aVal = a[orderBy];
        let bVal = b[orderBy];

        if (orderBy === "mtow") {
            aVal = a.mtow[`MAX_TOW_${weightType}`];
            bVal = b.mtow[`MAX_TOW_${weightType}`];
        }

        if (bVal < aVal) {
            return -1;
        }
        if (bVal > aVal) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const headers = [
        {
            id: "atcCost",
            headerLabel: `ATC COST(USD)`,
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        // { id: 'verifyButton', headerLabel: '', align: 'center', sortable: false, valueKey: null, formatFn: null }, // could possibly use format to return the button
        {
            id: "mapButton",
            headerLabel: "",
            align: "left",
            sortable: false,
            valueKey: null,
            formatFn: null,
            staticOnly: false,
        }, // could possibly use format to return the button
        {
            id: "flightNumber",
            headerLabel: "FLIGHT",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "tailNumber",
            headerLabel: "TAIL#",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "aircraftModel",
            headerLabel: "ACFT",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "std",
            headerLabel: "STD",
            align: "center",
            sortable: true,
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "departure",
            headerLabel: "ORG",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "arrival",
            headerLabel: "DEST",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "eta",
            headerLabel: "STA",
            align: "center",
            sortable: true,
            formatFn: "",
            staticOnly: false,
        },
        // TODO: fix being able to sort by weight (its an object with {MAX_TOW_KG, MAX_TOW_L, valueKey: '', formatFn: ''B})
        {
            id: "mtow",
            headerLabel: "mtow",
            align: "center",
            sortable: false,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "dist_nm",
            headerLabel: "DIST (NM)",
            align: "center",
            sortable: false,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "fuelCost",
            headerLabel: "FUEL COST",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "otherTdvc",
            headerLabel: "OTHER TDVC",
            width: "4px",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
        {
            id: "ovfCosts",
            headerLabel: "OVF COSTS",
            width: "4px",
            align: "center",
            sortable: true,
            valueKey: "",
            formatFn: "",
            staticOnly: true,
        },
        {
            id: "calculated_tnc",
            headerLabel: "CALC TNC",
            align: "center",
            sortable: true,
            valueKey: "totalCosts",
            formatFn: "",
            staticOnly: true,
        },
        {
            id: "actual_tnc",
            headerLabel: "ACTUAL TNC",
            align: "center",
            sortable: true,
            valueKey: "totalCosts",
            formatFn: "",
            staticOnly: true,
        },
        {
            id: "totalCosts",
            headerLabel: "TOTAL COSTS",
            align: "center",
            sortable: true,
            valueKey: "totalCosts",
            formatFn: (cost) => cost.toFixed(2),
            staticOnly: false,
        },
        {
            id: "route",
            headerLabel: "ROUTE",
            align: "center",
            sortable: false,
            valueKey: "",
            formatFn: "",
            staticOnly: false,
        },
    ];

    const EnhancedTableHead = (props) => {
        const { classes, order, orderBy, onRequestSort, dataSourceType } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        const revisedHeaders = headers.filter((h) => {
            if (dataSourceType === 10) {
                return !h.staticOnly;
            } else {
                return true;
            }
        });

        return (
            <TableHead>
                <TableRow>
                    {revisedHeaders.map((h) => (
                        <StyledTableCell
                            key={h.id}
                            align={h.align}
                            sortDirection={orderBy === h.id ? order : false}
                        >
                            {h.sortable ? (
                                <StyledTableSortLabel
                                    hideSortIcon
                                    active={orderBy === h.id}
                                    direction={orderBy === h.id ? order : "asc"}
                                    onClick={createSortHandler(h.id)}
                                >
                                    {h.headerLabel}
                                    {orderBy === h.id ? (
                                        <span
                                            className={classes.visuallyHidden}
                                        >
                                            {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </span>
                                    ) : null}
                                </StyledTableSortLabel>
                            ) : h.headerLabel === "mtow" ? (
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        weightType === "LB"
                                            ? setWeightType("KG")
                                            : setWeightType("LB")
                                    }
                                >
                                    MTOW
                                    <br />
                                    {weightType}
                                </span>
                            ) : (
                                <span>{h.headerLabel}</span>
                            )}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const createFlightNumberCell = (flightNumber, arrivalStatus) => {
        // const { flightNumber, arrivalStatus } = info;
        const backgroundColors = {
            planned: { backgroundColor: "#DAA520", color: "black" },
            arrived: { backgroundColor: "#016401", color: "white" },
            departed: { backgroundColor: "#513ff3", color: "white" },
        };
        const { backgroundColor, color } =
            backgroundColors[arrivalStatus ? arrivalStatus : "planned"];

        return (
            <div
                style={{
                    color,
                    backgroundColor,
                    borderRadius: "25px",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "0px 5px",
                    width: "70px",
                }}
            >
                {flightNumber}
            </div>
        );
    };

    const handleLiveData = (flightInfo) => {
        //const mtowData = flightInfo.mtow
        const ptowData = flightInfo.ptow;
        //const valueOfMtow = parseInt(mtowData).toFixed(0);
        const valueOfPtow = parseInt(ptowData).toFixed(0);

        const mtowData =
            flightInfo.mtow && weightType === "LB"
                ? flightInfo?.mtow.MAX_TOW_LB
                : flightInfo.mtow
                ? flightInfo.mtow.MAX_TOW_KG
                : 0;
        const valueOfMtow = parseInt(mtowData).toFixed(0);

        return {
            ...flightInfo,
            valueOfMtow,
            valueOfPtow,
            calculatedOtherTdvc: 0,
            route: flightInfo.legacy_format,
        };
    };

    const handleSWIMData = (flightInfo, index) => {
        const mtowData =
            flightInfo.mtow && weightType === "LB"
                ? flightInfo?.mtow.MAX_TOW_LB
                : flightInfo.mtow
                ? flightInfo.mtow.MAX_TOW_KG
                : 0;
        const valueOfMtow = parseInt(mtowData).toFixed(0);

        let calculatedOtherTdvc;
        const atdzTime = new Date(flightInfo.eta);
        const atazTime = new Date(flightInfo.std);
        const timeDifferenceInMilliseconds = atdzTime - atazTime;
        // Convert the time difference to hours, minutes, and seconds
        const hours = Math.floor(
            timeDifferenceInMilliseconds / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor(
            (timeDifferenceInMilliseconds % (1000 * 60)) / 1000
        );
        if (flightInfo.tdvcPerHourCost) {
            // Calculate the total minutes from midnight
            const totalMinutes = hours * 60 + minutes;
            // Hourly cost rate
            const hourlyRate = parseInt(flightInfo.tdvcPerHourCost); // Your hourly rate in currency
            // Calculate the cost based on the hourly rate
            calculatedOtherTdvc = (totalMinutes / 60) * hourlyRate;
        } else {
            calculatedOtherTdvc = 0;
        }

        return {
            ...flightInfo,
            valueOfMtow,
            calculatedOtherTdvc,
            fuelCost: flightInfo.firCalculatedInfo[0].fuelCost,
            route: flightInfo.legacy_format,
        };
    };

    const createDefaultRow = (flightInfo, index) => {
        const {
            flight_ref,
            atcCost,
            flightNumber,
            distNm,
            arrivalStatus,
            tailNumber,
            msn,
            aircraftModel,
            std,
            revisedStd,
            departure,
            arrival,
            eta,
            revisedEta,
            valueOfMtow,
            valueOfPtow,
            fuelCost,
            fuelWeight,
            groundDistance,
            gcd,
            esad,
            calculatedOtherTdvc,
            ovfCosts,
            totalCosts,
            altitude,
            tnc,
            route,
        } = flightInfo.isLiveData
            ? handleLiveData(flightInfo)
            : handleSWIMData(flightInfo, index);
        // TODO: ensure that it converts to common currency or backend provides that

        return (
            <StyledTableRow key={flight_ref}>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {parseInt(atcCost.toFixed(0)).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="left"
                >
                    <span
                        style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            fontSize: "14px !important",
                            color: "#bd0026",
                            fontFamily: "Mukta",
                        }}
                        onClick={() => handleVerify(flightInfo)}
                        variant="contained"
                        color="secondary"
                    >
                        <ExploreIcon />
                    </span>
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    <Button
                        style={{
                            backgroundColor: "Transparent",
                            backgroundRepeat: "no-repeat",
                            border: "none",
                            cursor: "pointer",
                            overflow: "hidden",
                            outline: "none",
                            fontFamily: "Mukta",
                        }}
                        onClick={() => handleMap(flightInfo)}
                        variant="contained"
                        color="secondary"
                    >
                        {createFlightNumberCell(
                            "FLX" + flightNumber.slice(3),
                            arrivalStatus
                        )}
                    </Button>
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        tailNumber ? (
                            <Tooltip title={"MSN: " + (msn || "N/A")}>
                                {tailNumber}
                            </Tooltip>
                        ) : (
                            "N/A"
                        )
                    ) : tailNumber ? (
                        tailNumber
                    ) : (
                        "N/A"
                    )}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {aircraftModel}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        <Tooltip
                            title={
                                "Revised ETD: " + getTime(revisedStd) || "N/A"
                            }
                        >
                            {useAllDates ? std : getTime(std)}
                        </Tooltip>
                    ) : useAllDates ? (
                        std
                    ) : (
                        getTime(std)
                    )}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {departure}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {arrival}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        <Tooltip
                            title={
                                "Revised ETA: " + getTime(revisedEta) || "N/A"
                            }
                        >
                            {useAllDates ? std : getTime(eta)}
                        </Tooltip>
                    ) : useAllDates ? (
                        std
                    ) : (
                        getTime(eta)
                    )}
                </StyledTableCell>
                <StyledTableCell
                    style={{
                        fontSize: "16px",
                        width: "4px",
                        fontFamily: "Mukta",
                    }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        <Tooltip
                            title={
                                "PTOW: " +
                                    parseInt(valueOfPtow).toLocaleString() ||
                                "N/A"
                            }
                        >
                            {parseInt(valueOfMtow).toLocaleString()}
                        </Tooltip>
                    ) : (
                        parseInt(valueOfMtow).toLocaleString()
                    )}
                </StyledTableCell>
                <StyledTableCell
                    style={{
                        fontSize: "16px",
                        width: "4px",
                        fontFamily: "Mukta",
                    }}
                    align="center"
                >
                    {parseInt(distNm).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        <Tooltip
                            title={
                                "Fuel Weight: " +
                                    parseInt(fuelWeight).toLocaleString() +
                                    "lbs" || "N/A"
                            }
                        >
                            {(parseInt(fuelCost) === 0
                                ? "N/A"
                                : parseInt(fuelCost)
                            ).toLocaleString()}
                        </Tooltip>
                    ) : (
                        (parseInt(fuelCost) === 0
                            ? "N/A"
                            : parseInt(fuelCost)
                        ).toLocaleString()
                    )}
                </StyledTableCell>
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {parseInt(calculatedOtherTdvc.toFixed(0)).toLocaleString()}
                </StyledTableCell>
                {flightInfo.isLiveData && (
                    <StyledTableCell
                        style={{ fontSize: "16px", fontFamily: "Mukta" }}
                        align="center"
                    >
                        {parseInt(ovfCosts).toLocaleString()}
                    </StyledTableCell>
                )}
                {flightInfo.isLiveData && (
                    <StyledTableCell
                        style={{ fontSize: "16px", fontFamily: "Mukta" }}
                        align="center"
                    >
                        {tnc || "0"}
                    </StyledTableCell>
                )}
                {flightInfo.isLiveData && (
                    <StyledTableCell
                        style={{ fontSize: "16px", fontFamily: "Mukta" }}
                        align="center"
                    >
                        0
                    </StyledTableCell>
                )}
                <StyledTableCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    align="center"
                >
                    {flightInfo.isLiveData ? (
                        <strong>
                            {(
                                parseInt(totalCosts.toFixed(0)) +
                                parseInt(ovfCosts.toFixed(0)) +
                                parseInt(tnc ? tnc : 0) +
                                parseInt(fuelCost)
                            ).toLocaleString()}
                        </strong>
                    ) : (
                        <strong>
                            {(
                                parseInt(totalCosts.toFixed(0)) +
                                parseInt(fuelCost)
                            ).toLocaleString()}
                        </strong>
                    )}
                </StyledTableCell>

                <FlightRouteTooltipCell
                    style={{ fontSize: "16px", fontFamily: "Mukta" }}
                    className={
                        flightInfo.isLiveData
                            ? classes.liveDataRouteDisplay
                            : null
                    }
                    route={route}
                />
            </StyledTableRow>
        );
    };

    return (
        <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ fontFamily: "Mukta" }}
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        dataSourceType={dataSourceType}
                    />
                    <TableBody>
                        {stableSort(flightInfo, getComparator(order, orderBy))
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(createDefaultRow)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={flightInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
