import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./App.css";

// import Calculation from "./containers/Calculation/Calculation";
import Login from "./components/Login/Login";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { setupInterceptors } from './axios-usercharges';
import 'mapbox-gl/dist/mapbox-gl.css';
import FlightGain from "./components/fliteROUTE/FlightGains";
import BetaInvoice from './components/BetaInvoice/BetaInvoice';
import CalculationRoute from "./components/Calculations/calculation";
import TrackDataRoute from "./components/OCR/TrackData";
import DashboardRoute from "./components/Dashboard/Dashboard";
import OcrRoute from "./components/OCR/OCR";
import OcrDataExtract from "./components/OCR/DataExtractXtracta";
import OcrDataExtractQatar from "./components/OCR/DataExtract/DataXtracta-qatar";
import OcrDataExtractFlorida from "./components/OCR/DataExtract/DataXtracta-florida";
import FolderSelector from "./components/OCR/CountryInvoiceFolder/AllCountryFolders";
import FolderSelector1 from "./components/OCR/CountryInvoiceFolder/DominicanRepublic";
import DataExtractDB from "./components/OCR/DataExtractDB";
import pdfUploadXtracta from "./components/OCR/XtractaPdfUpload";
import Contacts from "./components/Contacts/Contacts";
import Aircraft from "./components/Aircraft/Aircraft";
import ANPA from "./components/ANPA/ANPA";
import EFTR from "./components/EFTR/EFTR"
import InvoiceViewer from "./components/InvoiceViewer/InvoiceViewer";
import FlownRoute from "./components/FlewRoute/FlownRoute";
import InvoicesARM from "./components/ARM/InvoicesARM";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00305b",
    },
    secondary: {
      main: "#E5176E",
    },
    warning: {
      main: "#f8b308",
    },
    error: {
      main: "#f44336",
    },
    success: {
      main: "#4caf50",
    },
    softBlue: {
      main: "#4caf50"
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
    button: {
      fontSize: 15,
      textTransform: "none",
      fontFamily: "Arial, Helvetica, sans-serif",
      border: "none",
      margin: "4px",
    },
    body1: {
      lineHeight: 1,
      fontSize: 13,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "4px",
        // borderRadius: "0px",
      },
      contained: {
        boxShadow: "none",
      },
    },
  },
  MuiTableRow: {
    root: { //for the body
        height: "100%"
    },
    head: { //for the head
        height: "100%"
    }
  }
});

// TODO: refactor because all pages use TopBar and NavBar in component tree
function App() {
  const history = useHistory();
  setupInterceptors(history);
  return (
    
    <ThemeProvider theme={theme}>
      <Router>
        {/* Adjust screen size to account for Navbar */}
         <div style={{marginTop: "50px", marginLeft: "166px"}}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <ProtectedRoute exact path="/" component={DashboardRoute} />
            <ProtectedRoute exact path="/flitegains" component={FlightGain} />
            <ProtectedRoute exact path="/flownroute" component={FlownRoute} />
            {/* <ProtectedRoute exact path="/calculation" component={Calculation} /> */}
            {/*<ProtectedRoute exact path="/Insights" component={Insight} />*/}
            <ProtectedRoute exact path="/betaInvoicing" component={BetaInvoice} />
            <ProtectedRoute exact path="/calculations" component={CalculationRoute} />
            <ProtectedRoute exact path="/track" component={TrackDataRoute} />
            <ProtectedRoute exact path="/invoice_viewer" component={InvoiceViewer} />
            <ProtectedRoute exact path="/invoicesarm" component={InvoicesARM} />
            <ProtectedRoute exact path="/ocr-data" component={OcrRoute} />
            <ProtectedRoute exact path="/ocr" component={OcrDataExtract} />
            <ProtectedRoute exact path="/ocr-qatar" component={OcrDataExtractQatar} />
            <ProtectedRoute exact path="/ocr-florida" component={OcrDataExtractFlorida} />
            <ProtectedRoute exact path="/folder" component={FolderSelector} />
            <ProtectedRoute exact path="/folder1" component={FolderSelector1} />
            <ProtectedRoute exact path="/PdfViewer" component={DataExtractDB} />
            <ProtectedRoute exact path="/PdfUpload" component={pdfUploadXtracta} />
            <ProtectedRoute exact path="/anpa" component={ANPA} />
            <ProtectedRoute exact path="/eftr" component={EFTR} />
            <ProtectedRoute exact path="/aircraft" component={Aircraft} />
            <ProtectedRoute exact path="/contacts" component={Contacts} />
            
            <Redirect to="/" />
          </Switch>
         </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
