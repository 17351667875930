import React from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function SortSelector() {
  return(
    <div style={{display: "flex", flexDirection: "column", marginRight: "10px"}}>
      <ArrowDropUpIcon style={{margin:"-8px"}}/>
      <ArrowDropDownIcon style={{margin:"-8px"}}/>
    </div>
  )
}

export default SortSelector;