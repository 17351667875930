const invoiceViewerData = [
  {
    state: "State1",
    ansp: "ANSP1",
    toc: "OFC",
    period: new Date().toLocaleDateString(),
    currency: "USD",
    billed_amount: 123456,
    exch_rate: 1,
    billed_date: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString(),
    due_date: new Date(new Date().setDate(new Date().getDate() - 6)).toLocaleDateString(),
    interest: 7,
    email: "state1@flitegains.com",
    contact: "State 1.name",
    phone_number: "234 456 678"
  },
  {
    state: "State2",
    ansp: "ANSP2",
    toc: "OFC",
    period: new Date().toLocaleDateString(),
    currency: "HUF",
    billed_amount: 23456,
    exch_rate: 2.234,
    billed_date: new Date(new Date().setDate(new Date().getDate() + 6)).toLocaleDateString(),
    due_date: new Date(new Date().setDate(new Date().getDate() + 6)).toLocaleDateString(),
    interest: 8,
    email: "state2@flitegains.com",
    contact: "State 2.name",
    phone_number: "235 456 678"
  },
  {
    state: "State3",
    ansp: "ANSP3",
    toc: "TNC",
    period: new Date().toLocaleDateString(),
    currency: "UKL",
    billed_amount: 23777,
    exch_rate: 0.5672,
    billed_date: new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString(),
    due_date: new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString(),
    interest: 9,
    email: "state3@flitegains.com",
    contact: "State 3.name",
    phone_number: "236 456 678"
  }
]

export default invoiceViewerData;