// This is the code to upload PDF to the xtracta workflow-----------------

import axios from '../../axios-usercharges';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Navbar from '../navbar/Navbar';
import { CircularProgress, InputLabel, Select } from '@material-ui/core';
import './OCR.css';
import TrackData from './TrackData';
import { FormControl, MenuItem } from '@mui/material';


const PdfUploadComponent = () => {
    const [uploadMessage, setUploadMessage] = useState('');
    const [workflowId, setWorkflowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState('');

    const messageStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
    };

    const workflowIdOptions = {
        '990751': 'EuroControl',
        '977948': 'Germany',
        '977949': 'Brazil',
        '977951': 'Others',
        '977978': 'Ireland',
        '978107': 'Mexico',
        '981719': 'Canada',
        '990821': 'Dominican Republic',
    };

    const onDrop = async (acceptedFiles) => {
        try {
            setLoading(true);

            const pdfData = acceptedFiles[0];

            const xtractaRequestBody = new FormData();
            xtractaRequestBody.append('workflowId', workflowId);
            xtractaRequestBody.append('file', pdfData);

            const response = await axios.post(`/api/upload-pdf`, xtractaRequestBody);

            setDocumentId(response.data.document_id);
            setUploadMessage(
                <div style={messageStyle}>
                    PDF uploaded and sent to Xtracta workflow.
                </div>
            );
            setTimeout(() => {
                setUploadMessage(null);
                const countryPdfData = new FormData();
                countryPdfData.append('file', pdfData);
                countryPdfData.append('countryName', selectedCountry);
    
                const pdfResponse = axios.post(`/api/upload-country-pdf`, countryPdfData);
                if (pdfResponse.status === 400) {
                    console.log('File with the same name already exists...');
                } else {
                    console.log('Your API response:', pdfResponse);
                }    
            }, 5000);
        } catch (error) {
            setUploadMessage('An error occurred while processing the PDF. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleWorkflowIdChange = (e) => {
        setWorkflowId(e.target.value);
        setSelectedCountry(workflowIdOptions[e.target.value]);
    };

    return (
        <>
            <Navbar index={5} />
            <div style={{ padding: '5px 60px' }}>
                <FormControl sx={{ m: 1, width: 200, }}>
                    <InputLabel id="demo-select-small-label">Select Country</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="workflowId"
                        value={workflowId}
                        label="Select"
                        onChange={handleWorkflowIdChange}
                    >
                        {Object.keys(workflowIdOptions).map((id) => (
                            <MenuItem key={id} value={id}>
                                {workflowIdOptions[id]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Dropzone onDrop={onDrop} accept=".pdf">
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <button
                                type="button"
                                style={{
                                    border: '2px dashed #cccccc',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    marginTop: '20px'
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                        Uploading...
                                    </>
                                ) : (
                                    'Upload PDF'
                                )}
                            </button>
                        </div>
                    )}
                </Dropzone>
                {uploadMessage && <p>{uploadMessage}</p>}
                {documentId !== null && <TrackData documentId={documentId} />}
            </div>
        </>
    );
};

export default PdfUploadComponent;
