// ------------- REACT -------------
import React from "react";

// ------------- MUI -------------
import { KeyboardDatePicker } from "@material-ui/pickers";

// ------------- LUXON -------------
import { DateTime } from "luxon";

export default function FlightDayPicker({
    selectedDate,
    handleDateChange,
    useAllDates,
    setUseAllDates,
    dataSourceType,
}) {
    // data started being persisted on this day and calculations started
    // const startDate = DateTime.fromISO('2021-01-14');
    // date for JBU demo
    const startDate = DateTime.fromISO("2021-01-15").minus({ day: 1 });
    const startStaticDate = DateTime.fromISO("2023-12-02").minus({ day: 1 });
    return (
        <>
            <KeyboardDatePicker
                style={{ width: "200px", fontSize: "1.25rem" }}
                autoOk
                value={selectedDate}
                placeholder="01/01/2021"
                onChange={(date) => handleDateChange(date)}
                minDate={
                    dataSourceType == "20"
                        ? DateTime.utc(2023, 12, 2, 12, 0, 0)
                        : startDate
                }
                maxDate={
                    dataSourceType == "20"
                        ? DateTime.utc(2023, 12, 19, 12, 0, 0)
                        : DateTime.utc()
                }
                format="DD"
            />
        </>
    );
}
