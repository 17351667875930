import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import axios from "../../../axios-usercharges";
// import Navbar from '../../navbar/Navbar';

const RetrieveFloridaDataFromXtracta = ({ documentId }) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await axios.post(
          `/api/retrieve-data`,
          { document_id: documentId },
        );

        setResponse(apiResponse.data);
        setError(null);
        await insertData(apiResponse.data);
      } catch (err) {
        setError(err.message);
        setResponse([]);
      }
    };

    const insertData = async (data) => {
      try {
        const filteredData = data.filter((item) => {
          return [
            "Charge Type",
            "Total",
            "Supplier Name",
            "Invoice Number",
            "Invoice Date",
            "Address",
            "Country",
            "Total Due",
            "Date",
            "Flight ID",
            "Org",
            "Dest",
            "Type",
            // "MTOW",
            // "KM",
            "Tail Number",
            "Flight Type",
            "Flight Total Sum",
          ].includes(item.field_name);
        });
        console.log("filteredDatafilteredData", filteredData);
        let d = filteredData[2].field_value;
        let month_year = d.split('/')[0] + ' ' + (parseInt(d.split('/')[1]) - 1).toString().padStart(2, '0');
        console.log('Invoice Date', month_year);

        const country = 'Dominican Republic';
        await axios.post(`/api/insert-data`, { country, month_year, data: filteredData });
      } catch (err) {
        console.error("Data Insertion API Error:", err);
      }
    };

    fetchData();

    return () => {};
  }, [documentId]);

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const tableData = response.filter((item) => {
    return ["Charge Type", "No Charges", "Base Rate", "Total"].includes(item.field_name);
  });

  const groupedData = {};
  tableData.forEach((item) => {
    if (!groupedData[item.field_name]) {
      groupedData[item.field_name] = [];
    }
    groupedData[item.field_name].push(item.field_value);
  });

  const filteredResponse = response
    .map((item) => {
      return item;
    })
    .filter((item) =>
      [
        "Supplier Name",
        "Invoice Number",
        "Invoice Date",
        "Address",
        "Country",
        "Total Due"
      ].includes(item.field_name)
    );

  const duplicateData = response.filter((item) =>
    [
      "Date",
      "Flight ID",
      "Org",
      "Dest",
      "Type",
    //   "MTOW",
    //   "KM",
      "Tail Number",
      "Flight Type",
      "Flight Total Sum",
    ].includes(item.field_name)
  );

  const rows = [];
  for (let i = 0; i < duplicateData.length; i += 8) {
    const rowData = duplicateData.slice(i, i + 8);
    const cells = rowData.map((item) => {
      if (item.field_name === "Date") {
        // Format the "Date" fields
        const formattedDate = formatDate(item.field_value);
        return <td key={item.field_id}>{formattedDate}</td>;
      } 
      else {
        // Display "-" for fields with no value
        return (
          <td key={item.field_id}>
            {item.field_value ? item.field_value : "-"}
          </td>
        );
      }
    });
    rows.push(<tr key={`row-${i}`}>{cells}</tr>);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <>
    <div>
        {/* <Navbar /> */}
    </div>
      <div className="retrieve-data-container">
      <h2 className="invoice-detail-heading">UAL Invoice Detail</h2>
        <div className="table-container customTable">
          <table>
            <thead>
              <tr>
                {filteredResponse.map((item) => (
                  <th key={item.field_id}>{item.field_name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {filteredResponse.map((item) => (
                  <td key={item.field_id}>
                    {item.field_name === "Invoice Date"
                      ? formatDate(item.field_value)
                      : item.field_value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-container customTable">
          <table>
            <thead>
              <tr>
                {Object.keys(groupedData).map((fieldName) => (
                  <th key={fieldName}>{fieldName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.values(groupedData).map((fieldValues, index) => (
                  <td key={index}>
                    {fieldValues.map((value, subIndex) => (
                      <div key={subIndex}>{value}</div>
                    ))}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-container customTable" style={{ flexDirection: "column" }}>
          <table>
            <thead>
              <tr>
                {[
                  "Date",
                  "Flight ID",
                  "Org",
                  "Dest",
                  "Type",
                //   "MTOW",
                //   "KM",
                  "Flight Total Sum",
                  "Tail Number",
                  "Flight Type",
                ].map((fieldName) => (
                  // <th key={fieldName}>{fieldName}</th>
                  <th key={fieldName}>{fieldName === "Flight Total Sum" ? "Charge Rate" : fieldName}</th>
                ))}
              </tr>
            </thead>
            <tbody>{rows.slice(startIndex, endIndex)}</tbody>
          </table>
          <TablePagination
            component="div"
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default RetrieveFloridaDataFromXtracta;
