// ------------- REACT -------------
import React, { useState, useEffect, createRef } from "react";

// ------------- MUI -------------
import {
    CircularProgress,
    TextField,
    Icon,
    Typography,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";

// ------------- AXIOS -------------
import axios from "../../axios-usercharges";

// ------------- LUXON -------------
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import FlightInfoTable from "./FlightInfoTable";
import DateTimePanel from "./DateTimePanel";
import FlightMapModal from "./FlightMapModal";

export default function FlightGains() {
    // ------------- REFS -------------
    const GreatCircleRef = createRef();

    // ------------- STATES -------------
    const [isLoaded, setIsLoaded] = useState(false);
    const [flightsInfo, setFlightsInfo] = useState([]);
    const [detailedFirInfo, setDetailedFirInfo] = useState();
    const [selectedDate, setSelectedDate] = useState(DateTime.utc());
    const [useAllDates, setUseAllDates] = useState(false);
    const [selectedFlightInfo, setSelectedFlightInfo] = useState(null);
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [dataSourceType, setDataSourceType] = useState(10);

    // ------------- FUNCTIONS -------------
    const handleMapButtonClick = (flightInfo) => {
        const {
            flight_ref,
            tailNumber,
            atcCosts,
            totalCosts,
            mtow,
            departure,
            arrival,
            firCalculatedInfo,
            etd,
            eta,
            flightNumber,
            aircraftModel,
            legacy_format,
            arrivalTerminalCharges,
            arrivalTerminalChargesCurrency,
            arrivalUSDTerminalCharges,
            departureTerminalCharges,
            departureTerminalChargesCurrency,
            departureUSDTerminalCharges,
            waypointsArray,
        } = flightInfo;
        const selectedInfo = {
            flight_ref,
            tailNumber,
            atcCosts,
            totalCosts,
            mtow,
            departure,
            arrival,
            firCalculatedInfo,
            etd,
            eta,
            flightNumber,
            aircraftModel,
            legacy_format,
            arrivalTerminalCharges,
            arrivalTerminalChargesCurrency,
            arrivalUSDTerminalCharges,
            departureTerminalCharges,
            departureTerminalChargesCurrency,
            departureUSDTerminalCharges,
            waypointsArray,
        };
        axios
            .get(`/api/flightGains/geometry/${flightInfo.flight_ref}`)
            .then((res) => {
                const firRegionsPointData = res.data;
                let getWayPoints = [];
                axios
                    .get(`/api/flightGains/waypoints/${flightInfo.flight_ref}`)
                    .then((res) => {
                        getWayPoints = res.data;
                        const firInfoWithPoints = firCalculatedInfo.map(
                            (f, i) => ({
                                ...f,
                                geometry: firRegionsPointData[i],
                                wayPointsC: getWayPoints,
                            })
                        );

                        const gcData = {
                            type: "FeatureCollection",
                            features: firInfoWithPoints,
                        };

                        setSelectedFlightInfo({ gcData, ...selectedInfo });

                        setMapModalOpen(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDateChange = (date) => {
        setUseAllDates(false);
        setSelectedDate(date);
        setIsLoaded(false);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSourceChange = (event) => {
        if (event.target.value == "20") {
            setSelectedDate(DateTime.utc(2023, 12, 2, 12, 0, 0));
        } else {
            setSelectedDate(DateTime.utc());
        }
        setDataSourceType(event.target.value);
        setIsLoaded(false);
    };

    const reset = () => {
        setSearchResults([]);
        setSearchTerm("");
    };

    // ------------- HOOKS -------------
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        // TODO: generalize based on who's logged in
        const dataSource =
            dataSourceType === 10
                ? `/api/flightGains/UAL/${selectedDate}`
                : `/api/flightGains/live-data2/UAL/${selectedDate}`;

        axios
            .get(dataSource, { signal })
            .then((res) => {
                setFlightsInfo(res.data);
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });

        return () => {
            // Cancel the request when the component unmounts
            abortController.abort();
        };
    }, [selectedDate, dataSourceType]);

    useEffect(() => {
        const results = flightsInfo.filter((flight) => {
            return (
                flight.flightNumber.includes(searchTerm) ||
                flight.departure
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                flight.arrival.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setSearchResults(results);
    }, [searchTerm]);

    return (
        <>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <>
                    <Navbar index={2} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "95%",
                            margin: "auto",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            {/* Date Selector */}
                            <DateTimePanel
                                selectedDate={selectedDate}
                                handleDateChange={handleDateChange}
                                useAllDates={useAllDates}
                                setUseAllDates={setUseAllDates}
                                dataSourceType={dataSourceType}
                            />
                            {/* Swim/Static Dropdown */}
                            <FormControl
                                sx={{ m: 1, minWidth: 200 }}
                                style={{ marginTop: "15px" }}
                                size="small"
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Source
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={dataSourceType}
                                    label="Source"
                                    onChange={handleSourceChange}
                                >
                                    <MenuItem value={10}>Swim</MenuItem>
                                    <MenuItem value={20}>Static</MenuItem>
                                </Select>
                            </FormControl>
                            {dataSourceType === 20 && (
                                <Typography style={{ marginTop: "25px" }}>
                                    Flight Data available from Dec 02 2023 to
                                    Dec 19 2023
                                </Typography>
                            )}
                        </div>
                        <div>
                            {/* Search */}
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                autoFocus
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleChange}
                            />
                            <CachedIcon
                                style={{
                                    marginTop: "30px",
                                    marginLeft: "10px",
                                }}
                                onClick={reset}
                            />
                        </div>
                    </div>

                    {isLoaded ? (
                        searchResults.length !== 0 ? (
                            <FlightInfoTable
                                handleMap={handleMapButtonClick}
                                flightInfo={searchResults}
                                selectedDate={selectedDate}
                                useAllDates={useAllDates}
                                dataSourceType={dataSourceType}
                            />
                        ) : (
                            <FlightInfoTable
                                handleMap={handleMapButtonClick}
                                flightInfo={flightsInfo}
                                selectedDate={selectedDate}
                                useAllDates={useAllDates}
                                dataSourceType={dataSourceType}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "50px",
                            }}
                        >
                            {/* Loading State */}
                            <CircularProgress size={70} left={-20} top={40} />
                            <span
                                style={{ fontSize: "2rem", marginTop: "20px" }}
                            >
                                Loading
                            </span>
                        </div>
                    )}
                    {mapModalOpen && (
                        <FlightMapModal
                            GreatCircleRef={GreatCircleRef}
                            selectedDate={selectedDate}
                            setModalOpen={setMapModalOpen}
                            selectedFlightInfo={selectedFlightInfo}
                        />
                    )}
                </>
            </MuiPickersUtilsProvider>
        </>
    );
}
