const ATCCharges = {
  mexico: {
    charges: [
      {
          weight : 'Type A: 0 - 16.7 meters long',
          currency : 'MXN',
          fixedCharge : '0.27',
          formula : 'Unit Rate x Great Circle Distance_km or part thereof'
      },
      {
          weight : 'Type B: 16.7 - 25 meters long',
          currency : 'MXN',
          fixedCharge : '2.04',
          formula : 'Unit Rate x Great Circle Distance_km or part thereof'
      },
      {
          weight : 'Type C: 25 - 38 meters long',
          currency : 'MXN',
          fixedCharge : '5.9',
          formula : 'Unit Rate x Great Circle Distance_km or part thereof'
      },
      {
          weight : 'Type D: More than 38 meters long',
          currency : 'MXN',
          fixedCharge : '8.81',
          formula : 'Unit Rate x Great Circle Distance_km or part thereof'
      },
    ],
    note: "1. Between the FIR's entry point to its exit point - GDC"
  },
  dominican: {
    charges: [
      {
          weight : '0 - 25,000 kg',
          currency : 'USD',
          fixedCharge : '60.0',
          formula : 'Fixed Charge'
      },
      {
          weight : '25,000.01 - 60,000 kg',
          currency : 'USD',
          fixedCharge : '95.0',
          formula : 'Fixed Charge'
      },
      {
          weight : '60,000.01 - 100,000 kg',
          currency : 'USD',
          fixedCharge : '165.0',
          formula : 'Fixed Charge'
      },
      {
          weight : '100,000.01 - 200,000 kg',
          currency : 'USD',
          fixedCharge : '215.0',
          formula : 'Fixed Charge'
      },
      {
          weight : 'Over 200,000 kg',
          currency : 'USD',
          fixedCharge : '350.0',
          formula : 'Fixed Charge'
      }
    ],
    note: `1. All rights for the use of air navigation services and facilities due to landings and overflights shal be paid to the
    Instituto Dominicano de Aviacion Civil, or its representatives. Landing aircrafts shall pay before departure,
    except regularly scheduled airlines, or those airlines operators who had obtained credit from Instituto
    Dominicano de Aviacion Civil. Regularly scheduled airlines will be billed at the last day of each month. 2. The
    Instituto Dominicano de Aviacion Civil will be able to retain landing aircraft in the international airports or any
    other airport equipped with radio aids for air navigation, on international operations, for the charge of radio aids
    use, when these services have not been paid immediately before the departure by the operator or aircraft owner
    to Instituto Dominicano de Aviacion Civil or its properly authorized agents. 3. The overflight rights in all the Santo
    Domingo FIR are specified by aircraft category, in accordance with the maximum take off weight`
  }
}

export default ATCCharges;