// ------------- REACT -------------
import React from 'react';

// ------------- MUI -------------
import { Dialog, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// --- MUI ICONS ---
import ListAltIcon from '@mui/icons-material/ListAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CalculateIcon from '@mui/icons-material/Calculate';

const useStyles = makeStyles((theme) => ({
  paper:{
      maxHeight: '80vh',
      padding: "10px"
  }
}));

function ATCChargesModal (props) {
  const classes= useStyles();

  const { object, modalOpen, setModalOpen } = props;

  return (
    <>
      <Dialog open={modalOpen} classes={{ paper: classes.paper }}>
        <Button color="secondary" style={{position: "absolute",
          color: "red",
          top: "-10px",
          right: "-10px",
          fontSize:"1.5rem"}} onClick={() => setModalOpen(false)}>X</Button>
        
        <Typography variant="h5" component="h6" style={{textAlign: "center", margin: "10px 0", fontWeight: "bold"}}>ATC Charges</Typography>
        <div style={{border: "2px solid black", borderRadius: "10px", padding: "10px"}}>
          <TableContainer>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <div style={{display: 'flex', gap: '5px'}}>
                            <ListAltIcon style={{color: 'blue'}} />
                            <span>Schema of Charges</span>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div style={{display: 'flex', gap: '5px'}}>
                            <MonetizationOnIcon style={{color: 'blue'}}/>
                            <span>Currency</span>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div style={{display: 'flex', gap: '5px'}}>
                            <PriceCheckIcon style={{color: 'blue'}}/>
                            <span>Fixed Charge</span>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div style={{display: 'flex', gap: '5px'}}>
                            <CalculateIcon style={{color: 'blue'}}/>
                            <span>Formula Desc.</span>
                        </div>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {object.charges
                .map((ele) => (
                    <TableRow>
                        <TableCell align="center">{ele.weight}</TableCell>
                        <TableCell align="center">{ele.currency}</TableCell>
                        <TableCell align="center">${ele.fixedCharge}</TableCell>
                        <TableCell align="center">{ele.formula}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
            {object.note && <div style={{padding: '10px'}}>
                <span><b>Note :</b>
                {object.note}
                </span>
            </div>}
        </TableContainer>
        </div>
      </Dialog>
    </>
  )
}

export default ATCChargesModal;