// ------------- REACT -------------
import React, { useState } from "react";

// ------------- MUI -------------
import { InputLabel, MenuItem, FormControl, Select }from "@mui/material";
import { Icon } from "@material-ui/core";

// ------------- COMPONENTS -------------
import Navbar from "../navbar/Navbar";
import FileUpload from "./DataExtractDB";
import Folder from "./CountryInvoiceFolder/AllCountryFolders";
import Claims from "./Claims/DataExtract";
import PlaceHolder from "../Utility/PlaceHolders/PlaceHolder";

// ------------- CSS -------------
import "./OCR.css";


const OcrComponent = () => {
    // ------------- STATES -------------
    const [uploadMessage, setUploadMessage] = useState("");
    const [data, setData] = useState("");

    // ------------- FUNCTIONS -------------
    const handleChange = (event) => {
        setData(event.target.value);
    };

    return (
        <>
            <Navbar index={7} />
            <div>
                    <div style={{width:"95%", margin: "0 auto", paddingTop: "30px"}}>
                        <div
                            style={{ marginTop: '15px', backgroundColor: '#180aa2', fontSize: '22px', padding: '10px 20px', fontWeight: '700', color: 'white' }}
                        >
                            <span>Air Navigation Claims Generator</span>
                        </div>
                        <div style={{ marginTop: '15px', backgroundColor: '#180aa2',fontSize: '22px', padding: '5px 20px', color: 'white', width: 'fit-content' }} >
                            <span>Format</span>
                        </div>
                        <div style={{marginTop: '15px', padding: '25px' }}>
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>   
                                    <span style={{ fontSize: '18px', minWidth: "150px" }}>Select Option</span>
                                    <FormControl sx={{ m: 1, width: 300, }}>
                                        <InputLabel id="demo-select-small-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={data}
                                            label="Select"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Pull PDF</MenuItem>
                                            <MenuItem value={20}>Pull digitized Document</MenuItem>
                                            <MenuItem value={30}>Match/Claim</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Insert a placeholder if no data has been selected*/}
                    {!data && 
                        <PlaceHolder 
                            title="OCR Data"
                            description="Please select a viewing option"
                        />
                    }
                    <div>
                        {data === 10 && <Folder />}
                        {data === 20 && <FileUpload />}
                        {data === 30 && <Claims />}
                    </div>
                {uploadMessage && <p>{uploadMessage}</p>}
            </div>
        </>
    );
};

export default OcrComponent;
